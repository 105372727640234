// Icon Sizes
// -------------------------
@use "sass:math";

/* makes the font 33% larger relative to the icon container */
.#{$fa-css-prefix}-lg {
  font-size: math.div(4em, 3);
  line-height: math.div(3em, 4);
  vertical-align: -15%;
}

.#{$fa-css-prefix}-2x {
  font-size: 2em;
}

.#{$fa-css-prefix}-3x {
  font-size: 3em;
}

.#{$fa-css-prefix}-4x {
  font-size: 4em;
}

.#{$fa-css-prefix}-5x {
  font-size: 5em;
}
