.breadcrumbs {
  margin: 0;

  li {
    display: inline-block;
    vertical-align: top;
    line-height: 16px;
    font-size: $size-md;
    text-transform: none;

    &:after {
      content: "/";
      padding: 0 3px 0 7px;
      margin: 0 !important;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      font-size: $size-lg;
      color: $color-text;
      font-weight: 600;

      &:hover {
        color: darken($color-link, 10%);
      }
    }
  }

  [class^="icon-"],
  [class*=" icon-"] {
    margin-left: 7px;
    font-size: $size-sm;
    color: $color-link;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;

    &:hover {
      color: darken($color-link, 10%);
    }
  }

  input {
    height: 18px;
    outline: none !important;
    padding: 0 15px;
  }
}

.breadcrumbs-sm {
  margin: 0;

  li {
    display: inline-block;
    vertical-align: top;
    text-transform: none;
    line-height: 1.2;

    &:after {
      content: ">";
      margin: 0 2px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
