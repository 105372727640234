.parts--wrap {
  display: flex;
  border: 1px solid $color-background;
  border-radius: 4px;

  label {
    line-height: 1.6;
  }
}

.parts--left {
  flex: 0 auto;
  width: 203px;
  padding: 15px 20px;
  border-right: 1px solid $color-background;

  .line {
    margin: 5px 0;
    width: 100px;
  }

  .select--inline {
    margin-right: -10px;
  }
}

.parts--right {
  flex: 1;
  padding: 20px;
}

.parts--text--line {
  padding: 5px 0;

  p {
    font-size: 14px;
  }

  .button {
    .icon {
      font-size: 10px;
      color: $color-red;
    }
  }
}

.parts--details {
  padding: 29px 0;
  align-items: flex-start;

  .machine-details--icon {
    padding-top: 20px;
  }
}

.parts--top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  align-items: center;
  font-weight: 500;

  .reports-actions {
    margin: 0;
  }
}

.parts--top--left {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 2px;

  .icon {
    color: $color-grey2;
    font-size: 24px;
    margin-right: 14px;
  }

  .button-group {
    a,
    button {
      min-width: 83px;
    }
  }
}

.parts--top--center {
  .parts--top-title {
    font-size: 16px;

    span {
      display: inline-block;
      font-size: 16px;
      color: $color-grey2;
    }
  }
}

.parts--top-title {
  span {
    display: block;
    font-size: 12px;
    color: $color-text-light;
  }
}

.parts--calendar {
  margin: 0 -5px;
  padding-bottom: 15px;
  font-size: 12px;

  table {
    width: 100%;
    text-align: center;

    thead,
    tbody,
    tfoot {
      border: none;
    }
  }

  thead {
    td {
      font-size: 12px;
      text-align: center;
      padding: 0 5px;
    }

    .active {
      color: $color-yellow;
    }
  }

  tbody {
    tr {
      background: none;
    }
  }

  td {
    padding: 5px 5px 15px;
    vertical-align: middle;
  }

  .parts--time {
    font-size: 10px;
    line-height: 10px;
  }
}

.parts--item {
  border-radius: 2px;
  border: 1px solid #bfeaed;
  background-color: #dff5f6;
  height: 50px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.passive {
    background: $color-grey-light;
    border-color: $color-grey-light;
  }

  .active & {
    border-color: $color-yellow;
    background-color: #fef7e4;
  }
}

.parts--bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -20px;
  padding: 20px 20px 0;
  border-top: 1px solid $color-background;
}

.parts--bottom--left {
  align-items: center;
  display: flex;
  color: $color-text-light;
  font-size: $size-sm;
  font-weight: 400;

  .icon {
    margin-right: 5px;
  }

  .select--inline {
    .Select-control {
      font-weight: 400;
    }
  }
}

.rel-wrap {
  position: relative;

  > .flex {
    position: absolute;
    left: 0;
    right: 0;
  }
}

.flex-name {
  display: flex;
  align-items: center;

  .rel-wrap {
    height: 14px;
    flex: 1;
    margin: 0 0 0 5px;
  }

  .td-edit-fixed & {
    margin-right: 15px;

    .table-search {
      width: 256px;
      left: auto;
      margin-top: -14px;
      overflow: hidden;

      &.compressed {
        width: 26px;
      }
    }
  }
}
