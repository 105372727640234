.feedbacks {
  font-size: $size-sm;
  padding: 11px 20px 14px 30px;
}

.feedback--item {
  padding: 0 0 10px;

  &.yours {
    .fa,
    .icon {
      color: $color-link;
    }

    .feedback-person {
      color: $color-text;
    }
  }
}

.feedback-person {
  color: $color-text-light;
  padding: 0 0 6px;

  .fa {
    margin: 0 5px 0 0;
  }

  .icon,
  [class^="icon-"],
  [class*=" icon-"] {
    position: relative;
    top: 2px;
    display: inline-block;
    margin: 0 5px 0 0;
  }
}

.feedback-person-wrap {
  display: flex;
  justify-content: space-between;

  .inline-list {
    margin: -2px 0 0;

    li {
      margin: 0 0 0 23px;
      color: $color-text-light;
    }
  }
}

.feedback-comment {
  padding: 11px 13px 16px;
  border-radius: 2px;
  background: $color-background;
  word-wrap: break-word;
  word-break: break-all;
}

.feedback-comment--form {
  .input-group {
    border-collapse: collapse;
    margin: 0;
  }

  .input-group-field {
    width: 100%;
    border: 1px solid $color-link;
    border-radius: 2px 0 0 2px;
    height: 36px;
    padding: 0 15px;
    background: $color-white;

    &:focus {
      border-color: $color-link;
    }
  }

  .button {
    border-radius: 0 2px 2px 0;
  }

  .error-line {
    margin: 0;
    padding: 7px 0 0;
    text-align: left;
    float: none;
    font-size: $size-sm;
    max-width: none;
  }

  .error {
    input {
      border: 1px solid $color-red !important;
    }
  }

  .disabled-field {
    .input-group-field {
      border-color: $color-grey2;

      &:focus {
        border-color: $color-link;

        + .input-group-button {
          .button__submit {
            border-color: $color-link $color-link $color-link $color-grey2;
          }
        }
      }
    }

    .button__submit[disabled] {
      opacity: 1;
      background: $color-grey2;
      border-color: $color-grey2;
    }
  }
}
