@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  src: url("#{$font-path}/Roboto/Roboto-regular/Roboto-regular.eot");
  src:
    url("#{$font-path}/Roboto/Roboto-regular/Roboto-regular.eot?#iefix")
      format("embedded-opentype"),
    local("Roboto"),
    local("Roboto-regular"),
    url("#{$font-path}/Roboto/Roboto-regular/Roboto-regular.woff2")
      format("woff2"),
    url("#{$font-path}/Roboto/Roboto-regular/Roboto-regular.woff")
      format("woff"),
    url("#{$font-path}/Roboto/Roboto-regular/Roboto-regular.ttf")
      format("truetype"),
    url("#{$font-path}/Roboto/Roboto-regular/Roboto-regular.svg#Roboto")
      format("svg");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  src: url("#{$font-path}/Roboto/Roboto-700/Roboto-700.eot");
  src:
    url("#{$font-path}/Roboto/Roboto-700/Roboto-700.eot?#iefix")
      format("embedded-opentype"),
    local("Roboto Bold"),
    local("Roboto-700"),
    url("#{$font-path}/Roboto/Roboto-700/Roboto-700.woff2") format("woff2"),
    url("#{$font-path}/Roboto/Roboto-700/Roboto-700.woff") format("woff"),
    url("#{$font-path}/Roboto/Roboto-700/Roboto-700.ttf") format("truetype"),
    url("#{$font-path}/Roboto/Roboto-700/Roboto-700.svg#Roboto") format("svg");
}
