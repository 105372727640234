.tooltip-wrap {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.tooltip-icon {
  cursor: pointer;
  color: $color-text;
  vertical-align: top;
  font-size: 0.88rem;
  position: relative;
  z-index: 1;
  padding: 2px 5px;

  &:hover,
  &:active,
  &:focus {
    color: darken($color-link, 10%);
  }
}

.tooltip-in-wrap {
  position: absolute;
  left: 0;
  top: 100%;
  margin: -10px 0 0 -6px;
  padding-top: 12px;
  max-width: 400px;
  min-width: 270px;
  z-index: 5;
}

.tooltip-in {
  position: relative;
  background: $color-white;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(46, 47, 66, 0.07);
  text-align: left;
  font-weight: 300;
  padding: 10px 15px;
  word-wrap: break-word;
  color: $color-text-light;
  font-size: 13px;
  line-height: 1.4;

  &:before {
    content: "";
    position: absolute;
    left: 13px;
    top: -11px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-grey2 transparent;
  }

  &:after {
    content: "";
    position: absolute;
    left: 13px;
    top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-white transparent;
  }

  strong {
    color: $color-text;
    margin: 0 5px;
  }
}
