.wait {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 60px - 56px);
  background: rgba($color-white, 0.8);
  min-width: 100%;
  display: flex;
  align-items: center;
  z-index: 99;

  &.full-height {
    height: calc(100vh - 60px);
  }

  &.solid-background {
    background: $color-grey-light;
  }

  > div {
    width: 100%;
    text-align: center;

    .col--3--center & {
      padding-right: 185px;
    }
  }

  .wait-text {
    font-size: 20px;
    padding: 25px 0;
    color: $color-text-light;
    text-align: center;
  }

  .col--3 & {
    left: 185px;
    right: 380px;
  }
}

.logo-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 41px;
  color: $color-link;
  margin: -18px 0 0 -15px;
}

#circularG {
  position: relative;
  width: 143px;
  height: 143px;
  margin: auto;
}

.circularG {
  position: absolute;
  background-color: $color-link;
  width: 33px;
  height: 33px;
  border-radius: 21px;
  -o-border-radius: 21px;
  -ms-border-radius: 21px;
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  animation-name: bounce_circularG;
  -o-animation-name: bounce_circularG;
  -ms-animation-name: bounce_circularG;
  -webkit-animation-name: bounce_circularG;
  -moz-animation-name: bounce_circularG;
  animation-duration: 1.1s;
  -o-animation-duration: 1.1s;
  -ms-animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  -moz-animation-duration: 1.1s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#circularG_1 {
  left: 0;
  top: 57px;
  animation-delay: 0.41s;
  -o-animation-delay: 0.41s;
  -ms-animation-delay: 0.41s;
  -webkit-animation-delay: 0.41s;
  -moz-animation-delay: 0.41s;
}

#circularG_2 {
  left: 14px;
  top: 14px;
  animation-delay: 0.55s;
  -o-animation-delay: 0.55s;
  -ms-animation-delay: 0.55s;
  -webkit-animation-delay: 0.55s;
  -moz-animation-delay: 0.55s;
}

#circularG_3 {
  top: 0;
  left: 57px;
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}

#circularG_4 {
  right: 14px;
  top: 14px;
  animation-delay: 0.83s;
  -o-animation-delay: 0.83s;
  -ms-animation-delay: 0.83s;
  -webkit-animation-delay: 0.83s;
  -moz-animation-delay: 0.83s;
}

#circularG_5 {
  right: 0;
  top: 57px;
  animation-delay: 0.97s;
  -o-animation-delay: 0.97s;
  -ms-animation-delay: 0.97s;
  -webkit-animation-delay: 0.97s;
  -moz-animation-delay: 0.97s;
}

#circularG_6 {
  right: 14px;
  bottom: 14px;
  animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}

#circularG_7 {
  left: 57px;
  bottom: 0;
  animation-delay: 1.24s;
  -o-animation-delay: 1.24s;
  -ms-animation-delay: 1.24s;
  -webkit-animation-delay: 1.24s;
  -moz-animation-delay: 1.24s;
}

#circularG_8 {
  left: 14px;
  bottom: 14px;
  animation-delay: 1.38s;
  -o-animation-delay: 1.38s;
  -ms-animation-delay: 1.38s;
  -webkit-animation-delay: 1.38s;
  -moz-animation-delay: 1.38s;
}
.modal-content .wait-text {
  font-size: 20px;
  padding: 10px 0;
  margin-top: 10px;
  color: $color-text-light;
  text-align: center;
}

@keyframes bounce_circularG {
  0% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(0.4);
    opacity: 0.5;
  }
}

@-o-keyframes bounce_circularG {
  0% {
    -o-transform: scale(0.75);
  }

  100% {
    -o-transform: scale(0.4);
    opacity: 0.5;
  }
}

@-ms-keyframes bounce_circularG {
  0% {
    -ms-transform: scale(0.75);
  }

  100% {
    -ms-transform: scale(0.4);
    opacity: 0.5;
  }
}

@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(0.75);
  }

  100% {
    -webkit-transform: scale(0.4);
    opacity: 0.5;
  }
}

@-moz-keyframes bounce_circularG {
  0% {
    -moz-transform: scale(0.75);
  }

  100% {
    -moz-transform: scale(0.4);
    opacity: 0.5;
  }
}

.loader-local {
  min-height: 400px;
  position: relative;

  .wait {
    position: absolute;
    height: 100%;
    width: 100%;
    background: transparent;
    left: 0;
    top: 0;

    > div {
      padding-right: 0;
    }

    .fa,
    .icon {
      font-size: inherit;
    }

    .wait-text {
      padding-bottom: 20px;
      text-align: center;
    }
  }

  .search-block & {
    position: absolute;
    left: -20px;
    top: 0;
    right: -20px;
    bottom: -20px;
    background: $color-white;
    min-height: 0;

    .wait {
      right: 0;
    }
  }

  .header-search .search-block & {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.reports--in {
  .loader-local {
    min-height: 0;
    position: static;

    .wait {
      position: absolute;
      height: 100%;
      width: 100%;
      background: $color-white;
    }
  }
}

.calendar--position {
  .loader-local {
    min-height: 0;
    position: static;

    .wait {
      position: absolute;
      height: 100%;
      width: 100%;
      background: $color-white;
    }
  }
}

.loader {
  font-size: 10px;
  text-indent: -9999em;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background: $color-white;
  background: -moz-linear-gradient(left, $color-white 10%, rgba($color-white, 0) 42%);
  background: -webkit-linear-gradient(left, $color-white 10%, rgba($color-white, 0) 42%);
  background: -o-linear-gradient(left, $color-white 10%, rgba($color-white, 0) 42%);
  background: -ms-linear-gradient(left, $color-white 10%, rgba($color-white, 0) 42%);
  background: linear-gradient(to right, $color-white 10%, rgba($color-white, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: $color-white;
    border-radius: 100% 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: $color-white;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .round-chart--item.active & {
    width: 122px;
    height: 122px;
  }

  .round-charts-inline .round-chart--item.active & {
    width: 85px;
    height: 85px;
  }

  .col--3--left & {
    &:after {
      background: $color-left-aside;
    }
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
