.sensor--list {
  padding: 0 0 10px;
}

.sensor--item {
  position: relative;
  background: $color-white;
  border-radius: 2px;
  margin: 0 0 10px;
  font-size: $size-md;
  padding: 20px 10px 20px 20px;
  display: flex;
  align-items: flex-start;
  line-height: 1.3;

  &.opened {
    .sensor--more--btn {
      .fa-caret-left,
      .icon-caret-left {
        display: none;
      }

      .fa-caret-down,
      .icon-caret-down {
        display: inline-block;
      }
    }

    .sensor--title,
    .sensor--description {
      height: auto;

      &:after {
        display: none;
      }
    }
  }
}

.sensor--title,
.sensor--description {
  height: 36px;
  overflow: hidden;
  position: relative;
  overflow-wrap: break-word;

  &:after {
    content: "";
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -10px;
    top: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.sensor--title {
  flex: 0 auto;
  width: 160px;
  margin: 0 20px 0 0;
  font-weight: 600;
}

.sensor--description {
  flex: 1;
  line-height: 1.6;
}

.sensor--more--btn {
  flex: 0 auto;
  width: 20px;
  height: 20px;
  margin: 0 0 0 10px;
  text-align: center;
  line-height: 20px;
  display: inline-block;
  color: $color-grey2;
  cursor: pointer;

  &:hover {
    color: $color-grey;
  }

  .fa-caret-left,
  .icon-caret-left {
    display: inline-block;
  }

  .fa-caret-down,
  .icon-caret-down {
    display: none;
  }
}

.oh {
  overflow: hidden;
}

.history-online {
  width: 8px;
  height: 8px;
  float: left;
  display: inline-block;
  margin: 2px 7px 0 0;
  vertical-align: top;
  border-radius: 50%;

  &.success {
    background: $color-green;
  }

  &.alert {
    background: $color-red;
  }

  &.warning {
    background: $color-yellow;
  }
}

.inventory-tab--in {
  .sensor--item {
    background: #fafafa;
    margin: 0 0 10px;
    padding: 20px;

    .history-online {
      margin-right: 50px;
    }
  }

  .sensor--title {
    width: 280px;
  }

  .sensor--title,
  .sensor--description {
    &:after {
      content: "";
      position: absolute;
      left: -5px;
      right: -5px;
      bottom: -10px;
      top: 0;
      background: -moz-linear-gradient(
        top,
        rgba(250, 250, 250, 0) 0%,
        rgba(250, 250, 250, 1) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(250, 250, 250, 0) 0%,
        rgba(250, 250, 250, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(250, 250, 250, 0) 0%,
        rgba(250, 250, 250, 1) 100%
      );
    }
  }

  .table--wrapped {
    margin: 0 -20px;
    padding: 5px 0 0;

    table {
      thead,
      tbody,
      tfoot {
        border: none;

        td {
          &:first-child {
            padding-left: 20px;
          }
        }

        .td-category {
          width: 98px;
          padding-right: 0;

          &:last-child {
            padding-right: 15px;
          }

          &.padded {
            width: 128px;
            padding-right: 15px;
          }
        }
      }

      thead {
        td {
          &:first-child {
            padding-left: 23px;
          }

          &.td-check {
            padding-left: 20px;
          }
        }

        .td-category {
          width: 98px;
          padding-right: 0;
          vertical-align: top;

          &:last-child {
            padding-right: 15px;
          }
        }
      }
    }
  }
}
