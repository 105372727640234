:root {
  --border-color: #cecece;
  --primary-color: #cecece;
}

.drag-drop {
  background: #fff;
  border-radius: 8px;
}
.document-uploader {
  border: 2px dashed #4282fe;
  background-color: #f4fbff;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;

  &.active {
    border-color: #6dc24b;
  }

  .upload-info {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0.3rem;

    svg {
      font-size: 36px;
      margin-right: 1rem;
    }

    div {
      p {
        margin: 0;
        font-size: 16px;
      }

      p:first-child {
        font-weight: bold;
      }
    }
  }

  .file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 6vh;
    &__container {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  .file-item {
    margin: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;

      p {
        margin: 0;
        font-size: 14px;
        color: #333;
        padding: 10px;
      }
    }

    .file-actions {
      .icon-close2 {
        font-size: 14px;
        color: #888;
        padding-right: 10px;
        cursor: pointer;
      }

      &:hover {
        .icon-close2 {
          color: rgb(99, 98, 98);
        }
      }
    }
  }

  .browse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: var(--primary-color);
    &:hover {
      background-color: #d7d7d7;
    }
  }

  .success-file {
    display: flex;
    align-items: center;
    color: #7db068;

    p {
      margin: 0;
      font-size: 14px;
    }
  }

  input[type='file'] {
    display: none;
  }
}
.error-message {
  font-size: 14px;
  color: #d32f2f;
  background-color: #ffebee;
  padding: 5px 10px;
  border: 1px solid #d32f2f;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.warning-message {
  font-size: 14px;
  color: #a86c05;
  background-color: #fffaeb;
  padding: 5px 10px;
  border: 1px solid #d3b22f;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.success-message {
  font-size: 14px;
  color: #23a805;
  background-color: #edffeb;
  padding: 5px 10px;
  border: 1px solid #3ad32f;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.info-message {
  font-size: 14px;
  color: #0531a8;
  background-color: #ebf5ff;
  padding: 5px 10px;
  border: 1px solid #2f6bd3;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
