.round-charts-wrap {
  display: flex;
  height: 100%;
  align-items: center;
  position: fixed;
  width: 185px;
  padding: 0 20px 30px;
  top: 60px;
  bottom: 0;
  left: 0;
  background: $color-left-aside;

  .round-charts {
    margin: 0 auto;
  }

  .round-chart--item {
    margin: 2vh 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.round-charts {
  font-size: $size-md;
  font-weight: 300;
}

.round-chart--item {
  text-align: center;

  &.active {
    font-weight: 600;

    .round-chart {
      width: 122px;
      height: 122px;
    }
  }

  &.passive {
    color: $color-grey2;

    path {
      fill: $color-grey-light;
    }

    .kpi__percentage {
      color: $color-grey-light;
    }
  }

  p {
    cursor: pointer;
    min-height: 54px;

    @media screen and (max-width: 64em) {
      min-height: 0;
      padding: 0 0 10px;
    }
  }

  &.cursor-default {
    p {
      cursor: default;
    }

    .round-chart {
      cursor: default;
    }
  }
}

.round-chart {
  cursor: pointer;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  margin: 0 auto 10px;

  * {
    font-family: $secondaryfont;
  }
}

.round-charts-inline {
  display: flex;
  padding: 0;
  flex-flow: row wrap;
  margin: 0 -10px;
  align-items: flex-end;

  .round-chart--item {
    width: auto;
    white-space: nowrap;
    padding: 0 5px;
    min-width: 88px;
    font-size: $size-sm;

    &.active {
      font-weight: 400;

      .round-chart {
        height: 85px;
        width: 85px;
      }
    }
  }

  .round-chart {
    height: 64px;
    width: 64px;
  }
}

.round-charts-center {
  justify-content: center;

  .round-chart--item {
    padding: 0 5px;
    width: 25%;

    &.active {
      width: 25%;

      @media (min-width: 933px) and (max-width: 1200px) {
        width: 100%;
      }
    }
  }
}

.round-charts--left-alignment {
  .round-chart--item {
    padding: 0 10px;

    &.active {
      padding: 0 10px;
    }
  }
}

.position-relative {
  position: relative;
}

.percentage__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kpi__percentage {
  font-size: $size-lg;
  font-weight: 600;

  &.active {
    font-size: 24px;
  }
}

.kpi-color--oee,
.datatron-color--cpu {
  fill: $color-oee;
}

.kpi-color--availability,
.datatron-color--disk {
  fill: $color-orange;
}

.kpi-color--performance,
.datatron-color--network {
  fill: $color-blue;
}

.kpi-color--quality,
.datatron-color--ram {
  fill: $color-green;
}

.kpi-color__percentage--oee,
.datatron-color__percentage--cpu {
  color: $color-oee;
}

.kpi-color__percentage--availability,
.datatron-color__percentage--disk {
  color: $color-orange;
}

.kpi-color__percentage--performance,
.datatron-color__percentage--network {
  color: $color-blue;
}

.kpi-color__percentage--quality,
.datatron-color__percentage--ram {
  color: $color-green;
}

.kpi__percentage--white {
  color: $color-white;
}

.line--availability,
.line--disk {
  stroke: $color-orange;
  fill: none;
  stroke-width: 2px;
}

.line--performance,
.line--network {
  stroke: $color-blue;
  fill: none;
  stroke-width: 2px;
}

.line--quality,
.line--ram {
  stroke: $color-green;
  fill: none;
  stroke-width: 2px;
}

.line--event-anomaly {
  stroke: $color-blue;
  fill: none;
  stroke-width: 2px;
}

.line--cpu {
  stroke: $color-oee;
  fill: none;
  stroke-width: 2px;
}

.line--event-fatal {
  stroke: $color-red;
  fill: none;
  stroke-width: 2px;
}

.line--event-error {
  stroke: $color-orange;
  fill: none;
  stroke-width: 2px;
}

.line--event-warning {
  stroke: $color-yellow;
  fill: none;
  stroke-width: 2px;
}

.line--event-info {
  stroke: $color-green;
  fill: none;
  stroke-width: 2px;
}

.line--maintenance-planned {
  stroke: $color-green;
  fill: none;
  stroke-width: 2px;
}

.line--maintenance-unplanned {
  stroke: $color-red;
  fill: none;
  stroke-width: 2px;
}

.kpi-background--dark {
  fill: #576065;
}

.kpi-background--light {
  fill: $color-grey-light;
}

.kpi-color--total-events {
  fill: $color-link;
}

.kpi-color__percentage--total-events {
  color: $color-oee;
}

.kpi-color--fatal {
  fill: $color-red;
}

.kpi-color__percentage--fatal {
  color: $color-red;
}

.kpi-color--error {
  fill: $color-orange;
}

.kpi-color__percentage--error {
  color: $color-orange;
}

.kpi-color--warning {
  fill: $color-yellow;
}

.kpi-color__percentage--warning {
  color: $color-yellow;
}

.kpi-color--info {
  fill: $color-green;
}

.kpi-color__percentage--info {
  color: $color-green;
}

.kpi-color--anomaly {
  fill: $color-blue;
}

.kpi-color__percentage--anomaly {
  color: $color-blue;
}

.kpi-color--grey {
  fill: $color-grey2;
}

.kpi-color__percentage--grey {
  color: $color-grey2;
}

.kpi-color--planned {
  fill: $color-green;
}

.kpi-color__percentage--planned {
  color: $color-green;
}

.kpi-color--unplanned {
  fill: $color-red;
}

.kpi-color__percentage--unplanned {
  color: $color-red;
}

.anomaly-chart-threshold {
  stroke: $color-orange;
  fill: none;
  stroke-width: 1px;
}

.nomaly-chart-text {
  fill: $color-orange;
}

.help-line {
  text {
    display: none;
  }

  .domain {
    display: none;
  }

  line {
    stroke: $color-grey-light;
    stroke-width: 1px;
  }
}

.nomaly-chart-label {
  font-size: 10px;
  fill: $color-grey2;
}

.slider-axix {
  -moz-transform: translateY(-8px);
  -webkit-transform: translateY(-8px);
  -o-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);

  &.percentage {
    text {
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

path.domain {
  opacity: 0;
}

.chart-axis-bottom text {
  font-size: 8px;
}

.round-chart {
  .loader-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
