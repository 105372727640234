.errorPage {
  display: flex;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 140px;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }
}
