.recent--item {
  font-size: $size-md;
  word-wrap: break-word;
  word-break: break-all;
  background: $color-white;
  margin: 0 0 10px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(220, 222, 223, 0.09);
  padding: 12px 16px;
  color: $color-text;

  &:after {
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 2px;
    border-radius: 2px;
    background: $color-text-light;
  }

  &.success {
    &:after {
      background: $color-green;
      box-shadow: 0 0 6px 0 rgba($color-green, 0.5);
    }
  }

  &.alert {
    &:after {
      background: $color-red;
      box-shadow: 0 0 6px 0 rgba($color-red, 0.5);
    }
  }

  &.error {
    &:after {
      background: $color-orange;
      box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);
    }
  }

  &.warning {
    &:after {
      background: $color-yellow;
      box-shadow: 0 0 6px 0 rgba($color-yellow, 0.5);
    }
  }

  &.info {
    &:after {
      background: $color-blue;
      box-shadow: 0 0 6px 0 rgba($color-blue, 0.5);
    }
  }
}
