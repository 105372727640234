.analyzer-container-edit-page {
  background: #fff;
  border-radius: 4px;
  padding: 0 20px 5px 20px;
  margin-bottom: 20px;
}

.analyzer-container-wrap {
  min-height: calc(100vh - 60px - 58px - 20px - 20px - 57px);
  position: relative;
}

.drag-handle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 28px;
  margin-right: 2px;
  border-radius: 3px;
  cursor: grab;
  background-color: #f0f1f4;

  &:hover {
    background-color: #e7e7e8;
  }
}

.draggable-icon {
  width: 8px;
  height: 14px;
  position: relative;
}

.draggable-icon::before,
.draggable-icon::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 3px;
  background: #686868;
  border-radius: 50%;
  box-shadow:
    0 5px 0 #686868,
    0 10px 0 #686868;
  left: 0;
}

.draggable-icon::after {
  left: 5px; /* increased spacing between columns */
}

.draggable-icon::after {
  left: 4px; /* spacing between columns */
}

.analyzer-block {
  border-bottom: 1px solid rgba($color-text, 0.2);
  margin: 0 0 30px;
  padding: 0 0 10px;

  &:last-child {
    border-bottom: none;
  }
}

.analyzer-wrap {
  margin: 0 -10px;
}

.analyzer {
  display: flex;
  flex-flow: row wrap;
}

.analyzer--col {
  width: 334px;
  padding: 0 10px;
}

.analyzer--item {
  word-wrap: break-word;
  word-break: break-all;
  background: $color-white;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(220, 222, 223, 0.09);
  padding: 10px 16px;
  color: $color-text;

  &:before {
    content: '';
    position: absolute;
    right: -1px;
    top: -1px;
    border-width: 13px;
    border-style: solid;
    border-color: transparent;
    border-radius: 0 2px 0 0;
  }

  &:hover,
  &:focus {
    .analyzer--item--title {
      text-decoration: underline;
      color: $color-text;
    }

    .analyzer--item--content {
      color: $color-text-light;
    }
  }

  &.alert {
    background: #f3f6f8;

    &:after {
      content: '!';
      position: absolute;
      right: 4px;
      top: 1px;
      font-weight: 700;
      color: $color-white;
    }

    &:before {
      border-top-color: $color-red;
      border-right-color: $color-red;
    }

    .analyzer--item--title {
      color: $color-text-light;
    }

    .info-dialog--text {
      color: $color-text-light;
    }
  }

  &.active {
    border-color: $color-blue;
    background: #fafafa;

    .analyzer--item--title {
      color: $color-blue;
    }
  }

  > div {
    width: 100%;
  }

  .info-dialog--item {
    padding: 0;
  }

  .info-dialog--text {
    color: $color-text;
  }
}

.analyzer--item--add {
  display: none;
  background: none;
  box-shadow: none;
  border: 2px dashed $color-grey2;
  color: $color-text-light;
  text-align: center;
  font-size: $size-md;

  .fa,
  .icon {
    color: $color-green;
    margin: 0 5px 0 0;
  }

  &:hover {
    color: $color-text;
  }
}

.analyzer--item--title {
  font-size: $size-md;
  font-weight: 600;
  padding: 3px 0;
  min-width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.analyzer--item--content {
  font-size: $size-sm;
  color: $color-text-light;
  padding: 3px 0;
}

.title {
  font-size: $size-md;
  padding: 0 0 5px;

  .links {
    padding: 0 0 2px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    margin-top: 0;
  }
}

.analyzer--item--header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.analyzer--item--type {
  align-items: right;
  min-width: 65px;
}
.analyzer--item--status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.analyzer--item--datatron-name {
  flex-grow: 1;
}

.analyzer--item--organization {
  margin-top: 5px;
}

.session-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #eee6e6;
}

.session-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-weight: 500;
  background-color: #f9f9f9;
  font-size: 14px;
}

.session-card-header .action-panel-session {
  display: flex;
  align-items: center;

  span.fa {
    margin: 0;
    font-size: 15px;
  }

  .cursor-time-container {
    text-align: left;
    display: inline-block;
    line-height: 27px;
    font-size: 15px;
    width: 165px;

    .cursor-time-label {
      text-align: center;
      display: inline-block;
      width: 100%;
    }
  }
}

.session-card-body {
  padding: 0;
}

.session-card-footer {
  min-height: 50px;
}

.row-signal {
  display: flex;
  background-color: #f9f9f9;
  align-items: center;
  height: 100%;
}

.row-signal:hover {
  background-color: #fff;
}

.row-signal-info {
  width: 15%;
  overflow: hidden;
  white-space: nowrap;
  line-height: 100%;
  text-overflow: ellipsis;
  display: inline-block;
}

.row-signal-chart {
  min-width: 0;
  width: calc(85% - 100px);
  width: -webkit-calc(85% - 100px);
  width: -moz-calc(85% - 100px);
  display: inline-block;
}

.row-signal-label {
  text-align: left;
  margin-left: 16px;
  display: inline-block;
  width: 95px;
  // line-height: 150px;
  line-height: 100%;
}

.row-signal-footer {
  display: flex;
  background-color: #f9f9f9;
  align-items: center;
  min-height: 50px;

  .row-signal-input {
    width: 15%;
    width: calc(15% + 65px);
    width: -webkit-calc(15% + 65px);
    width: -moz-calc(15% + 65px);
    min-height: 50px;
    display: inline-block;
    padding-top: 7px;

    .button {
      width: 100%;
    }
  }

  .x-axis-chart-footer {
    width: calc(85% - 100px);
    width: -webkit-calc(85% - 100px);
    width: -moz-calc(85% - 100px);
    display: inline-block;
    min-height: 50px;
  }
  .cursor-time-container {
    text-align: left;
    margin: 0 0 10px 0;
    display: inline-block;
    line-height: 20px;
    width: 110px;

    .cursor-time-label {
      text-align: center;
      display: inline-block;
      width: 100%;
    }
  }
}

.button.extra-button.warning {
  color: $color-white;
  &:hover {
    color: $color-white;
  }
}

.datepicker-input-session-panel {
  padding: 0 15px;
  border: 1px solid #d4d7dc;
  border-radius: 4px;
  font-size: 14px;
  height: 37px;
  width: 180px;
}

.add-signal-input {
  .select-container {
    margin-top: 6px;
    position: relative;
  }

  .select-container {
    .select__control {
      height: 37px;
      min-height: 37px;
    }

    .select__value-container {
      height: 37px;
      padding: 0 8px;
    }

    .select__single-value,
    .select__input {
      line-height: 37px;
    }

    .select__indicator {
      color: white;
      background: #5fad41;
      padding: 5px;
      margin: 5px;
      border-radius: 2px;

      &.select__dropdown-indicator,
      &.select__clear-indicator {
        color: white;
      }
    }
    .select__input {
      height: 30px;
    }

    .select__placeholder {
      text-align: left;
      line-height: 37px;
    }
  }
}

.content-state {
  --base-bg-color: transparent;
  --pulse-bg-color: #eeeff2;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #6b7280;
  background-color: var(--base-bg-color);
  transition: background-color 0.3s ease;
  margin: 0 30px;
  i {
    font-size: 14px;
  }

  &__message {
    font-size: 14px;
  }

  &.loading {
    animation: pulse 1.5s infinite ease-in-out;

    i {
      margin-right: 4px;
    }
  }

  &.error {
    color: #dc2626;

    --base-bg-color: #fef2f2;
    --pulse-bg-color: #fee2e2;
  }
}

@keyframes pulse {
  0% {
    background-color: var(--base-bg-color);
  }
  50% {
    background-color: var(--pulse-bg-color);
  }
  100% {
    background-color: var(--base-bg-color);
  }
}

.x-axis-chart {
  width: 100%;
  height: 50px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.chart-tooltip {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10000000;

  &:focus {
    outline: none;
  }
}

.tooltip-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.tooltip-label {
  font-weight: bold;
}

.timeline-tools {
  display: flex;
  border-radius: 4px;
  border: 1px solid #ddd;

  .tool-button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8f9296;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 0;
    border-radius: 4px;

    &:hover {
      background-color: $color-grey-light3;
    }

    &.active {
      background-color: $color-grey-light;
    }
  }
}

$verdigris: #43b3ae;
$verdigrisHover: #e7f3f3;
$gray-400: #9ca3af;

.contentClass {
  background-color: white;
  border-radius: 0.375rem;
  padding: 0.35rem;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border: 1px solid $color-link-light;
  z-index: 40;
}

.itemClass {
  &:focus {
    outline: none;
  }

  position: relative;
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  border-radius: 0.125rem;
  font-size: 0.75rem;
  .fa {
    padding-left: 5px;
  }
}

.labelClass {
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  font-size: 0.8rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid $color-grey2;
  margin-top: 3px;
}

.itemEnabledClass {
  &:hover {
    background-color: $color-grey-light2;
  }
}

.itemDisabledClass {
  color: $gray-400;
}

// Combined class
.subTriggerClass {
  @extend .itemClass;
  @extend .itemEnabledClass;
}

html body[data-scroll-locked] {
  --removed-body-scroll-bar-size: 0 !important;

  margin-right: 0 !important;
}

.display-session-anomaly-data {
  width: 100%;

  &__container {
    width: 100%;
  }

  &__row {
    display: flex;
    font-weight: 400;
    border-bottom: 1px solid $color-grey-light2;

    &--even {
      background-color: $color-white;
    }

    &--odd {
      background-color: $color-grey-light3;
    }
  }

  &__key-cell {
    flex: 1;
    padding: 10px;
    text-transform: uppercase;
  }

  &__value-cell {
    flex: 2;
    padding: 10px;
  }
}

.session-alert {
  position: relative;
  padding: 15px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.3s ease;

  &.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}

.date-separator {
  margin: 0 3px;
  user-select: none;
}

.input-cursor {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    font-weight: 400;
  }

  &-wrapper {
    display: flex;
    padding: 0.25rem 0.475rem;
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    background-color: var(--bg-color, white);
    font-size: 0.85rem;
    transition: all 0.3s ease;

    &:hover {
      border-color: var(--border-hover-color, #2bc6ce);
    }

    &:focus-within {
      border-color: var(--border-focus-color, #00acb6);
      outline: none;
    }
    .input-2-digits {
      min-width: 18px;
      text-align: center;
    }
  }

  &-display {
    display: inline-block;
    position: relative;
    min-width: 9px;
    text-align: center;

    &:hover {
      background-color: #ecfbfb;
    }

    &:focus {
      outline: none;
      font-weight: 500;
      background-color: #b7dbe1;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
      background-color: #ccc;
    }
    &:disabled &:hover {
      background-color: #dfdcdc;
    }
  }
}

.print-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  background-color: white;
  font-size: 15px;
}

.print-header-left {
  display: flex;
  align-items: center;
}

.print-header-right {
  display: flex;
  align-items: center;
}

.print-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

@media print {
  body * {
    visibility: visible;
  }
  .header {
    display: none;
  }
  .print-header-container {
    display: none;
  }

  .analyzer-container-wrap {
    flex-direction: column;
    position: static;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .panel-print-container {
    padding: 20px;
    page-break-inside: avoid;
    border: 1px solid #ccc;
    background-color: white;
  }

  .print-panel-header {
    display: flex;
    flex-direction: column;
    font-size: 10pt;
    width: 100%;
  }

  .print-panel-info {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }

  .print-panel-info > div {
    width: 50%;
    margin-bottom: 3px;
    display: flex;
  }

  .font-bold {
    font-weight: 500;
    margin-right: 5px;
    width: 80px;
    font-size: 10pt;
  }

  .print-signal-container {
    margin-top: 5px;
    margin-bottom: 5px;
    page-break-inside: avoid;
  }

  .print-signal-name {
    line-height: 30px;
    font-weight: 500;
  }

  /* Ensure charts fit on page */
  .print-signal-chart {
    max-width: 100%;
    height: auto !important;
  }
}

@page {
  size: A4;
  margin: 10mm;
}

/* Optional: For screen preview of print layout */
@media screen {
  .panel-print-container {
    max-width: 210mm; /* A4 width */
    margin: 20px auto;
    border: 1px solid #ccc;
    padding: 20px;
    background-color: white;
  }

  .analyzer-container-wrap {
    flex-direction: column;
    position: static;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .panel-print-container {
    padding: 20px;
    page-break-inside: avoid;
    border: 1px solid #ccc;
    background-color: white;
  }

  .print-panel-header {
    display: flex;
    flex-direction: column;
    font-size: 10pt;
    width: 100%;
  }

  .print-panel-info {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }

  .print-panel-info > div {
    width: 50%;
    margin-bottom: 3px;
    display: flex;
  }

  .font-bold {
    font-weight: 500;
    margin-right: 5px;
    width: 80px;
    font-size: 10pt;
  }

  .print-signal-container {
    margin-top: 5px;
    margin-bottom: 5px;
    page-break-inside: avoid;
  }

  .print-signal-name {
    line-height: 30px;
    font-weight: 500;
  }

  /* Ensure charts fit on page */
  .print-signal-chart {
    max-width: 100%;
    height: auto !important;
  }
}

.modal--content {
  .search-container {
    position: relative;
    margin-bottom: 20px;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }

  .search-input {
    width: 100%;
    padding: 10px 10px 10px 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }

  .signals-list {
    border: 1px solid $color-grey-light2;
  }

  .signal-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
    cursor: pointer;
    border-bottom: 1px solid $color-grey-light2;
    transition: background-color 0.2s;
  }

  .signal-item:hover {
    background-color: #f5f5f5;
  }

  .signal-item.selected {
    background-color: #e3f2fd;
  }

  .signal-info {
    flex: 1;
  }
  .search-icon-container {
    padding: 10px;
    font-size: 15px;
    color: $color-grey;
  }

  .signal-name {
    font-weight: 500;
  }

  .signal-details {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
  }

  .signal-status {
    padding: 2px 6px;
    border-radius: 3px;
    margin-right: 10px;
  }

  .signal-status.enabled {
    background-color: #e8f5e9;
    color: #2e7d32;
  }

  .signal-status.disabled {
    background-color: #ffebee;
    color: #c62828;
  }

  .signal-checkbox {
    width: 24px;
    height: 24px;
    border: 2px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2196f3;
  }

  .button__save:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .filter-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin-top: 20px;
  }

  .filter-checkbox input {
    display: none;
  }

  .filter-checkbox label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .filter-checkbox .fa {
    font-size: 22px;
    color: #007bff;
  }

  .filter-checkbox span {
    font-size: 14px;
    color: #333;
  }

  .search-input-container {
    position: relative;
    width: 100%;
    padding: 10px;
    border: 1px solid $color-grey3;
    border-radius: 4px;
  }
  .search-input-container:focus {
    border-color: #007bff;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-400;
    font-size: 14px;
  }

  .search-input {
    width: 100%;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s ease;
  }
  .search-input::placeholder {
    color: #999;
  }
}

.analyzer-blocks {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
