.maintenances--list {
  padding: 0 0 10px;

  /*
  thead,
  tbody,
  tfoot {
    border: none;
  }

  tbody {
    tr {
      margin: 0 0 10px;
      background: none;
    }

    td {
      background: #fafafa;
      border-bottom: 10px solid $color-white;
    }
  }
  */

  .opened {
    .maintenances--more--btn {
      .fa-caret-right,
      .icon-caret-right {
        display: none;
      }

      .fa-caret-down,
      .icon-caret-down {
        display: inline-block;
      }
    }
  }
}

.maintenances--time {
  white-space: nowrap;
  font-size: $size-sm;

  .icon {
    color: $color-text-light;
    margin: 0 5px 0 0;
  }
}

.maintenances--person {
  white-space: nowrap;
  font-size: $size-sm;

  .icon {
    color: $color-blue;
    margin: 0 5px 0 0;
  }

  .icon-time {
    color: $color-text-light;
    font-size: 7px;
    vertical-align: top;
    margin: -5px 5px 0 -7px;
    display: inline-block;
  }
}

.maintenances--description {
  line-height: 1.6;
  padding: 10px 0 0;
  color: $color-text-light;

  &.pt-5 {
    padding-top: 5px;
  }
}

.maintenances--more--btn {
  flex: 0 auto;
  width: 20px;
  height: 20px;
  margin: 0;
  text-align: center;
  line-height: 20px;
  display: inline-block;
  color: $color-grey2;
  cursor: pointer;

  &:hover {
    color: $color-grey;
  }

  .fa-caret-right,
  .icon-caret-right {
    display: inline-block;
  }

  .fa-caret-down,
  .icon-caret-down {
    display: none;
  }
}
