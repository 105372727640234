.chartBox {
  width: inherit;
  height: inherit;
}

.pointer {
  cursor: pointer;
}

.image-render {
  overflow: hidden;
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;

  &.sm {
    margin: 0 auto;
    height: 200px;
    width: 200px;
  }
}

.cursor-default {
  cursor: default !important;
}
