.schedule {
  position: relative;
  padding: 15px 0;

  .history {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
  }

  .history-square {
    border-radius: 0;
  }

  .parts--top {
    padding-bottom: 20px;
  }
}

.schedule--top {
  text-align: center;
}

.schedule--top--arrows {
  display: inline-block;
  vertical-align: top;
  font-size: 9px;

  a {
    display: inline-block;
    color: $color-text-light;
    width: 19px;
    height: 19px;
    text-align: center;
    line-height: 19px;
    border: 1px solid $color-text-light;
    border-radius: 50%;

    &:hover {
      color: $color-link;
      border-color: $color-link;
    }

    .fa-chevron-left {
      margin-left: -1px;
    }

    .fa-chevron-right {
      margin-right: -1px;
    }
  }
}

.schedule--top--date {
  font-weight: 500;
  display: inline-block;
  padding: 0 11px;
  vertical-align: top;
  line-height: 20px;
}

.schedule--table {
  //overflow-x: auto;
  margin-left: 100px;
  overflow-y: visible;
  padding: 0 0 10px;
  font-size: 12px;

  table {
    border-collapse: collapse;
    border-spacing: 0;

    thead,
    tbody,
    tfoot {
      border: none;
    }
  }

  thead {
    td,
    th {
      background: transparent;
      text-align: center;
      height: 36px;
      border: none;

      &.headcol {
        background: transparent;
        border: none !important;

        &:after {
          display: none;
        }
      }
    }

    .schedule-in {
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      background: $color-white;

      &:first-child {
        td {
          border-width: 1px 1px 1px 0;
        }
      }
    }

    td {
      border-width: 0 1px 1px 0;
    }

    .passive {
      background: #fafafa;

      .headcol {
        background: $color-white;
      }
    }

    .success {
      .headcol {
        &:after {
          background: $color-green;
          box-shadow: 0 0 6px 0 rgba($color-green, 0.5);
        }
      }
    }

    .alert {
      .headcol {
        &:after {
          background: $color-red;
          box-shadow: 0 0 6px 0 rgba($color-red, 0.5);
        }
      }
    }

    .error {
      .headcol {
        &:after {
          background: $color-orange;
          box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);
        }
      }
    }

    .warning {
      .headcol {
        &:after {
          background: $color-yellow;
          box-shadow: 0 0 6px 0 rgba($color-yellow, 0.5);
        }
      }
    }

    .info {
      .headcol {
        &:after {
          background: $color-blue;
          box-shadow: 0 0 6px 0 rgba($color-blue, 0.5);
        }
      }
    }

    .weekend {
      .headcol {
        &:after {
          background: $color-blue;
          box-shadow: 0 0 6px 0 rgba($color-blue, 0.5);
        }
      }

      td {
        background-color: #f9fafb;
        border-right-color: transparent;

        &:first-child {
          border-right-color: $color-right-popup;
        }

        &:last-child {
          border-right-color: $color-right-popup;
        }
      }
    }
  }

  tr {
    display: flex;
  }

  td {
    margin: 0;
    border: 1px solid $color-right-popup;
    padding: 0;
    -ms-flex: 1 1 auto;
    flex: 1;
    display: flex;
  }

  .headcol {
    position: absolute;
    width: 100px;
    left: 1px;
    top: auto;
    vertical-align: top;
    background: $color-white;
    margin-top: -36px\9;

    @-moz-document url-prefix() {
      margin-top: 0;
    }

    &:after {
      content: "";
      position: absolute;
      left: -1px;
      top: -1px;
      bottom: -1px;
      width: 3px;
      border-radius: 2px;
      background: $color-text-light;
    }

    .schedule-in {
      font-weight: 500;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      color: $color-grey;
    }
  }
}

.schedule-in {
  width: 60px;
  height: 35px;
  padding: 8px 10px 7px;
  position: relative;
}

.schedule-item-line {
  position: absolute;
  padding: 0 1px;
  border-width: 0 2px;
  border-style: solid;
  line-height: 26px;
  height: 28px;
  top: 3px;
  cursor: pointer;
  color: $color-text;
  border-color: $color-link;
  background-color: #dff5f6;
  z-index: 1;

  &:hover {
    z-index: 2;
  }

  &.selected {
    z-index: 3;
  }

  .fa,
  [class^="icon-"],
  [class*=" icon-"] {
    color: $color-link;
    margin: 7px 7px 0 0;
    display: inline-block;
    vertical-align: top;
  }

  &.active {
    border-style: dashed;
    background-color: #cde9f6;

    &:before,
    &:after {
      content: "||";
      border: 1px solid $color-link;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
      font-size: 9px;
      color: $color-link;
      width: 16px;
      height: 16px;
      position: absolute;
      left: -9px;
      top: 50%;
      margin: -8px 0 0;
      background: $color-white;
    }

    &:after {
      left: auto;
      right: -9px;
    }

    .fa,
    [class^="icon-"],
    [class*=" icon-"] {
      opacity: 0.7;
    }
  }

  &.current {
    border-color: $color-yellow;
    background: #fef7e4;

    &.active {
      &:before,
      &:after {
        border-color: $color-yellow;
        color: $color-yellow;
      }
    }
  }

  .two-lines & {
    height: 14px;
    border-width: 0 2px;

    &.calendar-schedule {
      top: 2px;
    }

    &.calendar-shift {
      top: 19px;
    }

    .schedule-item--popup {
      top: 0;
      font-size: 15px;
      height: 30px;
      line-height: 30px;
      border-radius: 3px;
      transform: translateY(-25%);

      &:after {
        border-width: 3px;
        margin-top: -3px;
        left: -6px;
      }

      &.left {
        &:after {
          left: auto;
          right: -6px;
        }
      }

      &.outside {
        left: auto;
        right: 100%;
        margin: 0 10px 0 0;
        box-shadow: 0 1px 5px 1px rgba($color-black, 0.2);

        &:after {
          left: auto;
          right: -6px;
          border-color: transparent transparent transparent $color-white;
        }
      }
    }
  }

  &.calendar-schedule {
    border-color: $color-yellow;
    background: rgba($color-yellow, 0.2);

    &:before,
    &:after {
      border-color: $color-yellow;
      color: $color-yellow;
    }

    .schedule-hidden & {
      display: none;
    }
  }

  &.calendar-shift {
    border-color: $color-link;
    background: rgba($color-link, 0.2);

    .shift-hidden & {
      display: none;
    }
  }
}

.schedule-item-line-in {
  position: relative;
  margin: 0 -1px;
  height: 100%;
}

.schedule-popup {
  position: absolute;
  bottom: 35px;
  left: 50%;
  background: $color-white;
  box-shadow: 0 1px 5px 2px rgba($color-black, 0.15);
  width: 120px;
  margin: 0 0 0 -60px;
  color: $color-text-light;
  font-size: 12px;
  padding: 10px 10px 7px;
  border-radius: 5px;
  text-align: center;
  line-height: 14px;

  &:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin: 0 0 0 -8px;
    border-style: solid;
    border-width: 5px 8px;
    border-color: $color-white transparent transparent;
  }

  strong {
    font-weight: 500;
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: $color-text;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.schedule-item--popup {
  background: $color-white;
  padding: 2px 6px;
  font-size: 15px;
  position: absolute;
  left: 5px;
  height: 30px;
  white-space: nowrap;
  box-shadow: 0 1px 4px rgba($color-black, 0.1);
  border-radius: 4px;
  line-height: 30px;
  display: flex;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 50%;
    border: 5px solid transparent;
    border-right-color: $color-white;
    margin-top: -5px;
  }

  &.left {
    left: auto;
    right: 5px;

    &:after {
      left: auto;
      right: -10px;
      border-color: transparent transparent transparent $color-white;
    }

    .popup--time--change {
      order: 2;
    }

    .popup--action--buttons {
      order: 1;
    }

    .button {
      margin: 0 5px 0 0;
    }

    .rel {
      margin: 0 5px 0 0;

      .button {
        margin: 0;
      }
    }
  }

  &.outside {
    box-shadow: 0 1px 5px 2px rgba($color-black, 0.2);
    left: 100%;
    margin: 0 0 0 10px;

    &.left {
      left: auto;
      right: 100%;
      margin: 0 10px 0 0;
    }
  }

  .rel {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5px;

    .button {
      margin: 0;
    }
  }

  .button {
    width: 22px;
    height: 22px;
    padding: 0;
    text-align: center;
    line-height: 20px;
    font-size: 8px;
    margin: 0 0 0 5px;
    background: #fafafa;

    .icon {
      margin: 0;
      line-height: 20px;
      color: $color-grey2 !important;
    }

    .icon-remove {
      font-size: 11px;
      color: $color-red !important;
    }

    .icon-checkmark {
      line-height: 21px;
      color: $color-green !important;
    }

    .icon-reload-clockwise {
      font-size: 10px;
      color: $color-grey !important;
      margin-left: 2px;
    }
  }

  &.on-hover {
    min-width: 116px;
  }

  &.on-click {
    min-width: 220px;
  }
}

.popup--action--popup {
  position: absolute;
  left: 50%;
  top: 100%;
  background: $color-white;
  box-shadow: 0 1px 4px rgba($color-black, 0.1);
  border-radius: 4px;
  white-space: normal;
  width: 180px;
  margin: 3px 0 0 -90px;
  z-index: 9;
  padding: 6px;
  cursor: default;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -10px;
    border: 5px solid transparent;
    border-bottom-color: $color-white;
    margin-left: -5px;
  }

  .checkbox,
  .radio {
    line-height: 1.3;
    display: block;
    margin: 3px 0;
    font-size: 12px;
    cursor: pointer;

    > span {
      padding-left: 20px;
    }
  }

  .action--row {
    display: flex;

    &.column--row {
      display: block;

      .action--label {
        width: auto;
        text-align: left;
      }

      .action--text {
        padding-left: 0;
      }
    }
  }

  .action--label {
    flex: 0 auto;
  }

  .action--text {
    flex: 1;
    padding-left: 6px;
  }

  .filter-action {
    display: block !important;
    padding: 2px 5px;
    color: $color-text;
    font-weight: 300;

    &:hover {
      background: $color-link-light2;
    }
  }
}

.popup--time--change {
  display: flex;
  background-color: #f0f2f5;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding-left: 1px;

  input {
    background: none;
    border: none;
    text-align: center;
    flex: 1;
    height: 25px;
    padding: 0;
    font-size: 15px;
    color: $color-text;
    margin-bottom: -3px;
    min-width: 1px;

    @include placeholder {
      color: $color-text;
    }

    &:focus {
      border: none;
      background: none;
    }
  }

  .popup-sep {
    flex: 0 auto;
    margin: 3px 0 0 2px;
  }
}

.popup--time--change.invalid {
  background-color: #faa !important;
}

.popup--action--buttons {
  display: flex;
  align-items: center;
}
