.datapoints-all {
  margin-bottom: 25px;

  .line {
    background: $color-grey-light;
    height: 1px;
  }
}

.datapoints-block {
  position: relative;
  display: flex;
  border: 1px solid $color-grey-light;
  border-radius: 5px;
  font-size: $size-md;
  margin: 15px 0 0;
}

.datapoints--left {
  flex: 0 auto;
  width: 180px;
  padding-right: 1px;
  overflow: auto;
  height: 350px;
  background: #f8f8fc;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: $color-grey-light;
  }
}

.datapoints--right {
  flex: 1;
  overflow: auto;
  height: 350px;
  position: relative;
  margin-right: 1px;
}

.datapoints-results {
  position: relative;
}

.datapoints-result {
  position: relative;
  border-bottom: 1px solid $color-grey-light;
  border-radius: 5px 0 0;
  padding: 7px 46px 7px 10px;
  font-weight: 500;
  font-size: 10px;
  background: $color-white;
  color: $color-text-light;
  cursor: pointer;

  &:hover {
    color: $color-text;
  }

  * {
    cursor: pointer;
  }

  label {
    font-size: 10px;
    color: $color-text-light;
    font-weight: 300;
    line-height: 1.4;
  }

  .fa-check-circle,
  .fa-times-circle {
    display: none;
    position: absolute;
    right: 17px;
    top: 50%;
    margin: -6px 0 0;
    font-size: 12px;
    color: $color-grey2;
  }

  .fa-times-circle {
    display: none;
  }

  &.selected {
    color: $color-text;

    .fa-check-circle {
      display: block;
      color: $color-green;
      text-shadow: 0 0 5px $color-green;
    }

    &:hover {
      cursor: pointer;

      * {
        cursor: pointer;
      }

      .fa-check-circle {
        display: none;
      }

      .fa-times-circle {
        display: block;
        color: $color-red;
        text-shadow: 0 0 5px $color-red;
      }
    }
  }

  &.active {
    background: $color-white;
  }

  &.passive {
    background: $color-white;
  }

  &.opened {
    &:after {
      content: '';
      position: absolute;
      right: -1px;
      top: 0;
      bottom: 0;
      width: 1px;
      background: $color-white;
      z-index: 10;
    }
  }
}

.datapoints-list {
  padding: 0;
  list-style: none;
  font-size: 12px;
  line-height: 18px;

  li {
    display: flex;
    color: $color-text-light;
    padding: 7px 10px;
    position: relative;
    cursor: pointer;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    &:hover {
      color: $color-text;
      background: #f8f8fc;
    }

    .button_unchecked,
    .button_checked,
    .button_uncheck {
      flex: 0 auto;
      margin-top: 2px;
    }

    .button_uncheck {
      + .search-item-text {
        color: $color-text;
      }
    }

    .search-item-text {
      flex: 1;
      line-height: 22px;
    }
  }
}

.datapoints-empty {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  line-height: 1.4;
  color: $color-text-light;

  > div {
    width: 170px;
    margin: 0 auto;
  }

  .link-text {
    color: $color-text-light;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $color-text-light;
    }
  }
}

.datapoints-empty-icon {
  margin-bottom: 15px;
}

.addbutton-wrap {
  height: 0;
  position: relative;

  .button {
    position: absolute;
    right: 0;
    top: -20px;
    z-index: 15;
  }
}

.datapoints-filter-wrap {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid $color-grey-light;
  .rel-wrap {
    height: 26px;
    width: 256px;
    > .flex {
      position: relative;
    }
  }
  .flex-icon {
    height: 26px;
  }
  .table-search {
    margin-top: -13px;
  }
}

.datapoints-filter {
  display: flex;
  align-items: center;
  margin-right: 20px;
  .flex-name,
  .flex-name-label {
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

li a.filter-action > .flex > .fa {
  color: #818688;
}

li {
  &:has(> a.filter-action > .flex > .fa.fa-download) {
    border-top: 1px solid #e5e5e5;
  }
}

li {
  &:has(> a.filter-action > .flex > .fa.fa-list-alt) {
    border-top: 1px solid #e5e5e5;
  }
}
