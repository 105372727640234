.brush {
  .selection {
    fill: #85c9e9;
    opacity: 0.7;
    stroke: #4591e5;
    stroke-width: 1.5;
  }
}

.slider__axis {
  .domain {
    display: none;
  }

  .tick {
    line {
      stroke: #d1d1d1;
      stroke-width: 1;
    }

    text {
      fill: #b0b0b0;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.slider__thumb-chart {
  stroke: #bebebe;
  stroke-width: 1;
  fill: none;
}

.chart-border-rect {
  stroke: #dbe1eb;
}

.chart-fill-rect {
  fill: #f5f5f5;
}
