.onoffswitch-label {
  text-align: left;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  position: relative;
  width: 40px;

  input {
    display: none;
  }

  &.small {
    width: 24px;
    margin: 0 auto;

    .onoffswitch-inner {
      &:before,
      &:after {
        height: 14px;
        line-height: 14px;
      }
    }

    .onoffswitch-switch {
      right: 10px;
      width: 10px;
      height: 10px;
    }
  }
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;

  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 16px;
    padding: 0;
    line-height: 16px;
    font-size: 10px;
    color: $color-white;
    text-transform: uppercase;
  }

  &:before {
    content: attr(data-on);
    padding-left: 5px;
    background-color: $color-green;
    color: $color-white;
  }

  &:after {
    content: attr(data-off);
    padding-right: 5px;
    background-color: $color-grey2;
    color: $color-grey;
    text-align: right;
  }
}

.onoffswitch-switch {
  display: block;
  width: 12px;
  height: 12px;
  margin: 2px;
  background: $color-white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked {
  + .onoffswitch-inner {
    margin-left: 0;

    + .onoffswitch-switch {
      right: 0;
    }
  }
}
