.event-empty {
  min-height: calc(100vh - 60px - 58px);
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  line-height: 1.4;
  color: $color-text-light;

  > div {
    width: 100%;
  }
}

.event-empty-icon {
  font-size: 60px;
  padding: 0 0 10px;
}

.folder-empty {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  line-height: 1.4;
  color: $color-text-light;

  > div {
    width: 260px;
    margin: 0 auto;
  }

  .open-folder-icon {
    font-size: 60px;
    padding: 0 0 10px;
  }
}
