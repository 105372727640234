.header {
  color: $color-white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  height: 90px;
  background: $color-header-bk;
}

.header--row {
  display: flex;
  position: relative;
  border-top: 1px solid #3f494e;
  width: 100%;

  @include breakpoint(large down) {
    flex-flow: row;
  }
}

.header--row-thin {
  height: 29px;
  position: relative;
  width: 100%;
}

.header-logo {
  flex: 0 auto;
  padding: 0 20px;
  height: 60px;
  display: flex;
  justify-content: center;
  font-size: 9px;
  flex-direction: column;

  // Fix logo size on large screens
  @include breakpoint(medium down) {
    flex: 0 auto;
    max-width: none;
    min-width: $logo-sm-min-width;
  }

  img {
    max-height: 30px;
    max-width: 160px;
  }

  .powered-by {
    font-family: 'Roboto';
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: $color-link;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}

.powered-by--wrap {
  padding-top: 2px;
  margin-bottom: -5px;

  @include breakpoint(medium down) {
    display: none;
  }
}

.logo {
  display: block;
  margin: 3px 10px 0 0;
  position: relative;

  @include breakpoint(medium down) {
    margin-right: 0;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  object {
    vertical-align: top;
  }

  .logo-normal {
    margin-top: 0;

    @include breakpoint(medium down) {
      display: none;
    }
  }

  .logo-small {
    display: none;

    @include breakpoint(medium down) {
      display: inline-block;
    }
  }
}

.header-menu {
  flex: 1;
  padding: 0 0 0 20px;
  text-align: center;

  @include breakpoint(medium down) {
    flex: 1;
    max-width: none;
    text-align: left;
    padding: 0;
  }

  .menu {
    display: flex;
    vertical-align: top;
    font-weight: 600;
    white-space: nowrap;

    > li {
      > a {
        color: $color-white;
        padding: 17px 15px;
        line-height: 26px;
        text-decoration: none;
        height: 60px;

        @include breakpoint(medium down) {
          padding: 17px 10px;
        }

        &:hover {
          color: $color-header-icon;
        }

        .icon {
          color: $color-header-icon;
          font-size: $size;
          margin: 0 10px 0 0;

          @media (max-width: 1300px) {
            display: none;
          }
        }

        .icon-products {
          font-size: 20px;
          margin-top: -2px;
          display: inline-block;

          @media (max-width: 1300px) {
            display: none;
          }
        }

        .icon-experiment {
          font-size: 16px;
          margin-top: -2px;
          display: inline-block;

          @media (max-width: 1300px) {
            display: none;
          }
        }
      }

      &.active {
        a {
          background: $color-header-active;
          color: $color-white;

          .icon {
            color: $color-header-active-icon !important;
          }
        }
      }
    }
  }
}

.menu {
  > li {
    > a {
      > span {
        vertical-align: middle;
      }
    }
  }
}

.header-search {
  flex: 0 auto;
  min-width: 14%;

  @include breakpoint(medium down) {
    flex: 0 auto;
    width: 270px;
    max-width: 270px;
    float: right;
  }
}

.header-profile {
  flex: 0 auto;
  padding: 0 20px;
  text-align: right;
  float: right;
}

.menu-icon {
  color: $color-white;
  opacity: 0.5;
  display: inline-block;
  margin: 19px 0 0 7px;

  &:hover {
    opacity: 1;

    &:after {
      background: $color-white;
      box-shadow:
        0 7px 0 $color-white,
        0 14px 0 $color-white;
    }
  }

  @include breakpoint(medium up) {
    margin: 0 0 0 -15px;
    height: 60px;
    width: 26px;
    background: none;

    &:after {
      box-shadow: none;
      background: none;
      height: auto;
      content: '';
      position: absolute;
      right: 11px;
      top: 50%;
      border: 4px solid transparent;
      border-top-color: $color-white;
      margin: -1px 0 0 0;
      opacity: 1;
      width: auto;
      left: auto;
    }

    &:hover {
      &:after {
        background: none;
        box-shadow: none;
      }
    }
  }
}

.profile {
  white-space: nowrap;

  .menu-icon {
    display: none;
  }

  @include breakpoint(medium down) {
    .profile--ico {
      display: none;
    }

    .profile--name {
      display: none;
    }

    &.profile--link {
      padding-right: 7px;
      padding-left: 7px;

      &:after {
        display: none;
      }

      .menu-icon {
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        width: 20px;
        height: 16px;

        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 2px;
          background: #fefefe;
          top: 0;
          left: 0;
          border: none;
          box-shadow:
            0 7px 0 #fefefe,
            0 14px 0 #fefefe;
        }

        &:hover {
          &:after {
            background: #fff;
            box-shadow:
              0 7px 0 #fff,
              0 14px 0 #fff;
          }
        }
      }
    }
  }
}

.topMenu {
  display: none;
  text-align: left;
  position: absolute;
  right: 20px;
  top: 100%;
  background: $color-white;
  padding: 10px 16px;
  margin: 0 0 12px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.23);
  z-index: 999;
  min-width: 130px;

  @include breakpoint(medium down) {
    right: 20px;
  }

  &.in {
    display: block;
  }

  &:after {
    //content: "";
    position: absolute;
    right: 13px;
    top: -12px;
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-color: $color-white;
  }

  .profile {
    color: $color-text;

    &:hover,
    &:active,
    &:focus {
      color: $color-text;

      .profile--name {
        color: $color-text;
      }
    }
  }

  .topMenu--item {
    padding: 0;
  }

  .topMenu--link {
    color: $color-text;
    text-decoration: none;
    margin: 0 -16px;
    padding: 5px 20px;
    display: block;

    &:hover {
      color: $color-text;
      background: $color-link-light;

      .icon {
        color: $color-link;
      }
    }

    .icon {
      color: $color-grey2;
      float: left;
      margin: 4px 10px 0 0;
    }

    .icon-admin {
      font-size: 16px;
      margin: 2px 8px -2px -2px;
    }
  }
}
