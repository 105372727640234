.col--head--title {
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;

  .col--head--icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 2px;

    &.shift--icon {
      background: $color-link;
    }

    &.schedule--icon {
      background: $color-orange;
    }
  }

  .label-num {
    margin: -1px 0 0 8px;
    background: $color-link;
    color: $color-white;
    border-radius: 4px;
    line-height: 16px;
    min-width: 15px;
    text-align: center;
    padding: 0 1px;
    font-size: 10px;
    height: 15px;
    font-weight: 400;
    vertical-align: top;
  }
}

.col--head--search {
  flex: 0 auto;
}

.col--head--menu {
  flex: 0 auto;
  font-weight: 400;
  margin: 0;

  .filter--vertical--points {
    margin: 0 -15px 0 0;
  }
}

.filter--vertical--points {
  &:hover {
    .filter--btn {
      color: $color-text;
      background: darken($color-white, 5%);

      .point-dot {
        background: $color-grey4;

        &:before,
        &:after {
          background: $color-grey4;
        }
      }
    }
  }

  .filter--btn {
    @extend .button;
    @extend .light-grey-white;

    display: inline-flex;
    padding: 5px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    line-height: 2px;
    position: relative;

    .point-dot {
      vertical-align: top;
      border-radius: 50%;
      width: 3px;
      height: 3px;
      background: $color-text-light;
      display: inline-block;
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: $color-text-light;
        border-radius: 50%;
        width: 3px;
        height: 3px;
        margin: -5px 0 0 0;
      }

      &:after {
        margin-top: 5px;
      }
    }
  }

  /*
  .filter--dropdown {
    left: auto;
    right: 0;
    top: 95%;
    font-size: 11px;
    transition-delay: 0s;
    transition-duration: 0s;
    box-shadow: 0 2px 10px 0 rgba(46, 47, 66, .07);
    border-radius: 2px;
    letter-spacing: .4px;
    line-height: 22px;
    font-weight: 400;
    min-width: 90px;
    border: 1px solid $color-background;

    &:before,
    &:after {
      content: "";
      width: auto;
      height: auto;
      position: absolute;
      left: auto;
      bottom: auto;
      right: 8px;
      top: -8px;
      border-width: 4px;
      border-style: solid;
      border-color: transparent transparent $color-background;
    }

    &:after {
      top: -7px;
      border-color: transparent transparent $color-white;
    }
    */

  .filter--dropdown {
    left: auto;
    right: 0;
    font-weight: 500;
    top: 110%;
    border: 1px solid $color-grey-light;
    box-shadow: 0 2px 10px 0 rgba($color-grey, 0.08);
    border-radius: 2px;
    min-width: 100px;
    font-size: 11px;
    transition-delay: 0s;
    transition-duration: 0s;
    letter-spacing: 0.4px;
    line-height: 22px;

    ul {
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        right: 5px;
        top: -12px;
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent $color-grey-light;
      }

      &:after {
        top: -11px;
        border-color: transparent transparent $white;
      }
    }

    .filter-action {
      color: $color-grey4;
      font-size: 11px;
      padding: 7px 14px;

      &:hover {
        background: $color-white-dark;
        color: $color-text;

        [class^="icon-"],
        [class*=" icon-"] {
          color: $color-link;
        }
      }

      [class^="icon-"],
      [class*=" icon-"] {
        font-size: 10px;
        width: 10px;
        text-align: center;
        margin-right: 10px;
      }

      .icon-duplicate {
        margin-left: -3px;
        margin-right: 13px;
      }

      .icon-hide {
        margin-left: -2px;
        margin-right: 12px;
      }

      .icon-close2 {
        font-size: 8px;
      }

      .flex {
        align-items: center;
        display: flex;
      }
    }

    .data-table & {
      top: 100%;
    }

    a {
      line-height: 14px;
      border-radius: 0 !important;
      color: $color-grey4;
      padding: 7px 13px 7px 20px;

      &:hover {
        color: $color-text;
        background: $color-white-dark;
      }
    }
  }

  .dots--left & {
    .filter--dropdown {
      left: 0;
      right: auto;
    }
  }
}

.col-search {
  width: 250px;
  padding: 0;
  display: block;
  -webkit-transition: width 0.5s ease-out;
  -moz-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
  z-index: 1;

  .input-group {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .search-input {
    height: 26px;
    border: 1px solid transparent !important;
    border-left: none !important;
    order: 2;
    border-radius: 0 !important;
    padding: 0 5px;

    &::placeholder {
      color: #919191;
    }

    &:focus {
      border-color: transparent !important;
      box-shadow: none;
      background: transparent !important;

      ~ .icon-search {
        color: $color-link;
      }

      ~ .input-group-button {
        .search-button {
          .icon-search {
            color: $color-link;
          }
        }
      }
    }
  }

  .input-group-button {
    order: 1;
    width: auto;

    &.last {
      order: 3;
    }
  }

  .search-button {
    height: 26px;
    width: 20px;
    border: 1px solid transparent !important;
    border-right: none;
    text-align: center;
    font-size: 12px;

    &:hover {
      color: $color-link;

      .icon-search {
        color: $color-link;
      }
    }
  }

  .icon-search {
    color: $color-text-light;
  }

  .search-button--close {
    border-left: none;
    border-right: 1px solid transparent;
    font-size: 6px;
    color: $color-red;

    &:hover {
      color: darken($color-red, 20%);
    }
  }

  .search-button--trigger {
    display: none;
  }

  &.compressed {
    width: 20px;

    .search-button--submit {
      display: none;
    }

    .search-button--close {
      display: none;
    }

    .search-button--trigger {
      display: block;
      border-color: transparent;
    }

    .search-input {
      width: 0;
      overflow: hidden;
      padding: 0;
      border-color: transparent !important;
    }
  }
}

.no-calendars {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  color: #8f9295;
  font-size: 1.5em;
}

.calendar--position {
  background: $color-white;
  border-radius: 4px;
  margin: 0 -5px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .calendars & {
    margin: 0 -5px 15px;
    min-height: 435px;
    border: 1px solid $color-grey-light;
    box-shadow: 0 2px 10px rgba(46, 47, 66, 0.07);

    .parts--top--left {
      .icon {
        color: $color-text-light;
      }
    }
  }

  .reports-actions {
    width: 130px;
    min-width: 130px;

    .input-group {
      .input-group-fields {
        height: 28px;

        &:hover {
          input {
            color: $color-text;
            font-weight: 500;
          }
        }

        input {
          height: 26px;
          color: $color-text-light;
        }
      }

      .input-group-button {
        .button__calendar {
          height: 28px;
          padding: 6px 7px;
        }
      }
    }
  }

  .schedule {
    padding: 0;
    margin: 0 -1px;
  }

  .schedule--table {
    padding: 20px 0 0;
    margin-left: 65px;
    overflow: visible;

    .schedule--placeholder {
      position: absolute;
      left: 65px;
      top: 56px;
      width: calc(100% - 65px);
      height: calc(100% - 56px);

      .flex-el {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
        color: $color-text-light;

        p {
          padding: 0 0 5px;
        }

        .data-empty-icon {
          margin-bottom: -10px;
        }
      }
    }

    thead {
      .schedule-in {
        padding-left: 0;
        padding-right: 0;
        width: 35px;
        white-space: nowrap;
        margin: 0 0 0 -17px;

        @media (max-width: 1200px) {
          font-size: 10px;
          font-weight: 300;
          padding-top: 11px;
        }
      }

      .headcol {
        + td {
          opacity: 0;
        }
      }
    }

    .headcol {
      width: 65px;
      white-space: nowrap;

      &:after {
        display: none;
      }
    }

    .info {
      .headcol {
        &:after {
          display: block;
        }
      }
    }

    .schedule-in {
      width: auto;
      max-width: 100%;
    }
  }

  .schedule-item-line {
    border-top: none;
    border-bottom: none;
    background: rgba($color-link, 0.2);
    border-left-color: $color-link;
    border-right-color: $color-link;
    cursor: pointer;

    &:not(.read-only) {
      &:hover {
        background: rgba($color-link, 0.4);
      }
    }

    &.warning {
      background: rgba($color-orange, 0.2);
      border-left-color: $color-orange;
      border-right-color: $color-orange;

      &:not(.read-only) {
        &:hover {
          background: rgba($color-orange, 0.4);
        }
      }

      &:before,
      &:after {
        border-color: $color-yellow;
        color: $color-yellow;
      }
    }
  }

  .parts--top {
    padding: 0 15px;
  }

  .parts--top-title {
    font-size: 14px;
    font-weight: 500;

    span {
      font-weight: 400;
    }
  }

  .parts--top--left {
    .icon {
      margin: -1px 13px 0 0;
    }
  }
}

.calendar--top {
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  padding: 0 15px;
  margin: 0 0 15px;
  background: $color-white;
  border-bottom: 1px solid $color-grey-light;

  .filter--block {
    margin: 0 -8px;
  }
}

.calendar--name {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;

  &.simple {
    cursor: text;
  }

  &:hover {
    [class^="icon-"],
    [class*=" icon-"] {
      opacity: 1;
    }
  }

  [class^="icon-"],
  [class*=" icon-"] {
    color: $color-link;
    margin-left: 5px;
    cursor: pointer;
    font-size: 12px;
    opacity: 0;
  }

  [type="text"] {
    height: 28px;
    width: 250px;
    font-size: 13px;
    padding: 0 12px;
    margin: 0 0 0 -5px;

    &:focus {
      box-shadow: 0 0 3px 0 rgba($color-link, 0.7);
    }
  }

  form {
    > div {
      display: flex;
      align-items: center;
    }
  }

  .error-line {
    position: absolute;
    right: 5px;
    top: 27px;
    font-size: 7px;
    font-weight: 400;
  }

  .input-group {
    margin: 0;
  }

  .schedule-item--popup {
    top: -1px;

    [class^="icon-"],
    [class*=" icon-"] {
      font-size: 8px;
      opacity: 1;
    }

    .button {
      &:first-child {
        margin: 0;
      }
    }
  }
}

.calendar--bottom {
  padding: 0 15px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-text;
  font-weight: 500;
  font-size: 12px;
  height: 35px\9;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 35px;
  }

  .calendar--bottom--label {
    display: inline-block;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    color: $color-text-light;
    margin-right: 5px;
  }
}

.calendar--bottom--list {
  font-weight: 400;

  li {
    &:nth-child(odd) {
      background: #fafafa;
    }
  }

  a {
    color: $color-text;
    display: flex;
    padding: 4px 20px 5px;
    align-items: center;

    &:hover {
      color: $color-blue;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      color: $color-grey2 !important;
      margin-right: 5px;
      flex: 0 auto;
      align-items: center;
    }
  }

  .link--content {
    flex: 1;
  }
}
