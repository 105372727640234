.ai-app-details--list {
  margin: 20px 0;
  border-radius: 0.375rem;
  overflow: hidden;
}

.ai-app-details--item {
  display: flex;
  padding: 10px;
  font-weight: 500;
  font-size: $size-md;
  align-items: center;

  &:nth-child(odd) {
    background: #fafafa;
  }
}

.ai-app-details-cycle-detection--item {
  display: flex;
  padding: 10px;
  font-weight: 500;
  font-size: $size-md;
  align-items: center;
  border: 1px solid #e8e6e6;
  cursor: pointer;

  &:nth-child(odd) {
    background: #fafafa;
  }
  &:hover {
    background: #f0f0f0; /* Change the background color on hover */
  }
}

.ai-app-details--icon {
  width: 55px;
  flex: 0 auto;
  text-align: center;
  font-size: 1.5rem;
  color: #cecece;
  line-height: 55px;
}

.ai-app-details--text {
  flex: 1;
  display: flex;

  label {
    font-size: $size-sm;
    font-weight: 300;
    color: $color-text-light;
  }

  h3,
  .h3 {
    font-size: 17px;
    font-weight: 600;
    padding: 0;
  }

  p {
    padding: 0;
    min-height: 20px;
  }

  .ai-app-details--position {
    flex: 0 auto;
    width: 28%;

    &:first-child {
      width: auto;
      flex: 1;
    }

    &.w33 {
      width: 33%;
      flex: 0 auto;
    }

    &.w50 {
      width: 56%;
    }
  }
}

.table-wrapper {
  width: 100%;
  overflow: hidden;
  border-radius: 0.375rem;
  // border: 1px solid #e0e0e0;
}

.table-wrapper table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table-wrapper thead {
  position: sticky;
  top: 0;
  background-color: #f3f4f6;
}

.table-wrapper tbody {
  display: block;
}

.table-wrapper tbody.overflow-y {
  max-height: 155px;
  overflow-y: auto;
}

.table-wrapper tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-wrapper th,
.table-wrapper td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Scrollbar styling for WebKit browsers */
.table-wrapper tbody::-webkit-scrollbar {
  width: 6px;
}

.table-wrapper tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-wrapper tbody::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.table-wrapper tbody::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.add-anomaly-btn {
  float: right;
  position: relative;
  top: -10px;
}

.add-anomaly-btn > .fa {
  color: white;
}

.ai-app-label {
  font-size: $size-sm;
  padding: 0 2px 0 7px;
  margin: 0 15px 0 0;
  line-height: 22px;
  border-radius: 5px 0 0 5px;
  position: relative;
  min-width: 60px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    right: -9px;
    top: 2px;
    width: 18px;
    height: 18px;

    @include rotate(45deg);

    z-index: 1;
    border-radius: 5px;
  }

  &.failed {
    background: $color-red;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-red, 0.5);

    &:before {
      background: $color-red;
      box-shadow: 0 0 6px 0 rgba($color-red, 0.5);
    }

    span {
      background: $color-red;
    }
  }

  &.created {
    background: $color-label;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-label, 0.5);

    &:before {
      background: $color-label;
      box-shadow: 0 0 6px 0 rgba($color-label, 0.5);
    }

    span {
      background: $color-label;
    }
  }

  &.completed {
    background: $color-green;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-green, 0.5);

    &:before {
      background: $color-green;
      box-shadow: 0 0 6px 0 rgba($color-green, 0.5);
    }

    span {
      background: $color-green;
    }
  }

  &.running {
    background: $color-orange;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);

    &:before {
      background: $color-orange;
      box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);
    }

    span {
      background: $color-orange;
    }
  }
}
.ai-app-table > tbody > tr > td {
  max-width: 150px;
}

.ai-app-table > tbody > tr > td.td-category {
  width: 150px;
}

.device-list {
  display: inline-block;
  padding: 5px 15px;
  background-color: #f4f8f8;
  border: 1px solid #f0f5f5;
  border-radius: 2px;
  margin: 5px 5px 0 0;
}

.button.green.button__dropdown > .fa.fa-plus {
  color: #fff;
}

.type-items-container {
  height: 261px;
  overflow: auto;

  .type-item {
    cursor: pointer;
    border-bottom: 1px solid #e8e6e6;
    padding: 10px;

    &:hover {
      background: #f6f5f5;
    }

    &.selected {
      background: #def7f8;

      &:hover {
        background: #e9f7f8;
      }
    }

    .type-item-content {
      .row {
        display: flex;
        align-items: center;
        width: 100%;

        .label {
          width: 80px;
          text-align: left;
          font-weight: bold;
          background: transparent;
          color: #747474;
        }
        .value {
          flex: 1;
          min-width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.selected-item-batch {
  margin-top: 20px;
  padding: 12px;
  background-color: #f0f0f0;
  font-size: 13px;
  border-radius: 3px;

  .selected-item-info {
    flex-grow: 1;
  }

  .selected-item-delete {
    cursor: pointer;
    font-size: 18px;
    color: rgb(228, 128, 128);
  }
}

.td-edit-fixed-action {
  width: 70px;
}

.ai-app-no-pagination-display-table .status {
  color: $color-white;
  padding: 0.2em 1.2em;
  border-radius: 3px;
  display: table;
  font-size: 0.75rem;
  box-shadow: 0 0 3px 0 rgba($color-label, 0.5);
  vertical-align: center;
  width: 100px;
  text-align: center;
  margin-left: 5px;
}

.ai-app-no-pagination-display-table .status.created {
  background-color: $color-label;
}

.ai-app-no-pagination-display-table .status.running {
  background-color: $color-orange;
}

.ai-app-no-pagination-display-table .status.completed {
  background-color: $color-green;
}

.ai-app-no-pagination-display-table .status.failed {
  background-color: $color-red;
}

// SCSS Mixin for line clamping with vendor prefixes
@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-clamp {
  @include line-clamp(3);
}

.anomaly--details {
  .ai-app-details--position {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px 0;
  }

  .detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #f6f6f6;
  }

  .detail-label {
    flex: 0 0 200px;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    color: #8f9296;
  }

  .detail-value {
    flex: 1;
    font-weight: 400;
    min-height: 20px;
    font-family: 'Poppins', sans-serif;
    color: #3a3b3d;
  }

  .center-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.accordion-content.active {
  display: block;
}

.accordion-details {
  height: 290px;
  overflow: auto;
}

.comment-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  background-color: #e2e2e3;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
}
