/* Fonts  */
$Poppins: 'Poppins', sans-serif;
$Roboto: 'Roboto', sans-serif;
$mainfont: $Poppins;
$secondaryfont: $Roboto;

/* Colors  */
$color-blue: #37a8dd;
$color-red: #f34343;
$color-green: #7cc022;
$color-green-hover: #6da91d;
$color-green2: #e4f3e4;
$color-yellow: #f8c024;
$color-orange: #f59526;
$color-white: #fff;
$color-white-dark: #fafafa;
$color-black: #000;
$color-link: #00acb6;
$color-link-hover: #00bbc6;
$color-link-light: #a9e6e9;
$color-link-light2: #cdf4f6;
$color-grey: #364247;
$color-grey2: #cacdd2;
$color-grey3: #d4d7dc;
$color-grey4: #8f9296;
$color-grey-light: #e8ebef;
$color-grey-light2: #eee;
$color-grey-light3: #eff1f5;
$color-grey-button: #cecece;
$color-grey-button-hover: #d9d9d9;
$color-header-bk: #293439;
$color-header-icon: #697174;
$color-header-active: #434e54;
$color-header-active-icon: $color-link;
$color-background: #e8ebef;
$color-left-aside: $color-grey;
$color-right-aside: #e1e4e9;
$color-right-popup: #f1f1f1;
$color-text: #3a3b3d;
$color-text-light: $color-grey4;

/* special colors  */
$color-oee: $color-link;
$color-label: $color-link;

/* special styles  */
$logo-sm-min-width: 60px;
