.pagination {
  li {
    margin: 0 0 0 -1px;
    border: 1px solid $color-grey-light;
    background: $white;
    font-size: 12px;
    min-width: 36px;
    color: #9b9b9b;
    font-weight: 500;

    &:first-child {
      margin: 0;
      border-radius: 4px 0 0 4px;

      a,
      span,
      button {
        border-radius: 4px 0 0 4px;
      }
    }

    &:last-child {
      border-radius: 0 4px 4px 0;

      a,
      span,
      button {
        border-radius: 0 4px 4px 0;
      }
    }

    a,
    span,
    button {
      min-width: 36px;
      padding-top: 8px;
      padding-bottom: 8px;
      color: #9b9b9b;
      text-decoration: none !important;

      &:hover {
        color: $color-link;
        background: transparent;
      }
    }

    span {
      display: block;
      border-radius: 0;

      &.ellipsis {
        &:after {
          color: $color-grey2;
        }
      }
    }

    &.current {
      color: $color-text;
      background: $color-white-dark;
      padding-top: 8px;
      padding-bottom: 8px;
      border-color: $color-link;
      box-shadow: inset 0 0 4px 0 rgba($color-link, 0.5);
      z-index: 2;
      position: relative;
    }

    &.disabled {
      padding-top: 8px;
      padding-bottom: 8px;
      color: $color-grey2;

      &:hover,
      &:active {
        background: #fafafa;
        color: $color-grey2;
      }

      &.current {
        color: $color-text;
      }
    }
  }

  .table + &,
  .lined--table + & {
    margin: 20px 0 0;
  }
}

.pagination-previous {
  a,
  &.disabled {
    &:before {
      content: "<";
      margin: 0;
      font-size: 14px;
      line-height: 16px;
      padding-top: 2px;
      vertical-align: top;
    }
  }
}

.pagination-next {
  a,
  &.disabled {
    &:after {
      content: ">";
      margin: 0;
      font-size: 14px;
      line-height: 16px;
      padding-top: 2px;
      vertical-align: top;
    }
  }
}
