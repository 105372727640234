.documents--item {
  display: flex;
  border-bottom: 1px solid $color-right-aside;
  padding: 15px 0 25px;
  margin: 0 0 10px;
  font-size: $size-md;

  &:last-child {
    border-bottom: none;
  }
}

.documents--icon {
  flex: 0 auto;
  width: 78px;
  margin: 0 10px 0 0;
  position: relative;

  &:hover {
    .documents--main--icon {
      opacity: 0.12;
    }

    .documents-actions {
      display: block;
    }
  }
}

.documents--text {
  h4,
  .h4 {
    font-size: $size-md;
    font-weight: 600;
    padding: 0 0 5px;
  }

  p {
    padding: 0 0 8px;
  }
}

.documents--info {
  flex: 1;
}

.documents--socials {
  color: $color-grey2;
  font-size: $size-sm;

  ul {
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    vertical-align: top;
    padding: 0 40px 0 0;
    min-width: 110px;

    &.documents--socials__big {
      min-width: 150px;
    }
  }

  .icon {
    font-size: 11px;
    margin: 0 5px 0 0;
  }
}

.documents--main--icon {
  text-align: center;
}

.documents-actions {
  position: absolute;
  left: 0;
  right: 0;
  top: 6px;
  display: none;
  text-align: center;

  a {
    border-radius: 50%;
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: $size-sm;
    background: #eee;
    border: 2px solid $color-white;
  }
}
