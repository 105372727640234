/* Fonts Sizes  */
$size-sm: 12px;
$size-md: 13px;
$size: 14px;
$size-lg: 16px;
$size-xxl: 24px;
$size-input: 14px;
$size-header: 14px;

/* Mixins  */
@mixin linearGradient($top, $bottom) {
  background: $top;
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $top),
    color-stop(100%, $bottom)
  );
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
  background: -o-linear-gradient(top, $top 0%, $bottom 100%);
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
  background: linear-gradient(to bottom, $top 0%, $bottom 100%);
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin column($n) {
  -webkit-column-count: $n;
  -moz-column-count: $n;
  column-count: $n;
}

@mixin column-gap($nx) {
  -webkit-column-gap: $nx;
  -moz-column-gap: $nx;
  column-gap: $nx;
}

@mixin rotate($deg) {
  -moz-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin scroller {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
