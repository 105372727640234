.users {
  background: $color-white;
  border-radius: 4px;
  padding: 0 20px;
  margin-bottom: 20px;

  .users-wrap {
    min-height: calc(100vh - 60px - 58px - 20px - 20px - 57px);
    position: relative;
  }

  .reports-empty {
    top: 45px;
  }
}

.user-table {
  margin: 0 -20px;
  padding: 5px 0 20px;

  table {
    thead,
    tbody,
    tfoot {
      td {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }

    tbody,
    tfoot {
      td {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    strong {
      font-weight: 500;
    }

    .td-actions {
      width: 1%;
      white-space: nowrap;

      .button {
        width: 24px;
        height: 24px;
        font-size: 10px;
        margin-right: 5px;
        padding: 5px 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.no-max-width {
  max-width: none;
}

.form--read-only {
  label,
  .like-label {
    [type="text"],
    [type="password"],
    [type="date"],
    [type="datetime"],
    [type="datetime-local"],
    [type="month"],
    [type="week"],
    [type="email"],
    [type="number"],
    [type="search"],
    [type="tel"],
    [type="time"],
    [type="url"],
    [type="color"],
    textarea,
    select {
      margin: 0;
      padding: 5px 0 0;
      font-weight: 500;
      border: none;
      height: auto;
      background: none;
    }

    .Select {
      margin: 0;
      font-size: 13px;
      height: auto;

      > .Select-control {
        background: none;
        height: auto;
        border: none;
      }

      .Select-value {
        line-height: 1.8;
        color: $color-text;
        margin: 0;
        padding: 5px 0 0;
        font-weight: 500;
      }

      .Select-input {
        height: auto;
      }
    }
  }

  .location-line-field {
    padding: 0;
    border: none;
    min-height: 0;
    cursor: default;
    margin-top: 0;
    margin-bottom: -10px;
    background: none;

    .breadcrumps-home {
      padding-left: 0 !important;
    }

    .breadcrumps-title {
      font-weight: 500;
      cursor: default;

      .icon-location {
        color: $color-text-light !important;
      }
    }
  }

  .input-group {
    margin: 0;
  }
}

.input-result {
  font-weight: 500;
  padding-top: 5px;
  margin: 0 !important;

  &.grey {
    color: $color-background;
  }

  .list {
    line-height: 1.6;
    max-height: 150px;
    overflow: auto;
    margin-top: 3px;
    padding-left: 18px;

    @include scroller;

    li {
      list-style: disc;
    }
  }

  .location-line-field {
    padding: 0;
    border: none;
    min-height: 0;
    cursor: default;
    margin-top: -5px;
    margin-bottom: -10px;

    &:hover {
      background: $color-white;
    }

    .breadcrumps-home {
      padding-left: 0 !important;
    }

    .breadcrumps-title {
      font-weight: 500;

      .icon-location {
        color: $color-text-light !important;
      }
    }
  }
}

.sub-text {
  font-weight: 300;
  font-size: 12px;
}

.checkbox-list {
  line-height: 1.6;
  max-height: 150px;
  overflow: auto;
  margin-top: 8px;
  padding-right: 5px;

  @include scroller;

  li {
    padding-bottom: 5px;
  }

  .checkbox {
    min-height: 20px;
    height: auto;
    padding: 0;

    > span {
      padding-left: 20px;
      color: $color-text-light;
      font-weight: 500;

      &:before,
      &:after {
        top: 3px;
      }
    }

    input {
      &:checked {
        + span {
          color: $color-text;
        }
      }
    }
  }
}

.tab-row {
  margin: 0 -20px;
  display: flex;

  .tab-col {
    padding: 0 20px;
    flex: 1;
  }
}
