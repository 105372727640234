.scroll {
  padding: 20px 0 !important;
  //margin: 0 -20px 0 0;
  //height: calc(100vh - 60px);

  .subheader + & {
    //height: calc(100vh - 60px - 56px);
  }

  .jspPane {
    padding-right: 20px !important;
  }
}

.jspScrollable {
  padding: 0 !important;

  .jspPane {
    padding: 20px 0 !important;
  }
}

.jspTrack,
.jspVerticalBar {
  background: transparent;
}

.jspDrag {
  background: transparent;

  &:after {
    content: "";
    top: 2px;
    bottom: 2px;
    left: 10px;
    right: 2px;
    position: absolute;
    border-radius: 3px;
    background: $color-text-light;
  }
}
