.chartBox {
  width: inherit;
  height: inherit;
}

.pointer {
  cursor: pointer;
}

.image-render {
  overflow: hidden;
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;

  &.sm {
    margin: 0 auto;
    height: 200px;
    width: 200px;
  }
}

.card--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.label-num {
  margin: 0 0 0 8px;
  background: $color-text-light;
  color: $color-white;
  border-radius: 4px;
  line-height: 16px;
  min-width: 15px;
  text-align: center;
  padding: 0 5px;
  font-size: 10px;
  height: 15px;
  font-weight: 400;
}
