.white-group {
  display: flex;

  a {
    @extend .button;
    @extend .white;

    border: 1px solid #d4d7dc !important;
    margin-left: -1px;
    line-height: 15px;
    font-weight: 500;
    color: $color-text-light;
    background: #fafafa;

    &:hover {
      background: $color-grey-light;
    }

    &:after {
      display: none;
    }

    &:first-child {
      margin: 0;
    }

    &.active {
      background: $color-white !important;
      color: $color-link !important;
      cursor: default;
    }
  }

  .input-group-button {
    width: auto;
  }
}

.hamburger {
  width: 10px;
  height: 2px;
  margin: 4px 0;
  position: relative;
  display: inline-block;
  background: $color-text-light;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -4px;
    height: 2px;
    background: $color-text-light;
  }

  &:after {
    top: auto;
    bottom: -4px;
  }
}

.schedule--buttons {
  display: flex;
  align-items: center;

  .schedule--table + & {
    padding: 20px 0;
  }

  label {
    display: flex;
    align-items: center;
    color: $color-text-light;
    padding-bottom: 5px;

    .history-square {
      margin: 0 5px 3px;
    }
  }

  .button-group {
    flex: 0 auto;
    margin-right: 20px;

    &:last-child {
      margin: 0;
    }

    a {
      color: $color-text;
      background: $color-white;
      flex: 0 auto;
      height: 42px;
      display: flex;
      align-items: center;

      &:hover {
        background: #fafafa;
      }
    }

    .icon-watch {
      font-size: 10px;
      color: $color-text-light;
    }

    .texted {
      padding-right: 15px;
      padding-left: 15px;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .texted-in {
      flex: 1;
      text-align: left;
    }

    .hamburger {
      flex: 0 auto;
      margin-left: 5px;
    }

    .passive {
      .icon-watch {
        position: relative;

        &:after {
          content: "";
          width: 19px;
          height: 2px;
          position: absolute;
          left: -2px;
          top: 4px;
          background: $color-red;

          @include rotate(-45deg);
        }
      }
    }
  }
}

.schedule--buttons--col {
  width: 50%;
  padding: 0 22px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
