.search {
  margin: 17px 0;
  float: right;
  max-width: 230px;
  border-collapse: collapse;
  line-height: 0;

  .input-group {
    margin: 0;
  }

  .input-group-field {
    height: 26px;
    padding: 0 5px;
    width: 100%;
    color: $color-white;
    background: none;
    border: none;
    outline: none !important;

    @include placeholder {
      color: $color-white;
    }
  }

  .button__search {
    @extend .button;

    border: none;
    padding: 0;
    height: 26px;
    width: 26px;
    margin: 0 0 0 -1px;
    position: relative;
    background: none !important;
    color: $color-header-icon;

    &:hover {
      color: $color-white;
    }

    .icon {
      font-size: $size-lg;
    }
  }
}

.search-field-style {
  position: relative;

  .input-group-button {
    width: 0;
    display: none !important;
  }

  .input-group {
    > div {
      &:first-child {
        flex: 1;
      }
    }
  }

  .button__search {
    background: transparent;
    border-radius: 4px;
    box-shadow: none;
    font-size: $size-sm;
    font-weight: 600;
    height: 42px;
    color: $color-grey2;
    margin: 0;
    border-left: none;
    -webkit-transition: all 0s ease-out;
    -moz-transition: all 0s ease-out;
    transition: all 0s ease-out;
    padding: 8px 15px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;

    &:hover {
      color: $color-link;
    }
  }

  [type="text"],
  [type="password"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="month"],
  [type="week"],
  [type="email"],
  [type="number"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="url"],
  [type="color"] {
    background: $color-white;
    border: 1px solid #d4d7dc !important;
    box-shadow: none;
    font-size: $size-sm;
    font-weight: 400;
    padding: 8px 15px 8px 8px !important;
    padding-left: 0;
    height: 42px;
    margin: 0 !important;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;

    &:focus {
      border-color: $color-link !important;
    }
  }

  .Select__input {
    padding: 0;
    height: 26px;

    > input {
      height: 26px !important;
      padding: 0 15px !important;
      margin: 0 !important;
      border: none !important;
    }
  }
}
