@font-face {
  font-family: "datadash";
  src: url("#{$font-path}datadash/datadash.eot");
  src:
    url("#{$font-path}datadash/datadash.eot#iefix") format("embedded-opentype"),
    url("#{$font-path}datadash/datadash.ttf") format("truetype"),
    url("#{$font-path}datadash/datadash.woff") format("woff"),
    url("#{$font-path}datadash/datadash.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "datadash" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-experiment:before {
  content: "\e972";
}

.icon-merge:before {
  content: "\e973";
}

.icon-archive1:before {
  content: "\e96e";
}

.icon-assign:before {
  content: "\e96f";
}

.icon-hide:before {
  content: "\e970";
}

.icon-publish:before {
  content: "\e971";
}

.icon-number:before {
  content: "\e96d";
}

.icon-counterclockwise:before {
  content: "\e96b";
}

.icon-clockwise:before {
  content: "\e96c";
}

.icon-tool:before {
  content: "\e96a";
}

.icon-eye:before {
  content: "\e969";
}

.icon-admin:before {
  content: "\e968";
}

.icon-chart:before {
  content: "\e967";
}

.icon-duplicate:before {
  content: "\e965";
}

.icon-interval:before {
  content: "\e966";
}

.icon-photo:before {
  content: "\e964";
}

.icon-linked:before {
  content: "\e963";
}

.icon-copy_report2:before {
  content: "\e961";
}

.icon-reset:before {
  content: "\e95b";
}

.icon-totals:before {
  content: "\e95d";
}

.icon-remove:before {
  content: "\e95a";
}

.icon-save:before {
  content: "\e95c";
}

.icon-inventory:before {
  content: "\e957";
}

.icon-warning-triangle:before {
  content: "\e948";
}

.icon-suitcase:before {
  content: "\e949";
}

.icon-shifts-on:before {
  content: "\e94a";
}

.icon-server:before {
  content: "\e94b";
}

.icon-products-inactive:before {
  content: "\e94c";
}

.icon-products-barcode:before {
  content: "\e94d";
}

.icon-prioritized:before {
  content: "\e94e";
}

.icon-plus-round:before {
  content: "\e94f";
}

.icon-performance:before {
  content: "\e950";
}

.icon-performance5:before {
  content: "\e951";
}

.icon-none:before {
  content: "\e952";
}

.icon-loading:before {
  content: "\e953";
}

.icon-inbox2:before {
  content: "\e954";
}

.icon-inbox:before {
  content: "\e955";
}

.icon-id-card:before {
  content: "\e956";
}

.icon-download:before {
  content: "\e947";
}

.icon-compare2:before {
  content: "\e946";
}

.icon-comment-line:before {
  content: "\e945";
}

.icon-close-round:before {
  content: "\e944";
}

.icon-close2:before {
  content: "\e943";
}

.icon-arrow-left:before {
  content: "\e941";
}

.icon-arrow-right:before {
  content: "\e942";
}

.icon-watch-inactive:before {
  content: "\e940";
}

.icon-warning:before {
  content: "\e93e";
}

.icon-tag:before {
  content: "\e93f";
}

.icon-slide-left:before {
  content: "\e936";
}

.icon-slide-right:before {
  content: "\e938";
}

.icon-slide-down:before {
  content: "\e958";
}

.icon-slide-up:before {
  content: "\e959";
}

.icon-scheduled:before {
  content: "\e934";
}

.icon-reload-clockwise:before {
  content: "\e935";
}

.icon-phone:before {
  content: "\e933";
}

.icon-mail:before {
  content: "\e939";
}

.icon-cog:before {
  content: "\e93a";
}

.icon-logout:before {
  content: "\e93b";
}

.icon-location:before {
  content: "\e93c";
}

.icon-list:before {
  content: "\e93d";
}

.icon-filter-funnel:before {
  content: "\e937";
}

.icon-edit-card:before {
  content: "\e930";
}

.icon-caret-down:before {
  content: "\e932";
}

.icon-caret-up:before {
  content: "\e95e";
}

.icon-caret-left:before {
  content: "\e95f";
}

.icon-caret-right:before {
  content: "\e960";
}

.icon-datasource:before {
  content: "\e92e";
}

.icon-copy-report:before {
  content: "\e91d";
}

.icon-compare4:before {
  content: "\e91c";
}

.icon-compare3:before {
  content: "\e90c";
}

.icon-cards:before {
  content: "\e909";
}

.icon-window-expand:before {
  content: "\e926";
}

.icon-window-collapse:before {
  content: "\e928";
}

.icon-window-horizontal:before {
  content: "\e929";
}

.icon-move:before {
  content: "\e92a";
}

.icon-window-full:before {
  content: "\e92b";
}

.icon-window-cut:before {
  content: "\e92c";
}

.icon-window-cut-bottom:before {
  content: "\e92d";
}

.icon-performance4:before {
  content: "\e92f";
}

.icon-performance3:before {
  content: "\e931";
}

.icon-checkmark:before {
  content: "\e924";
}

.icon-checkmark-circle:before {
  content: "\e925";
}

.icon-checkmark-big:before {
  content: "\e927";
}

.icon-calendar-empty:before {
  content: "\e923";
}

.icon-time-filled:before {
  content: "\e922";
}

.icon-locomotive:before {
  content: "\e921";
}

.icon-compare:before {
  content: "\e920";
}

.icon-home:before {
  content: "\e91f";
}

.icon-tachometer:before {
  content: "\e91e";
}

.icon-products:before {
  content: "\e91b";
}

.icon-clone:before {
  content: "\e91a";
}

.icon-return:before {
  content: "\e917";
}

.icon-close:before {
  content: "\e918";
}

.icon-archive:before {
  content: "\e919";
}

.icon-edit:before {
  content: "\e916";
}

.icon-screwdriver:before {
  content: "\e914";
}

.icon-wrench:before {
  content: "\e913";
}

.icon-info:before {
  content: "\e90f";
}

.icon-id:before {
  content: "\e910";
}

.icon-notes:before {
  content: "\e911";
}

.icon-cogs:before {
  content: "\e912";
}

.icon-load:before {
  content: "\e90e";
}

.icon-time:before {
  content: "\e90b";
}

.icon-calendar:before {
  content: "\e90d";
}

.icon-location:before {
  content: "\e915";
}

.icon-person:before {
  content: "\e900";
}

.icon-filter:before {
  content: "\e901";
}

.icon-comment:before {
  content: "\e902";
}

.icon-attached:before {
  content: "\e903";
}

.icon-plus:before {
  content: "\e904";
}

.icon-watch:before {
  content: "\e905";
}

.icon-clipboard:before {
  content: "\e91d";
  color: $color-link;
}
.icon-clipboard:hover {
  color: $color-link-hover;
  cursor: pointer;
}

.icon-search:before {
  content: "\e906";
}

.icon-reports:before {
  content: "\e907";
}

.icon-maintenance:before {
  content: "\e908";
}

.icon-dashboard:before {
  content: "\e90a";
}
