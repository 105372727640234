.dashboard--event-transition {
  margin: 0 -5px;
  padding: 0 5px;

  &.transition-enter {
    max-height: 0;
  }

  &.transition-enter-active {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 0.8s ease-in;
  }

  &.transition-leave {
    max-height: 1000px;
  }

  &.transition-leave-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease-in-out;
  }

  &:last-child .position {
    margin: 0;
  }
}
