.button {
  height: 36px;
  font-size: $size-sm;
  color: $color-white;
  background: $color-blue;
  border-radius: 5px;
  font-weight: 600;
  margin: 0;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $color-white;
    background: darken($color-blue, 10%);
  }

  &.disabled,
  &[disabled] {
    opacity: 0.5;

    &:hover,
    &:active,
    &:focus {
      color: $color-white;
      background: $color-blue;
      border-color: $color-blue;
    }
  }

  &.green {
    background: $color-green !important;

    &:hover {
      color: $color-white;
      background: $color-green-hover !important;
      border-color: $color-green-hover !important;
    }

    &:active,
    &:focus {
      color: $color-white;
      background: $color-green-hover !important;
      border-color: darken($color-green-hover, 5%) !important;
      box-shadow: inset 0 1px 2px rgba($color-black, 0.31);
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-green;
        color: $color-white;
        background: $color-green;
        box-shadow: none;
      }
    }
  }

  &.blue {
    background: $color-blue;

    &:hover {
      color: $color-white;
      background: darken($color-blue, 5%);
      border-color: darken($color-blue, 5%);
    }

    &:active,
    &:focus {
      color: $color-white;
      background: $color-blue;
      border-color: darken($color-blue, 3%);
      box-shadow: inset 0 1px 2px rgba($color-black, 0.21);
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-blue;
        color: $color-white;
        background: $color-blue;
        box-shadow: none;
      }
    }
  }

  &.link {
    background: $color-link;

    &:hover {
      color: $color-white;
      background: $color-link-hover;
      border-color: $color-link-hover;
    }

    &:active,
    &:focus {
      color: $color-white;
      background: $color-link;
      border-color: darken($color-link, 3%);
      box-shadow: inset 0 1px 2px rgba($color-black, 0.21);
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-link;
        color: $color-white;
        background: $color-link;
        box-shadow: none;
      }
    }
  }

  &.grey {
    background: $color-grey-button;
    color: $color-text;

    &:hover {
      color: $color-text;
      background: $color-grey-button-hover;
      border-color: $color-grey-button-hover;
    }

    &:active,
    &:focus {
      color: $color-text;
      box-shadow: inset 0 1px 2px rgba($color-black, 0.31);
      background: $color-grey-button;
      border-color: darken($color-grey-button, 5%);
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-grey-button;
        color: $color-text;
        background: $color-grey-button;
        box-shadow: none;
      }
    }
  }

  &.light-grey {
    background: $color-grey-light;
    color: $color-text;

    &:hover,
    &:active,
    &:focus {
      color: $color-text;
      background: darken($color-grey-light, 10%);
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-grey-light;
        color: $color-text;
        background: $color-grey-light;
        box-shadow: none;
      }
    }
  }

  &.light-grey-white {
    background: $color-white;
    color: $color-grey4;
    border: 1px solid $color-grey3;

    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $color-text;
      background: darken($color-white, 5%);

      .fa,
      .icon {
        color: $color-grey4;
      }
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-grey3;
        color: $color-text;
        background: darken($color-white, 10%);
        box-shadow: none;
      }
    }

    .fa,
    .icon {
      color: $color-grey4;
    }
  }

  &.light-green-white {
    background: $color-green2;
    color: $color-green;
    border: 1px solid $color-green;

    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $color-text;
      background: darken($color-white, 5%);

      .fa,
      .icon {
        color: $color-green;
      }
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-grey3;
        color: $color-text;
        background: darken($color-white, 10%);
        box-shadow: none;
      }
    }

    .fa,
    .icon {
      color: $color-green;
    }
  }

  &.light-grey-bordered {
    background: $color-grey-light2;
    color: $color-text;
    font-weight: 500;
    border-color: $color-grey3;

    &:hover {
      color: $color-text;
      background: $color-grey-light;
    }

    &:active,
    &:focus {
      box-shadow: inset 0 1px 2px 0 rgba($color-black, 0.2);
      color: $color-text;
      background: $color-grey-light;
    }

    .fa,
    .icon {
      color: $color-link;
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-grey3;
        color: $color-text;
        background: $color-grey-light2;
        box-shadow: none;
      }
    }
  }

  &.red {
    background: $color-red;
    color: $color-white;

    &:hover,
    &:active,
    &:focus {
      color: $color-white;
      background: darken($color-red, 10%);
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-red;
        color: $color-white;
        background: $color-red;
        box-shadow: none;
      }
    }
  }

  &.white {
    background: $color-white;
    color: $color-text;
    border: 1px solid $color-grey3;

    &:hover,
    &:active,
    &:focus {
      color: $color-text;
      background: darken($color-white, 10%);

      .fa,
      .icon {
        color: $color-text;
      }
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:active,
      &:focus {
        border-color: $color-grey3;
        color: $color-text;
        background: $color-white;
        box-shadow: none;
      }
    }

    .fa,
    .icon {
      color: $color-link;
    }
  }

  &.transparent {
    background: none;
    color: $color-link;

    &:hover,
    &:active,
    &:focus {
      background: none;
      color: darken($color-link, 10%);
    }
  }

  &.ghost {
    border: 1px solid $color-background;
    border-radius: 2px;
    background: transparent;
    color: $color-link;
    font-size: $size-sm;
    padding: 0 10px;
    line-height: 22px;
    height: 22px;
    text-decoration: none;
    font-weight: 300;

    &:hover {
      background: $color-grey-light;
    }
  }

  &.button__sm {
    min-width: 88px;
    height: 36px;
    line-height: 1;
    font-weight: 400;
    margin: 0 17px;
    font-size: $size-sm;

    &.m0 {
      margin: 0;
    }
  }

  &.button__md {
    min-width: 112px;
    height: 44px;
    line-height: 22px;
    font-weight: 300;
    margin: 0 8px;
    font-size: $size-md;
  }

  &.button__xs {
    min-width: 98px;
    height: 26px;
    line-height: 1;
    font-weight: 400;
    margin: 0;
    font-size: $size-sm;
    padding: 0 8px;
  }

  &.button-block {
    width: 100%;
    display: block;
  }

  .fa,
  .icon {
    margin: 0 7px 0 0;
  }

  .fa-spinner {
    margin-top: -1px;
    vertical-align: top;
  }
}

.button__new-machine,
.button__dropdown-text,
.button__back {
  @extend .button;
  @extend .green;
}

.button__snooze {
  @extend .button;
  @extend .ghost;
}

.button__submit {
  @extend .button;
  @extend .link;

  white-space: nowrap;
  font-weight: 400;
}

.button--card {
  @extend .button;

  font-weight: 400;
  width: 80px;
  text-align: center;
}

.button__close,
.button__clone {
  @extend .button;
  @extend .transparent;

  height: auto;
  padding: 0;
  color: $color-text-light;

  &:hover,
  &:active,
  &:focus {
    color: $color-text;
  }

  .fa {
    margin: 0;
    font-size: 20px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    margin: 0;
    font-size: 18px;
  }
}

.button__clone {
  [class^='icon-'],
  [class*=' icon-'] {
    margin: 0;
    font-size: 15px;
  }
}

.button__filter {
  @extend .button;
  @extend .transparent;

  height: auto;
  padding: 0;
  color: $color-grey2 !important;

  &:hover,
  &:active,
  &:focus {
    color: $color-text !important;
  }

  .fa {
    margin: 0;
    font-size: 20px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    margin: 0;
    font-size: 14px;
  }
}

.button__cancel,
.button__remove__all {
  @extend .button;
  @extend .grey;
  @extend .button__md;
}

.button__save {
  @extend .button;
  @extend .green;
  @extend .button__md;

  &.flexed {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.button__remove {
  @extend .button;
  @extend .red;
  @extend .button__md;
}

.button__white {
  @extend .button;
  @extend .white;
  @extend .button__md;
}

.button__dropdown-arrow {
  @extend .button;
  @extend .green;

  .fa {
    vertical-align: top;
    margin: 0 !important;
  }
}

.button__calendar {
  @extend .button;
  @extend .transparent;

  margin: 8px 0;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  display: none;

  @media screen and (max-width: 64em) {
    display: none !important;
    display: inline-block;
  }
}

.button__add-location {
  @extend .button;
  @extend .green;

  [class^='icon-'],
  [class*=' icon-'] {
    vertical-align: top;
    margin: 0 5px 0 0;
    color: lighten($color-green, 30%);
  }
}

.button__positions-more {
  @extend .button;
  @extend .button__md;
  @extend .button-block;

  margin: 0 0 20px;
  background: $color-white;
  border-color: $color-grey2;
  font-weight: 600;
  color: $color-blue;

  &:hover {
    color: $color-blue;
    border-color: $color-blue;
    background: $color-white;
    box-shadow: 0 1px 10px rgba($color-blue, 0.21);
  }

  &:active,
  &:focus {
    color: $color-white;
    border-color: $color-blue;
    background: $color-blue;
    box-shadow: 0 1px 10px rgba($color-blue, 0.21);
  }
}

.button__maintenance-more {
  @extend .button;
  @extend .button__md;
  @extend .button-block;

  background: $color-white;
  border-color: $color-right-aside;
  font-weight: 600;
  margin: 0;
  color: $color-blue;

  &:hover {
    color: $color-blue;
    border-color: $color-blue;
    background: $color-white;
    box-shadow: 0 1px 10px rgba($color-blue, 0.21);
  }

  &:active,
  &:focus {
    color: $color-blue;
    border-color: $color-right-aside;
    background: $color-white;
  }

  .fa {
    color: $color-grey2;
    margin: 0 8px 0 0;
    position: relative;
    top: 2px;
    font-size: 1rem;
    font-weight: 400;
  }
}
