.position {
  background: $color-white;
  border-radius: 2px;
  margin: 0 0 20px;
  box-shadow: 0 2px 10px 0 rgba(46, 47, 66, 0.07);
  cursor: pointer;

  h2,
  .h2 {
    font-size: $size;
    padding: 0 0 3px;
  }

  &.opened {
    .position--bottom {
      display: flex;
      cursor: default;
    }

    .position-pic {
      img {
        border-radius: 2px 0 0;
      }
    }
  }
}

.position--top {
  display: flex;
}

.position-pic--wrap {
  flex: 0 auto;
  width: 170px;
  height: 170px;
  overflow: hidden;

  img {
    border-radius: 2px 0 0 2px;
    width: 170px;
    height: 170px;
  }
}

.position-pic {
  width: 170px;
  height: 170px;
  display: block;
}

.position-text {
  flex: 1;
  padding: 0 0 40px;
  position: relative;
  background: $color-white;
}

.position-text--top {
  padding: 12px 20px 12px 30px;
  background: $color-white-dark;
  line-height: 1.5;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }
}

.links {
  color: $color-blue;
  font-weight: 500;

  &:hover {
    color: $color-blue;
    text-decoration: underline;

    .fa,
    [class^="icon-"],
    [class*=" icon-"] {
      color: $color-text-light;
    }
  }

  .fa,
  [class^="icon-"],
  [class*=" icon-"] {
    margin: 3px 5px 0 0;
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
    color: $color-grey2;

    &.last-child {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  .icon-location {
    font-size: 11px;
  }

  &.links-text {
    color: $color-text !important;
    text-decoration: none !important;

    .fa,
    [class^="icon-"],
    [class*=" icon-"] {
      color: $color-grey2 !important;
    }
  }

  &.links-light {
    color: #cecece;
    font-weight: 400;
  }

  &.links-big {
    font-size: $size;
  }

  .mt0 {
    margin-top: 0;
  }
}

.position-machines {
  margin-right: 25px;
}

.position--label {
  padding: 0 15px 0 0;
  flex: 0 auto;
}

.position-title {
  flex: 1;

  .fa,
  .icon {
    margin: 3px 5px 0 0;
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
    color: $color-grey2;

    &.last-child {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  .icon-location {
    font-size: 11px;
  }

  .link-text {
    &:hover,
    &:focus,
    &:active {
      color: $color-link;

      .fa,
      .icon {
        color: $color-grey2;
      }
    }
  }
}

.position-button {
  padding: 0 0 0 15px;
  flex: 0 auto;
  margin: 0 -6px 0 0;
}

.button__position-extend,
.button__toggle {
  @extend .button;
  @extend .transparent;

  color: $color-grey2;
  margin: 0;
  font-size: 14px;
  padding: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 0;

  &:hover,
  &:active,
  &:focus {
    color: $color-link;
  }

  .fa,
  .icon {
    margin: 0;
  }

  .fa-caret-down,
  .icon-caret-down {
    display: inline;
  }

  .opened & {
    color: $color-link;

    &:hover,
    &:active,
    &:focus {
      color: $color-link;
    }

    .fa-caret-left,
    .icon-caret-left {
      display: none;
    }

    .fa-caret-down,
    .icon-caret-down {
      display: inline-block;
    }
  }

  &.mr-10 {
    margin-right: 10px;
  }
}

.position-text--center {
  padding: 14px 20px 0 30px;
  color: $color-text-light;
  line-height: 1.6;

  p {
    margin: 0;
    padding: 0 0 5px;
  }
}

.position-text--bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 20px 14px 30px;
  line-height: 22px;
}

.inline-list {
  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 23px 0 0;
  }

  .button {
    margin: 0;
  }

  &.right {
    text-align: right;

    li {
      margin: 0 0 0 23px;
    }
  }

  .fa,
  [class^="icon-"],
  [class*=" icon-"] {
    margin: 2px 6px 0 0;
    display: inline-block;
    vertical-align: top;
    color: $color-grey2;
  }

  .icon-comment,
  .icon-locomotive {
    margin-top: 3px;
    color: $color-link;
  }

  .fa-caret-down,
  .fa-caret-up,
  .fa-caret-left,
  .icon-caret-down,
  .icon-caret-up,
  .icon-caret-left {
    margin: 0 0 0 3px;
  }

  .passive {
    color: $color-grey2;

    .fa,
    [class^="icon-"],
    [class*=" icon-"] {
      color: $color-grey2;
    }
  }

  .inline-list--date {
    min-width: 85px;
  }

  .inline-list--time {
    min-width: 75px;
  }

  .inline-list--comments {
    min-width: 50px;
  }

  .inline-list--alerts {
    min-width: 75px;
  }
}

.position-list {
  @include flex-grid-row();
  @include grid-row-nest(30px);
}

.position-list--left {
  @include flex-grid-column(8);
}

.position-list--right {
  @include flex-grid-column(4);

  text-align: right;

  .links {
    .fa {
      margin-top: 5px;
    }
  }
}

.position--bottom {
  display: none;
  border-top: 1px solid $color-background;

  .position-text {
    padding: 0;

    .graph-medium {
      padding: 13px 20px 20px 30px;
    }
  }

  .infos {
    font-size: $size-sm;

    .fa,
    .icon {
      position: relative;
      left: 0;
      top: 0;
      float: left;
      margin: 3px 8px 0 0;
    }
  }

  .infos--left {
    @include flex-grid-column(12, 20px);

    padding: 0 0 10px;
    position: relative;
    border: none;
    margin: 0 0 20px;

    .line {
      margin: 20px 0 15px;
      height: 1px;
      background-color: $color-right-aside;
    }

    .button {
      color: $color-text-light;
      font-weight: 500;

      &:hover,
      &:active,
      &:focus {
        color: $color-text;
      }

      [class^="icon-"],
      [class*=" icon-"] {
        margin-right: 10px;
        color: $color-text-light;
      }
    }
  }

  .infos--right {
    @include flex-grid-column(12, 20px);

    padding: 0 0 10px;
    position: relative;
  }
}

.position-recent {
  flex: 0 auto;
  width: 170px;
  padding: 20px 0 13px 20px;

  p {
    padding: 0;
  }

  h3,
  .h3 {
    font-size: $size-sm;
    font-weight: 600;
    padding-bottom: 10px;
  }

  li {
    padding: 0 0 10px;
  }
}

.position-recent--item {
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }
}

.track--list {
  padding: 0 0 0 20px;
}

.track--position {
  border-bottom: 1px solid $color-right-aside;
  padding: 10px 0 15px;

  &:last-child {
    border: none;
  }

  .position-text--top {
    padding: 0 0 10px;
    background: none;
  }

  .position--label {
    padding-right: 30px;
  }

  .track--text {
    font-weight: 500;
  }
}
