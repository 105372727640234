@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
  src: url("#{$font-path}/Poppins-300/Poppins-300.eot");
  src:
    url("#{$font-path}/Poppins-300/Poppins-300.eot?#iefix")
      format("embedded-opentype"),
    local("Poppins Light"),
    local("Poppins-300"),
    url("#{$font-path}/Poppins-300/Poppins-300.woff2") format("woff2"),
    url("#{$font-path}/Poppins-300/Poppins-300.woff") format("woff"),
    url("#{$font-path}/Poppins-300/Poppins-300.ttf") format("truetype"),
    url("#{$font-path}/Poppins-300/Poppins-300.svg#Poppins") format("svg");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: url("#{$font-path}/Poppins-regular/Poppins-regular.eot");
  src:
    url("#{$font-path}/Poppins-regular/Poppins-regular.eot?#iefix")
      format("embedded-opentype"),
    local("Poppins"),
    local("Poppins-regular"),
    url("#{$font-path}/Poppins-regular/Poppins-regular.woff2") format("woff2"),
    url("#{$font-path}/Poppins-regular/Poppins-regular.woff") format("woff"),
    url("#{$font-path}/Poppins-regular/Poppins-regular.ttf") format("truetype"),
    url("#{$font-path}/Poppins-regular/Poppins-regular.svg#Poppins")
      format("svg");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: url("#{$font-path}/Poppins-500/Poppins-500.eot");
  src:
    url("#{$font-path}/Poppins-500/Poppins-500.eot?#iefix")
      format("embedded-opentype"),
    local("Poppins Medium"),
    local("Poppins-500"),
    url("#{$font-path}/Poppins-500/Poppins-500.woff2") format("woff2"),
    url("#{$font-path}/Poppins-500/Poppins-500.woff") format("woff"),
    url("#{$font-path}/Poppins-500/Poppins-500.ttf") format("truetype"),
    url("#{$font-path}/Poppins-500/Poppins-500.svg#Poppins") format("svg");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url("#{$font-path}/Poppins-600/Poppins-600.eot");
  src:
    url("#{$font-path}/Poppins-600/Poppins-600.eot?#iefix")
      format("embedded-opentype"),
    local("Poppins SemiBold"),
    local("Poppins-600"),
    url("#{$font-path}/Poppins-600/Poppins-600.woff2") format("woff2"),
    url("#{$font-path}/Poppins-600/Poppins-600.woff") format("woff"),
    url("#{$font-path}/Poppins-600/Poppins-600.ttf") format("truetype"),
    url("#{$font-path}/Poppins-600/Poppins-600.svg#Poppins") format("svg");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: url("#{$font-path}/Poppins-700/Poppins-700.eot");
  src:
    url("#{$font-path}/Poppins-700/Poppins-700.eot?#iefix")
      format("embedded-opentype"),
    local("Poppins Bold"),
    local("Poppins-700"),
    url("#{$font-path}/Poppins-700/Poppins-700.woff2") format("woff2"),
    url("#{$font-path}/Poppins-700/Poppins-700.woff") format("woff"),
    url("#{$font-path}/Poppins-700/Poppins-700.ttf") format("truetype"),
    url("#{$font-path}/Poppins-700/Poppins-700.svg#Poppins") format("svg");
}
