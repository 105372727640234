.history {
  color: $color-text-light;
  font-size: 11px;
  padding: 0 0 15px;

  li {
    vertical-align: top;
    display: inline-block;
    margin: 0 15px 0 0;
  }
}

.history--row {
  display: flex;
}

.history--left {
  flex: 1;
}

.history--right {
  flex: 0 auto;
  white-space: nowrap;

  .fa,
  .icon {
    color: $color-link;
  }
}

.history-square {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 2px 5px 0 0;
  vertical-align: top;
  border-radius: 2px;

  &.success {
    background: $color-green;
  }

  &.alert {
    background: $color-red;
  }

  &.link {
    background: $color-link;
  }

  &.warning {
    background: $color-orange;
  }
}

.calendar--item {
  min-height: 146px;
  background: $color-white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  display: flex;
  margin: 0 0 20px;
}

.calendar--item--left {
  flex: 0 auto;
  width: 150px;
  border-right: 1px solid $color-right-aside;
  padding: 23px 20px 50px 21px;
  color: $color-text-light;
  position: relative;
}

.week-name {
  display: block;
  padding: 0 0 3px;
  color: $color-text;
}

.calendar-users {
  position: absolute;
  left: 21px;
  bottom: 19px;
  white-space: nowrap;

  .fa,
  .icon {
    color: $color-blue;
  }
}

.calendar--item--right {
  flex: 1;
  padding: 15px 20px;
  text-align: center;
}

.calendar--month {
  background: $color-white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  margin: 0 0 20px;
}

.calendar--month--top {
  padding: 20px 10px;
  text-align: center;

  .fa,
  .icon {
    font-size: $size-lg;
  }
}

.calendar--month--prev {
  float: left;
  width: 30px;
  height: 30px;
}

.calendar--month--next {
  float: right;
  width: 30px;
  height: 30px;
}

.calendar--month--title {
  text-align: center;
  font-size: $size-lg;
  font-weight: 600;
  margin: 0 40px;
}

.calendar--month--day--item {
  display: flex;
  line-height: 28px;
  font-size: $size-sm;
  border-top: 1px solid $color-background;

  &.passive {
    .calendar--month--day {
      color: $color-text-light;
    }

    .calendar--month--day--text {
      background: rgba(232, 235, 239, 0.25);
    }
  }

  &.success {
    .calendar--month--day {
      &:after {
        background: $color-green;
        box-shadow: 0 0 6px 0 rgba($color-green, 0.5);
      }
    }
  }

  &.alert {
    color: $color-text !important;

    .calendar--month--day {
      &:after {
        background: $color-red;
        box-shadow: 0 0 6px 0 rgba($color-red, 0.5);
      }
    }
  }

  &.error {
    .calendar--month--day {
      &:after {
        background: $color-orange;
        box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);
      }
    }
  }

  &.warning {
    .calendar--month--day {
      &:after {
        background: $color-yellow;
        box-shadow: 0 0 6px 0 rgba($color-yellow, 0.5);
      }
    }
  }

  &.info {
    .calendar--month--day {
      &:after {
        background: $color-blue;
        box-shadow: 0 0 6px 0 rgba($color-blue, 0.5);
      }
    }
  }

  &.anomaly {
    .calendar--month--day {
      &:after {
        background: #000;
        box-shadow: 0 0 6px 0 rgba(#000, 0.5);
      }
    }
  }
}

.calendar--month--day {
  flex: 0 auto;
  font-weight: 600;
  width: 80px;
  text-align: center;
  padding: 0 5px;
  position: relative;
  border-right: 1px solid $color-background;

  &:after {
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0;
    width: 2px;
    border-radius: 2px;
  }
}

.calendar--month--day--text {
  flex: 1;
  padding: 0 15px;

  a {
    margin: 0 0 0 5px;
  }
}

.datepicker-block {
  display: flex;
  align-items: center;
  position: relative;
}

.datepicker-position,
.report-interval {
  flex: 1;
  position: relative;
  background: $color-white;
  border-radius: 4px;

  .icon-calendar {
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 1;
    font-size: 14px;
    margin-top: -7px;
    color: $color-text-light;
  }

  .icon-interval {
    margin: -2px 3px 0 0;
  }

  input {
    background: none !important;
    z-index: 2;
    position: relative;
    height: 36px;
    padding: 5px 5px 5px 31px;
    -webkit-transition:
      box-shadow 0.5s,
      border-color 0.25s ease-in-out;
    transition:
      box-shadow 0.5s,
      border-color 0.25s ease-in-out;

    &:focus {
      border-color: $color-link;
      box-shadow: inset 0 0 2px $color-link;

      + .icon-calendar {
        color: $color-link;
      }
    }
  }

  .button-date {
    padding: 6px 10px 5px 31px;
    font-weight: 500;
    color: $color-text;
  }

  &.opened {
    background: #fafafa;

    input {
      border-color: $color-link;
      box-shadow: inset 0 0 2px $color-link;
    }

    .icon-calendar {
      color: $color-link;
    }

    + .datepicker-dropdowns {
      right: 0;

      &:before,
      &:after {
        right: 82px;
      }
    }
  }

  &.disabled {
    background-color: $color-grey-light;

    input {
      color: $color-text-light;
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.fields-sep {
  flex: 0 auto;
  padding: 0 6px;
}

.datepicker-styled {
  .datepicker-block {
    width: 100%;
  }

  .fields-sep {
    padding: 0 30px;
  }

  .datepicker-dropdowns {
    right: 0;
    width: 458px;
    padding: 10px 0 0;
    box-shadow: none;

    &:before,
    &:after {
      display: none;
      left: 15px;
      right: auto;
    }
  }

  .datepicker-position {
    &,
    &.opened {
      + .datepicker-dropdowns {
        right: 4px;

        &:before,
        &:after {
          left: auto;
          right: 15px;
        }
      }
    }
  }

  .datepicker-dropdowns--one {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .datepicker-dropdowns--top {
      width: 100%;
    }

    .react-datepicker {
      width: 190px;
    }
  }

  .modal-datepicker & {
    .datepicker-dropdowns--flex {
      flex-direction: column;
      padding: 0;
    }

    .datepicker-dropdowns--one {
      order: 1;
    }

    .datepicker--buttons {
      order: 2;
      width: auto;
      display: flex;
      margin: 20px -20px 0;
      padding: 10px 20px 0;
      border-top: none;

      .line {
        display: none;
      }

      .button {
        flex: 1;
        width: auto;
        margin: 0 0 0 15px;

        &:first-child {
          margin: 0;
        }
      }
    }
  }

  .tabs {
    margin: 0 -40px 15px -40px;
    padding: 0 40px;
    position: relative;
    border-bottom: 1px solid $color-grey-light;

    li {
      margin: 0 0 -1px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent transparent $color-grey-light;
      border-radius: 4px 4px 0 0;
      padding: 0;
      position: relative;

      a {
        padding: 0 20px;
        position: relative;

        &:hover {
          color: $color-text;
        }

        &:before {
          display: none;
        }
      }
    }

    .is-active {
      border-color: $color-grey-light $color-grey-light transparent;
      background: $color-white;

      &:before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        top: auto;
        left: -3px;
        bottom: -1px;
        background: $color-white;
      }

      &:after {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        top: auto;
        right: -3px;
        bottom: -1px;
        background: $color-white;
      }

      a {
        position: relative;

        &:before {
          display: block;
          z-index: 2;
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          top: auto;
          left: -10px;
          bottom: -1px;
          border-radius: 0 0 5px;
          border-width: 1px;
          border-style: solid;
          background: none;
          border-color: transparent #e2e5ea #e2e5ea transparent;
        }

        &:after {
          z-index: 2;
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          top: auto;
          right: -10px;
          bottom: -1px;
          border-radius: 0 0 0 5px;
          border-width: 1px;
          border-style: solid;
          border-color: transparent transparent #e2e5ea #e2e5ea;
        }
      }
    }
  }

  .datepicker-time {
    .sm {
      height: 26px;
      padding: 0 10px 0 31px;
      color: $color-text-light;
      cursor: pointer;
      min-width: 0;
    }
  }
}

.datepickers-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.datepicker-wrap {
  width: 190px;

  .react-datepicker {
    min-height: 206px;
  }
}

.datepicker-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  input {
    flex: 1;
    padding: 0 !important;
    text-align: center;
    font-weight: 600;
    color: $color-grey4;
  }

  .sm {
    height: 26px;
    color: $color-grey4;
    cursor: pointer;
    min-width: 0;
    margin: 0 !important;
  }
}

.datepicker-time-spacer {
  text-align: center;
  width: 20px;
  flex: 0 auto;
}

.now {
  padding-bottom: 10px;
  color: $color-text-light;
}

.relative-date-fields {
  display: flex;

  > input {
    flex: 0 auto;
    margin-right: 15px;
    width: 65px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .Select {
    flex: 1;
  }
}

.datepicker-relative {
  .datepicker-wrap {
    text-align: left;
    width: 210px;
  }
}

.datepicker--buttons--horizontal {
  margin: -21px -20px 20px -20px;
  padding: 10px 20px 20px;
  background: $color-white;
  border-bottom: 1px solid $color-grey-light2;
  display: flex;

  .button {
    margin: 0 8px;
    flex: 1;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
