.flex-comments {
  color: $color-text-light;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-comments__text {
  padding-right: 5px;
  text-transform: uppercase;
}

.comments--list {
  .position--bottom & {
    padding: 0 20px 0 30px;

    .comments--list--item {
      display: flex;
    }
  }
}

.comments--tr {
  .comments--list {
    padding: 0 20px 0 30px;

    .comments--list {
      padding: 0;
    }
  }

  .comments--list--item {
    display: flex;
  }
}

.comments--list--fullwidth {
  width: 100%;
}

.comments--list--item {
  border-bottom: 1px solid $color-right-aside;
  padding: 18px 0;

  &:last-child {
    border-bottom: none;
  }

  .inline-list {
    .fa-caret-down,
    .fa-caret-up,
    .icon-caret-down,
    .icon-caret-up {
      margin-top: 2px;
    }

    .fa-caret-left,
    .icon-caret-left {
      margin-top: 3px;
    }

    .passive {
      color: $color-text;
    }
  }

  .feedbacks {
    padding: 20px 0 0 23px;
  }

  .details--link {
    margin-left: 40px;
  }
}

.comments--list--label {
  font-weight: 500;
  flex: 0 auto;
  width: 120px;
}

.comments--list--text {
  flex: 1;
  padding-top: 3px;
}

.comments--list--title {
  padding-bottom: 5px;
  font-weight: 500;
}

.comments--list--details {
  line-height: 1.6;
  padding: 5px 0 0;
  color: $color-text-light;
}

.details--link {
  float: right;

  .fa {
    margin: 1px 0 0 5px;
    display: inline-block;
    vertical-align: top;
  }
}
