$error-border: 1px solid $color-red;
$success-border: 1px solid $color-green;

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
.likefield {
  background: $color-white;
  border: 1px solid #d4d7dc;
  border-radius: 4px;
  box-shadow: none;
  font-size: $size-sm;
  font-weight: 400;
  padding: 8px 15px;
  height: 42px;
  margin: 0;
  -webkit-transition: all 0s ease-out;
  -moz-transition: all 0s ease-out;
  transition: all 0s ease-out;

  &:focus {
    border-color: $color-link;
    box-shadow: none;
    background: $color-white;

    ~ .input-group-button {
      button {
        border-color: $color-link;
      }
    }
  }

  &.success {
    border-color: $color-green;
  }

  &.error {
    border-color: $color-red;

    &,
    &:focus {
      + .input-group-button button {
        border-top: $error-border;
        border-right: $error-border;
        border-bottom: $error-border;
      }
    }
  }

  @include placeholder {
    color: $color-grey2;
  }
}

.form-error-outline input {
  border: $error-border;
}

.central-form {
  margin: 0 auto;
  width: 520px;

  &:last-child {
    padding-bottom: 30px;
  }
}

.form--row {
  display: flex;
}

.form--left {
  width: 160px;
  flex: 0 auto;
}

.form--right {
  flex: 1;
  padding: 0 0 0 30px;
}

.form {
  padding-top: 15px;

  label,
  .like-label {
    padding: 0 0 23px;
    line-height: 1.8;
    color: #0a0a0a;
    font-weight: normal;

    .label-text {
      color: $color-text;
      font-weight: 600;

      &.passive {
        color: $color-grey3;
      }
    }

    &.pb14 {
      padding-bottom: 14px;
    }

    &.error {
      input,
      textarea {
        border: $error-border;

        &:focus {
          + .input-group-button button {
            border-top: $error-border;
            border-right: $error-border;
            border-bottom: $error-border;
          }
        }
      }

      .input-group-button button {
        border-top: $error-border;
        border-right: $error-border;
        border-bottom: $error-border;
      }
    }

    &.success {
      input,
      textarea {
        border: $success-border;
      }

      .input-group-button button {
        border-top: $success-border;
        border-right: $success-border;
        border-bottom: $success-border;
      }

      small.error {
        display: none;
      }
    }

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea,
    select {
      margin: 9px 0 0;
      outline: none !important;
    }

    > h3,
    > .h3 {
      font-size: 16px;
      font-weight: 600;
      padding: 3px 0 0;
    }

    .datepicker-block {
      margin: 9px 0 0;

      .fields-sep {
        width: 45px;
        text-align: center;
      }
    }

    .datepicker-position {
      input {
        margin: 0;
        height: 42px;
        //background: #fafafa !important;
      }

      .button-date {
        margin: 0;
        height: 42px;
        text-align: left;
        padding: 5px 5px 5px 31px;
        font-weight: 400;
        color: $color-text;

        &:hover {
          background: $white;
          color: $color-text;
          border-color: $color-grey3;
        }

        &:focus,
        &:active {
          background: $white;
          border-color: $color-link;
          color: $color-text;

          + .icon-calendar {
            color: $color-link;
          }
        }
      }

      .icon-calendar {
        z-index: 0;
      }
    }
  }

  .button-date {
    margin: 0;
    height: 42px;
    text-align: left;
    padding: 5px 5px 5px 31px;
    font-weight: 400;
    color: $color-text;

    &:focus,
    &:hover {
      background: $white;
      color: $color-text;
      border-color: $color-link;

      + .icon-calendar {
        color: $color-link;
      }
    }
  }

  select {
    &:focus {
      border-color: $color-link;
    }
  }

  textarea {
    width: 100% !important;
    height: 135px !important;
    resize: none;
  }

  h1,
  .h1 {
    padding-bottom: 20px;
  }

  .line {
    background: $color-grey2;
    margin: 0 0 32px;
    height: 1px;
    width: 100%;
  }

  .input-group {
    margin: 9px 0 0;

    input {
      margin: 0;
    }
  }

  .error-sm {
    text-align: right;
    display: block;
    color: $color-red;

    &.text-left {
      text-align: left;
    }
  }

  .login-info {
    word-wrap: break-word;
    padding: 0 30px 25px;
    color: $color-text-light;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    margin: 0 auto;
    max-width: 400px;

    strong {
      color: $color-text;
    }
  }

  &.p0 {
    padding: 0;
  }
}

.input-group {
  &.white-group {
    button {
      background: $color-white;
      border-color: #d4d7dc;
      border-radius: 0 4px 4px 0;
      box-shadow: none;
      font-size: $size-sm;
      font-weight: 600;
      height: 42px;
      // can be this one
      color: $color-link;
      margin: 0;
      border-left: none;
      -webkit-transition: all 0s ease-out;
      -moz-transition: all 0s ease-out;
      transition: all 0s ease-out;
    }
  }

  input {
    border-right-width: 0 !important;
    border-radius: 4px 0 0 4px !important;

    &:last-child {
      border-right-width: 1px !important;
      border-radius: 4px !important;
    }

    &:focus {
      + .input-group-button {
        button {
          border-color: $color-link;
        }
      }
    }
  }

  &.pressed {
    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea {
      border-color: $color-link;
      box-shadow: none;
      background: $color-white;
    }

    .input-group-button {
      button {
        border-color: $color-link;
      }
    }
  }
}

.form-md {
  label,
  .like-label {
    padding: 0 0 15px;
  }
}

.inputs--row {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.inputs--row3 {
  .inputs--col {
    width: 150px;
  }
}

.inputs--col--md {
  width: 380px;
}

.inputs--col--md2 {
  width: 210px;
}

.inputs--col--sm {
  width: 100px;
}

.inputs--col--sm2 {
  width: 172px;
}

.inputs--col {
  width: 240px;
}

.fullscreen-wrap {
  display: flex;
  align-items: center;
  text-align: center;
}

.fullscreen {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
}

.form-block {
  padding: 0 0 20px;
}

.form--field-block--wrap {
  height: 40px;
  margin-bottom: 1rem;
  display: flex;

  .button {
    margin: 1px 0 3px 4px;
    text-transform: uppercase;
  }
}

.form--field--wrap {
  flex: 1 1 0;
  margin: 0;
}

.form--field {
  width: 100%;
  border-radius: 3px;

  &.success {
    border-color: $color-green;
  }

  &.error {
    border-color: $color-red;
  }

  &.pending {
    border-color: $color-link;
  }

  + .input-group-button {
    vertical-align: top;

    button {
      height: 42px;
    }
  }
}

.form--field--button--wrap {
  flex: 0 1 auto;

  &.w76 {
    width: 76px;
  }
}

.form--fields--line {
  @include grid-row(12);

  .form--field {
    width: auto;
    display: inline-block;
    margin: 0 3px 0 0;
    max-width: 120px;

    @include breakpoint(medium down) {
      margin-bottom: 5px;
    }

    &.Select {
      margin: 0;
      max-width: 150px;

      @include breakpoint(small down) {
        width: 100%;
        max-width: none;
      }
    }
  }

  .form--field__lg {
    min-width: 225px;
    max-width: 225px;
  }

  .form--field__md {
    max-width: 90px;
    text-align: center;
  }

  .form--field__sm {
    max-width: 55px;
    text-align: center;
  }

  .form--buttons {
    display: inline-block;
    vertical-align: top;

    .button {
      margin: 1px 0 1px 2px;
    }
  }

  .select2-container {
    vertical-align: top;
  }

  .form--field {
    margin: 0 16px 5px 0;
  }

  .select--wrap {
    max-width: 250px;
    display: inline-block;
    vertical-align: top;
    margin: 0 16px 5px 0;
  }

  .select--wrap__md {
    min-width: 250px;
  }

  .select--wrap__lg {
    min-width: 280px;
    max-width: 280px;
  }

  + .error-line {
    float: none;
    margin: 8px 0 0;
    max-width: none;
    text-align: left;
    position: relative;
  }

  .settings-block & {
    + .error-line {
      float: none;
      margin: 3px 0 -8px 0;
      max-width: none;
      text-align: left;
      position: relative;
      min-height: 18px;
    }
  }
}

.line--text {
  color: $color-text-light;
  display: inline-block;
  margin: 0 16px 0 0;
}

.form--buttons--line {
  text-align: right;
  padding: 26px 20px 0;
  margin: 35px -60px 0 -60px;
  border-top: 1px solid $color-grey;

  @include breakpoint(large down) {
    margin: 35px -20px 0 -20px;
  }

  @include breakpoint(medium down) {
    margin: 35px -20px 0 -20px;
  }
}

.form--select {
  font-size: $size-lg;
  line-height: 1.4;
  padding: 6px 0 0;

  .select2-container {
    vertical-align: top;
  }
}

.error-line {
  font-size: 12px;
  text-align: right;
  color: $color-red;
  float: right;
  margin-top: 2px;
  max-width: 375px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 0 5px;
  position: relative;
  z-index: 1;

  @include breakpoint(small down) {
    margin: 0;
    max-width: none;
    overflow: visible;
    float: none;
    padding: 6px 0 0;
    text-align: left;
  }
}

.errors-bottom .error-line {
  margin-top: 5px;
}

.success-line {
  font-size: 18px;
  text-align: right;
  color: $color-green;
  margin: -34px 13px 0 0;
  float: right;
}

.pending-line {
  font-size: 18px;
  text-align: right;
  color: $color-link;
  margin: -34px 13px 0 0;
  float: right;
}

.form--label--line {
  padding: 0 0 7px;
}

.form--info {
  max-width: 270px;
  min-height: 153px;

  @include breakpoint(medium down) {
    max-width: none;
    min-height: 0;
  }
}

.form--label--inline {
  line-height: 37px;
  vertical-align: top;
  display: inline-block;
  margin: 0 10px;
  color: $color-text-light;

  @include breakpoint(medium down) {
    display: block;
    margin: 0;
  }
}

.checkbox,
.radio {
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin: 0;
  font-size: $size-md;

  > span {
    position: relative;
    padding: 0 0 0 24px;
    display: inline-block;
    color: $color-text;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background: #eee;
    }

    &:after {
      content: '\f00c';
      font-family: FontAwesome;
      position: absolute;
      left: 0;
      top: 0;
      width: 14px;
      line-height: 14px;
      text-align: center;
      font-size: 10px;
      color: $color-link;
      display: none;
    }
  }

  input {
    position: absolute;
    left: -20px;
    top: 0;

    &:checked {
      + span {
        font-weight: 500;

        &:after {
          display: block;
        }
      }
    }

    &:disabled {
      + span {
        font-weight: 500;
        color: $color-grey-light !important;
      }
    }
  }
}

.radio-round {
  span {
    &:before {
      border-radius: 50%;
    }

    &:after {
      content: '';
      left: 4px;
      top: 4px;
      width: 6px;
      height: 6px;
      line-height: 6px;
      text-align: center;
      background: $color-link;
      display: none;
      border-radius: 50%;
    }
  }
}

.checkbox-single,
.radio-single {
  height: 14px;

  > span {
    padding: 0 0 0 14px;

    &:before {
      border: 1px solid #eee;
      background: $color-white;
    }
  }

  input {
    &:checked {
      + span {
        &:before {
          background: #eee;
        }
      }
    }
  }
}

.checkbox-white,
.radio-white {
  height: 14px;

  > span {
    padding: 0 0 0 14px;

    &:before {
      border: 1px solid #eee;
      background: $color-white;
    }
  }

  input {
    &:checked {
      + span {
        &:before {
          background: $color-white;
        }
      }
    }
  }
}

.checkbox--list--inline,
.radio--list--inline {
  padding: 8px 0 12px;

  @include breakpoint(medium down) {
    padding: 0 0 12px;
  }

  .checkbox,
  .radio {
    display: inline-block;
    margin-right: 50px;
  }
}

.radios-logos {
  img {
    max-height: 40px;
    max-width: 140px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  input {
    &:checked {
      + span {
        &:before,
        &:after {
          display: none;
        }

        img {
          -webkit-filter: grayscale(0);
          filter: grayscale(0);
        }
      }
    }
  }

  .radio {
    span {
      padding: 0;

      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.form--label--fieldset {
  padding: 0 15px 10px 0;
  display: inline-block;
  vertical-align: top;
}

.form--label--date-sets {
  @include grid-col(12);

  .form--buttons {
    @include breakpoint(medium down) {
      display: block;
      padding: 0 0 20px;
    }
  }
}

.form--label--date-set {
  @include grid-col(12);

  display: none;
  font-weight: 600;
  font-size: 20px;
  padding: 8px 0 15px;
}

.date-set {
  .form--label--date-sets {
    display: none;
  }

  .form--label--date-set {
    @include grid-col(4);

    @include breakpoint(medium down) {
      @include grid-col(12);
    }

    display: block;
  }

  .checkbox--list--inline,
  .radio--list--inline {
    @include grid-col(8);

    @include breakpoint(medium down) {
      @include grid-col(12);
    }
  }
}

.form--results {
  display: none;
}

.form--success {
  text-align: center;
  font-size: 15px;
  position: relative;
  font-weight: 400;
  z-index: 1;
  padding: 100px 0;
  color: $color-text-light;

  p {
    padding: 0 0 40px;
  }

  h3 {
    margin: 0;
    color: $color-text;
    padding: 0 0 40px;
  }

  @include breakpoint(medium down) {
    padding: 30px 0;
  }
}

.form--success--in {
  max-width: 400px;
  margin: 0 auto;
}

.form--success--ico {
  width: 91px;
  height: 91px;
  opacity: 0.8;
  background-color: $color-green;
  display: inline-block;
  border-radius: 50%;
  margin: 0 0 50px;

  .icon-ok {
    color: $color-white;
    line-height: 89px;
    font-size: 50px;
  }
}

.form--group {
  padding: 0 0 22px;
}

.form--label {
  color: $color-text-light;
  font-size: 14px;
  display: block;
  padding: 0 0 6px;
}

select {
  font-size: $size-md;
}

.login-box {
  padding: 10px;
  text-align: center;

  [type='text'],
  [type='password'],
  [type='date'],
  [type='datetime'],
  [type='datetime-local'],
  [type='month'],
  [type='week'],
  [type='email'],
  [type='number'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='url'],
  [type='color'],
  textarea {
    margin: 0 0 26px;

    @include placeholder {
      font-weight: 400;
    }
  }

  $error-border: 1px solid $color-red;
  $success-border: 1px solid $color-green;

  .row {
    &.error {
      input,
      textarea {
        border: $error-border;
      }

      .input-group-button button {
        border-top: $error-border;
        border-right: $error-border;
        border-bottom: $error-border;
      }
    }

    &.success {
      input,
      textarea {
        border: $success-border;
      }

      .input-group-button button {
        border-top: $success-border;
        border-right: $success-border;
        border-bottom: $success-border;
      }

      small.error {
        display: none;
      }
    }
  }

  [type='password'] {
    font-size: 19px;
    font-size: 12px \9;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-size: $size-sm;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }

    @include placeholder {
      font-size: $size-sm;
    }
  }

  @supports (-ms-ime-align: auto) {
    [type='password'] {
      font-size: $size-sm;
    }
  }

  label {
    color: $color-text-light;
    text-align: left;
    font-size: 13px;
    padding: 0 0 7px;
  }

  strong {
    font-weight: 500;
  }

  h3 {
    color: $color-text-light;
    text-align: center;
    padding: 0 0 15px;
    font-weight: 300;
    font-size: $size-lg;
  }

  small.error {
    margin-top: -20px;
    margin-bottom: 26px;
    color: $color-red;
    display: block;
    text-align: right;
    width: 100%;
  }

  .button--submit {
    @extend .button;
    @extend .green;

    margin: 32px auto 28px;
    min-width: 172px;
  }

  .forgot-link {
    text-align: left;
    color: $color-text-light;
    font-size: $size-sm;
    padding: 0 0 7px;
    margin: -17px 0 0 0;
    position: relative;
    z-index: 2;

    @include grid-row(12);
    @include grid-row-nest(30px);

    a {
      color: $color-blue;
      font-weight: 500;

      &:hover {
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }

  .input-group {
    margin: 0;
  }

  .login-info {
    word-wrap: break-word;
    padding: 15px 30px 25px;
    color: $color-text-light;
    font-size: 13px;
    line-height: 1.4;

    strong {
      color: $color-text;
      margin: 0 5px;
    }
  }
}

/* Those are safari hacks, they need to be here */
::-webkit-full-page-media,
:future,
:root .login-box [type='password'] {
  font-size: $size-sm;
}

/* end of safari hacks */

.forgot-l {
  @include grid-col(6);
}

.forgot-r {
  @include grid-col(6);

  text-align: right;
}

.login-success--text {
  text-align: center;
  font-size: $size-lg;
  padding: 40px 0;
  font-weight: 400;
  margin: 0 -100px;

  @media (max-width: 640px) {
    margin: 0;
  }
}

.logo-big {
  position: relative;
  color: $color-link;
  font-size: $size-md;
  display: inline-block;
  margin: 0 auto 50px;
  width: 267px;

  .logo,
  img {
    margin: 0;
    width: 100%;
  }
}

.logo-more {
  position: absolute;
  right: 0;
  bottom: 0;
}

.select-lang {
  text-align: right;
  padding: 30px 0;

  @include breakpoint(small down) {
    text-align: center;
  }

  select {
    background-color: transparent;
    border-color: transparent;
    max-width: 120px;
    display: inline-block;
    outline: none !important;
    cursor: pointer;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.login-error {
  text-align: center;
  padding: 0 10px 10px;
  color: $color-red;
}

.reset-info {
  text-align: center;
  padding: 0 10px 10px;
  color: $color-link;
}

input {
  &.error {
    border-color: $color-red;
  }
}

select {
  height: 42px;
  border-radius: 4px;

  &.error {
    border-color: $color-red;
  }
}

.opacity0 {
  opacity: 0;
}

.inline-fields {
  display: flex;
  width: 100%;
}

.inline-fields--left {
  flex: 1;

  input,
  select {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }
}

.inline-fields--right {
  flex: 0 auto;
  width: 100px;

  input,
  select {
    border-radius: 0 4px 4px 0;
  }
}

.profile--row--wrap {
  margin: 0 -20px;
}

.profile--row {
  display: flex;
  width: 100%;
}

.profile--l,
.profile--r {
  width: 50%;
  padding: 0 20px;
}

.profile--top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .links {
    margin-bottom: 20px;

    .icon {
      margin-top: 0;
    }
  }
}

.small-alert-text {
  font-size: 12px;
  color: $color-orange;
  margin-top: -5px;

  a,
  span,
  div {
    display: inline-block !important;
    margin: 0;
    padding: 0 !important;
    font-size: 12px;
    color: $color-orange !important;
    font-weight: 400;

    &:before,
    &:after {
      display: none;
    }
  }

  a {
    text-decoration: underline;
  }
}

.radio-item-cols {
  .radio-items {
    padding-top: 9px;

    @include column(2);
    @include column-gap(40px);

    .form-item & {
      @include column-gap(10px);

      padding-top: 18px;
    }
  }
}

.radio-item {
  .radio {
    padding: 0;
    height: auto;
    cursor: pointer;

    @extend .radio-white;

    input {
      &:checked {
        + span {
          font-weight: 400;
        }
      }
    }

    > span {
      padding: 0 0 0 20px;
      line-height: 16px;
    }
  }
}

.checkbox-item-cols {
  padding-bottom: 15px;
}

.checkbox-item {
  .checkbox {
    padding: 0;
    height: auto;
    cursor: pointer;

    @extend .checkbox-white;

    input {
      &:checked {
        + span {
          font-weight: 400;
        }
      }
    }

    > span {
      padding: 0 0 0 20px;
      line-height: 16px;
    }
  }
}

.form-subtitle {
  font-size: 14px;
  letter-spacing: 0.43px;
  line-height: 16px;
  padding-bottom: 14px;
  font-weight: 500;
}
