.profile {
  display: inline-block;
  margin: 0;
  line-height: 29px;
  vertical-align: top;
  color: $color-white;
  text-decoration: none;
  position: relative;

  &:hover,
  &:active,
  &:focus {
    .profile--name {
      color: $color-white;
    }
  }
}

.profile--name {
  display: inline-block;
  vertical-align: top;
}

.profile--ico {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $color-white;
  margin: 0 7px 0 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  overflow: hidden;

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .icon-person {
    font-size: 36px;
    line-height: 50px;
    color: $color-link;
  }
}

.profile--link {
  padding: 0 20px 0 0;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    border: 4px solid transparent;
    border-top-color: $color-header-icon;
    margin: -1px 0 0 0;
  }

  &:hover {
    color: $color-white;
    text-decoration: none;

    &:after {
      border-top-color: $color-white;
    }
  }
}

.profile--access-token-missing {
  display: flex;
  align-items: center;
  background: $color-white;
  margin: 0;
  padding: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
