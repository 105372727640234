.info-dialog--list {
  strong {
    font-weight: 600;
    margin: 0 0 0 10px;
  }
}

.info-dialog--item {
  padding: 0 0 10px;
  display: flex;
  width: 100%;
}

.info-dialog--label {
  flex: 0 auto;
  padding: 0 7px 0 0;
}

.info-dialog--text {
  flex: 1;
}
