.inventory-tab--in {
  position: absolute;
  padding-right: 20px;
  padding-left: 20px;
  //overflow-y: auto;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 20px;

  &.related {
    top: 0;
    position: relative;
    padding: 0;
    min-height: calc(100vh - 60px - 56px - 20px - 100px - 40px);

    .inventory-dots {
      right: 17px;
      top: 20px;
    }
  }
}

.inventory-empty {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $color-white;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: $size-md;
  color: $color-text-light;
  line-height: 1.4;
  z-index: 3;

  > div {
    width: 100%;
  }

  .top-gap & {
    top: 107px;
    border-top: 1px solid $color-grey2;
  }

  .top-gap-sm & {
    top: 44px;
  }

  .button-group__with-dropdown {
    max-width: 260px;
    margin: 0 auto;
  }
}

.fixed-tab {
  display: flex;
  flex-direction: column;
  overflow: visible;

  > .clearfix {
    flex: 0 auto;
  }

  .table--wrapped {
    flex: 1;
    overflow-y: auto;
  }
}

.inventory-empty-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto 25px;
  border: 5px solid $color-grey2;
  background: $color-background;
  position: relative;

  &:after {
    content: '';
    width: 5px;
    height: 80px;
    position: absolute;
    left: 22px;
    top: -15px;
    background: $color-grey2;

    @include rotate(45deg);
  }
}

.schedule-empty {
  p {
    padding-bottom: 5px;
  }

  .data-empty-icon {
    margin-bottom: -10px;
  }

  .button {
    margin-top: 15px;
  }
}

.inventory-dots {
  position: absolute;
  right: 37px;
  top: 25px;
}

.inventories-wrap {
  padding: 10px 0;
  margin: 0 -20px;
  min-height: calc(100vh - 60px - 58px - 20px - 40px - 57px);
  position: relative;

  table {
    td,
    th {
      &:first-child {
        padding-left: 20px;
      }

      &:first-child {
        padding-right: 20px;
      }
    }
  }
}

.tools-wrap {
  padding: 10px 0;
  margin: 0 -20px;
  min-height: calc(100vh - 60px - 58px - 20px - 20px);
  position: relative;

  table {
    td,
    th {
      &:first-child {
        padding-left: 20px;
      }

      &:first-child {
        padding-right: 20px;
      }
    }
  }
}

.loading-table-indicator {
  line-height: 36px;
  margin-bottom: 15px;
  margin-top: 40px;
  font-size: 36px;
}
