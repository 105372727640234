.locations-all {
  .line {
    background: $color-grey-light;
    height: 1px;
  }
}

.locations-block {
  position: relative;
  display: flex;
  border: 1px solid $color-grey-light;
  border-radius: 5px;
  font-size: $size-md;
  margin: 15px 0 0;
}

.locations--left {
  flex: 0 auto;
  width: 160px;
  overflow: auto;
  height: 400px;
  background: #f8f8fc;
  position: relative;
  padding-right: 1px;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: $color-grey-light;
    z-index: 1;
  }
}

.locations--right {
  flex: 1;
  overflow: auto;
  height: 400px;
  position: relative;
}

.locations-results {
  position: relative;
}

.locations-result {
  position: relative;
  border-bottom: 1px solid $color-grey-light;
  border-radius: 5px 0 0;
  padding: 7px 46px 7px 10px;
  font-weight: 500;

  label {
    font-size: 11px;
    color: $color-text-light;
    font-weight: 300;
    line-height: 1.4;
    padding: 0;
  }

  .fa-check-circle,
  .fa-times-circle {
    position: absolute;
    right: 17px;
    top: 50%;
    margin: -9px 0 0;
    font-size: 18px;
    color: $color-grey2;
  }

  .fa-times-circle {
    display: none;
  }

  &.selected {
    .fa-check-circle {
      color: $color-green;
      text-shadow: 0 0 5px $color-green;
    }

    &:hover {
      cursor: pointer;

      * {
        cursor: pointer;
      }

      .fa-check-circle {
        display: none;
      }

      .fa-times-circle {
        display: block;
        color: $color-red;
        text-shadow: 0 0 5px $color-red;
      }
    }
  }

  &.active {
    background: $color-white;

    &:after {
      content: "";
      position: absolute;
      right: -1px;
      top: 0;
      bottom: 0;
      background: $white;
      width: 1px;
      z-index: 2;
    }
  }

  &.passive {
    background: $color-white;
  }
}

.locations-list {
  padding: 0;
  list-style: none;

  li {
    padding: 0;
  }

  a {
    display: block;
    color: $color-text;
    padding: 10px 30px;

    &:hover {
      color: $color-text;
      background: #f8f8fc;
    }
  }

  .checkbox {
    display: block;
    height: auto;

    &:hover {
      span {
        background: #f8f8fc !important;
      }
    }

    > span {
      display: block;
      color: $color-text-light;
      padding: 10px 16px 10px 40px;

      &:before,
      &:after {
        left: 16px;
        top: 50%;
        margin-top: -7px;
      }
    }

    input {
      &:checked {
        + span {
          font-weight: 400;
          color: $color-text;
          background-color: #f2fafa;
        }
      }
    }
  }
}

.locations-empty {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  line-height: 1.4;
  color: $color-text-light;

  > div {
    width: 170px;
    margin: 0 auto;
  }

  .link-text {
    color: $color-text-light;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $color-text-light;
    }
  }
}

.locations-empty-icon {
  margin-bottom: 15px;
}

.locations--right--wrap {
  display: flex;
  flex-direction: column;
}

.locations--right-top {
  flex: 0 auto;
  border-bottom: 1px solid $color-grey-light;
}

.locations--right-content {
  flex: 1;
  overflow: auto;

  @include scroller;
}

.locations-list-select-all {
  display: flex;
  align-items: center;
  height: 44px;

  li {
    flex: 0 auto;

    &:first-child {
      flex: 1;
    }

    .checkbox {
      border-top-right-radius: 5px;

      span {
        padding-top: 11px;
      }
    }
  }
}
