@use "sass:list";

.online {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $color-grey2;
  box-shadow: 0 0 6px rgba($color-grey2, 0.5);

  &.success {
    background: $color-green;
    box-shadow: 0 0 6px rgba($color-green, 0.5);
  }

  &.warning {
    background: $color-yellow;
    box-shadow: 0 0 6px rgba($color-yellow, 0.5);
  }

  &.alert {
    background: $color-red;
    box-shadow: 0 0 6px rgba($color-red, 0.5);
  }
}

.table {
  border: none;

  thead,
  tbody,
  tfoot {
    background: none;
    border: none;
  }

  thead {
    td,
    th {
      color: #cecece;
    }
  }

  tbody {
    tr {
      background: #fafafa;

      &:nth-child(2n) {
        background: $color-white;
      }

      &.offline {
        color: $color-text-light;
      }
    }

    td {
      padding: 15px;
    }
  }

  .td-icon {
    padding-right: 0;
    width: 10px;
    text-align: right;
    color: $color-link;
    font-size: 0.88rem;
    line-height: 14px;
  }

  .td-checkbox {
    padding-right: 0;
    width: 25px;
    font-size: 0.88rem;
    line-height: 14px;

    .radio,
    .checkbox {
      height: 14px;
    }
  }

  .link-text {
    font-weight: 600;
    display: block;

    &:hover {
      color: $color-link;
    }
  }
}

.table--title {
  border-bottom: 1px solid $color-right-aside;
  font-size: $size;
  font-weight: 600;
  padding: 15px 15px 15px 10px;
  margin: 0 0 10px;

  .fa,
  .icon {
    margin: 0 7px 0 0;
    width: 15px;
    text-align: center;
    color: $color-link;
    display: inline-block;
  }
}

.table--title--info {
  font-size: $size-sm;
  color: $color-grey2;
  padding: 0 0 10px;
}

.table--title--block {
  padding: 15px 0 10px 10px;
  display: flex;
  height: 120px;

  h1,
  .h1 {
    font-size: $size-xxl;
  }
}

.table--title--left {
  flex: 1;
}

.table--title--right {
  flex: 0 auto;
  width: 40%;

  img {
    width: 100%;
  }
}

.product-empty {
  position: absolute;
  padding: 20px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  text-align: center;
  font-size: $size-md;
  color: $color-text-light;
  line-height: 1.4;
  z-index: 3;

  .flex-el {
    width: 100%;
    display: flex;
    align-items: center;
    max-height: calc(100vh - 60px - 20px - 84px);
    min-height: 200px;

    > div {
      width: 100%;
    }
  }
}

.product-empty-icon {
  min-height: 76px;
  display: flex;
  align-items: center;
  margin: 0 auto 15px;

  > object {
    margin: 0 auto;
  }
}

.info-table {
  margin: 10px 0 0;

  tbody {
    background: none;
    border: none;

    tr {
      background: none;
    }
  }

  td {
    padding: 2px 5px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .fa,
  [class^="icon-"],
  [class*=" icon-"] {
    color: $color-left-aside;
    margin: 0 3px 0 0;
  }
}

.table-scroll {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: -19px;
    top: 0;
    height: 41px;
    width: 25px;
    background: $color-white;
    display: none;
    z-index: 5;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }

    @supports (-ms-ime-align: auto) {
      display: block;
    }
  }

  thead {
    position: relative;
    flex: 0 0 auto;
    display: block;
    margin: 0 -20px 0 0;
    padding: 0 20px 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-base-color: transparent;
    scrollbar-highlight-color: transparent;
    scrollbar-track-color: $color-grey2;
    scrollbar-face-color: transparent;
    scrollbar-arrow-color: transparent;
    scrollbar-shadow-color: transparent;
    scrollbar-dark-shadow-color: transparent;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-button {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    tr {
      width: 100%;
      display: table;
      table-layout: fixed;
    }
  }

  tfoot {
    margin: 0 -20px 0 0;
    padding: 0 20px 0 0;
  }

  tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
    margin: 0 -20px 0 0;
    padding: 0 20px 0 0;
    scrollbar-base-color: transparent;
    scrollbar-highlight-color: transparent;
    scrollbar-track-color: $color-grey2;
    scrollbar-face-color: transparent;
    scrollbar-arrow-color: transparent;
    scrollbar-shadow-color: transparent;
    scrollbar-dark-shadow-color: transparent;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-button {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-grey2;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    tr {
      width: 100%;
      display: table;
      table-layout: fixed;
    }
  }
}

.table-scroll-wrap-1 {
  margin: -6px 0 10px;
  height: calc(100vh - 80px - 56px - 50px - 40px - 120px - 60px);

  td {
    width: 22%;

    &:first-child {
      width: 33%;
    }
  }
}

.table-scroll-wrap-2 {
  margin: -6px 0 10px;
  height: list.slash(
    calc((100vh - 60px - 56px - 20px - 50px - 120px - 154px - 8px)),
    2
  );

  td {
    width: 22%;

    &:first-child {
      width: 33%;
    }
  }
}

.column-title {
  color: $color-link;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  max-width: 80px;
  vertical-align: top;
}

.non-compare {
  .col--2--left {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .col--2--right {
    display: none;
  }
}

.icon-compare-link {
  color: $color-grey-light;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-title {
  flex: 0 auto;
}

.flex-icon {
  width: 20px;
  flex: 0 auto;
  height: 14px;
}

.flex-content {
  flex: 1;
  padding: 0 0 0 5px;
}

.table-search {
  margin: -12px 0 0 0;
  width: 100%;
  max-width: 256px;
  padding: 0;
  display: block;
  -webkit-transition: width 0.5s ease-out;
  -moz-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  background: $color-white;
  z-index: 1;
  border: 1px solid $color-grey2;
  border-radius: 4px;

  .input-group {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .search-input {
    height: 24px;
    border: 1px solid transparent !important;
    border-left: none !important;
    order: 2;
    border-radius: 0 !important;
    padding: 0 5px;

    &:focus {
      border-color: transparent !important;
      box-shadow: none;
      background: $color-white !important;

      ~ .icon-search {
        color: $color-link;
      }

      ~ .input-group-button {
        .search-button {
          .icon-search {
            color: $color-link;
          }
        }
      }
    }
  }

  .input-group-button {
    order: 1;
    width: auto;

    &.last {
      order: 3;
    }
  }

  .search-button {
    height: 24px;
    width: 26px;
    border: 1px solid transparent !important;
    border-right: none;
    text-align: center;
    font-size: 12px;

    &:hover {
      color: $color-link;

      .icon-search {
        color: $color-link;
      }
    }
  }

  .icon-search {
    color: $color-text-light;
    display: inline-block;
  }

  .search-button--close {
    border-left: none;
    border-right: 1px solid transparent;
    font-size: 6px;

    &:hover {
      color: $color-red;
    }
  }

  .search-button--trigger {
    display: none;
  }

  &.compressed {
    width: 20px;
    border-color: transparent;

    .search-button--submit {
      display: none;
    }

    .search-button--close {
      display: none;
    }

    .search-button--trigger {
      display: block;
      border-color: transparent;
    }

    .search-input {
      width: 0;
      overflow: hidden;
      padding: 0;
      border-color: transparent !important;
    }
  }
}
