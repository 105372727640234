.search-inline {
  display: inline-block;
}

.search-block {
  position: relative;
  font-size: $size-md;
  width: auto;

  .input-group {
    display: flex;
    align-items: center;
    border-right: 1px solid #2f3d44;
    border-bottom: 1px solid #2f3d44;
    background-color: #2f3d44;
    border-radius: 3px;
    padding: 1px;
  }

  .search-input {
    order: 2;
    flex: 1;
  }

  .input-group-button {
    order: 1;
    width: auto;
    flex: 0 auto;
    display: flex;
    align-items: center;
  }

  .input-group-button__cross {
    order: 3;
  }

  &.with-button {
    .search-alls {
      display: flex;
      flex-direction: column;
      min-height: 395px;
      margin: 16px 0 10px;

      > div {
        width: 100%;
      }

      .search-title {
        flex: 0 auto;
      }

      .search-alls-list-wrap {
        flex: 1;
        max-height: none;
      }

      .search-button-bottom {
        flex: 0 auto;
        text-align: center;
      }
    }
  }
}

.archived-info {
  flex: 0 auto;
  text-align: center;
  font-size: 12px;
  padding: 20px 0;
  color: $color-text-light;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-info {
    margin-right: 7px;
  }
}

.search-button-bottom {
  flex: 0 auto;
  text-align: center;
}

.search-text {
  position: relative;
  padding: 0 20px 0 0;
  font-size: $size-md;
  color: $color-text;
  line-height: 20px;

  .filter--dropdown & {
    padding: 3px 0 3px 20px;
    position: relative;
    width: 230px;

    &:after {
      content: '\e906';
      font-family: 'datadash' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      left: 9px;
      top: 7px;
      color: $color-text-light;
    }

    + .search-dropdown {
      margin: 3px 0 0;
      width: 230px;
      min-width: 230px;
      white-space: normal;

      &:before,
      &:after {
        left: 13px;
      }
    }

    ul {
      padding: 0;
    }
  }
}

.search-placeholder {
  color: $color-text-light;
  padding: 0 10px;
}

.search-text-in {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.search-text-in--title {
  flex: 1;
  padding-right: 10px;
  white-space: normal;
}

.search-close {
  flex: 0 auto;
  padding: 0 5px;
  margin: -4px 0;
  font-size: 16px !important;
}

.search-input {
  padding: 0 10px;
  font-weight: 400;
  background-color: transparent;
  height: 20px;
  border: none !important;

  &:focus {
    background-color: transparent;
  }
}

.search-arrow {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
}

.search-triangle {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $color-grey transparent transparent transparent;
  }
}

.search-close {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 100%;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  font-size: 18px;
}

.search-dropdown {
  position: absolute;
  min-width: 250px;
  left: 0;
  top: 100%;
  margin-top: 6px;
  background: $color-white;
  border-radius: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    left: 20px;
    top: -11px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-grey2 transparent;
  }

  &:after {
    content: '';
    position: absolute;
    left: 20px;
    top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-white transparent;
  }
}

.search-title {
  padding: 14px 20px 12px;
  color: $color-link;
  border-bottom: 1px solid $color-grey-light;
}

.search-results {
  padding: 12px 0;
}

.search-result--block {
  padding: 0 0 17px;

  &:last-child {
    padding: 0;
  }

  &.disable {
    color: $color-grey2;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    a {
      color: $color-grey2;
    }
  }
}

.search-header {
  font-weight: 600;
  padding: 0 20px 10px;
}

.search-result--item {
  a {
    font-size: $size-md !important;
    display: block;
    padding: 0 20px;
    color: $color-text;
    font-weight: 300 !important;
    cursor: pointer;

    &:hover {
      color: $color-link;
    }
  }

  span {
    font-size: $size-md !important;
    display: block;
    padding: 0 20px;
    color: $color-text;
    font-weight: 300 !important;
  }
}

.search--buttons {
  text-align: center;
  border-top: 1px solid $color-grey-light;
  padding: 10px 10px 0;
  margin-top: 10px;

  .button {
    margin: 0 8px;
    min-width: 75px;
  }
}

.header {
  .search-inline {
    float: right;
    max-width: 250px;
    border-collapse: collapse;
    width: 100%;

    .input-group {
      margin: 0;
    }

    .input-group-field,
    .search-input {
      height: 26px;
      padding: 0 5px;
      width: 100%;
      color: $color-white;
      background: none;
      border: none;
      outline: none !important;

      @include placeholder {
        color: $color-white;
      }

      &:focus {
        ~ .input-group-button {
          .button__search {
            color: $color-link;
          }
        }
      }
    }

    .button__search {
      @extend .button;

      border: none;
      padding: 0;
      height: 26px;
      width: 26px;
      margin: 0 0 0 -1px;
      position: relative;
      background: none !important;
      color: $color-header-icon;

      &:hover {
        color: $color-link;
      }

      .icon {
        font-size: $size-lg;
      }
    }

    .button__cross {
      @extend .button;

      border: none;
      padding: 0;
      height: 26px;
      width: 26px;
      margin: 0 0 0 -1px;
      position: relative;
      background: none !important;
      color: $color-red;
      opacity: 0.8;

      &:hover {
        color: $color-red;
        opacity: 1;
      }

      .fa {
        font-size: $size-md;
      }
    }
  }

  .search-dropdown {
    color: $color-text;
    padding: 10px;

    .search-alls {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .search-alls-list {
    max-height: 104px;
    overflow: auto;

    @include scroller;
  }
}

.search-dropdown--big {
  min-width: 360px;
}

.search-dropdown--wrap {
  display: flex;

  .search-text-in {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .search-text-in--title {
    flex: 1;
    padding-right: 10px;
  }

  .search-close {
    flex: 0 auto;
    font-weight: 700;
    padding: 0 5px;
    margin: -4px 0;
    color: $color-red;
    font-size: 16px !important;
  }
}

.search-dropdown--left,
.search-dropdown--right {
  width: 50%;
  flex: 1;
}

.update-flex {
  display: flex;
  align-items: center;
  margin: 19px 0 10px;

  .events-group {
    float: none;
    margin: 0 20px 0 0;
  }

  .search-block {
    position: relative;

    .button__search {
      position: absolute;
      left: 0;
      top: 0;
      width: 36px;
      height: 36px;
      -webkit-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in;
      color: $color-grey3;
    }
  }

  .search-input {
    width: 200px;
    padding-left: 36px;
    height: 36px;
    border: 1px solid $color-grey3 !important;

    &:focus {
      border-color: $color-link !important;

      + .button__search {
        color: $color-link;
      }
    }
  }
}

.events-group {
  float: left;
  margin-top: 9px;
}

.form {
  .search-block-category {
    .search-text {
      margin-top: 9px;
      padding: 0;
    }

    .search-input {
      margin: 0;
      min-height: 40px;
      height: auto;
      border: 1px solid #d4d7dc !important;

      &:focus,
      .focus & {
        border-color: $color-link !important;

        + .input-group-button {
          .button__search {
            color: $color-link;
          }
        }
      }
    }

    .input-group-button {
      position: absolute;
      right: 0;
      top: 0;

      .button__search {
        width: 36px;
        height: 40px;
        text-align: center;
        color: $color-grey2;
        font-size: 16px;
      }
    }
  }
}

.search-popup {
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: 4px;
  background-color: $color-white;
  box-shadow: 0 2px 10px 0 rgba(46, 47, 66, 0.07);
  position: absolute;
  left: 0;
  top: 46px;
  width: 350px;
  z-index: 5;
  font-size: 12px;
  padding: 15px 20px 20px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 20px;
    top: -10px;
    border: 5px solid transparent;
    border-bottom-color: $color-grey-light;
  }

  &:after {
    border-bottom-color: $color-white;
    top: -9px;
  }

  strong {
    font-weight: 600;
  }

  h5 {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.search-popup-list {
  max-height: 200px;
  overflow: auto;
  margin-bottom: 25px;

  ul {
    column-count: 2;
    column-gap: 20px;

    li {
      display: inline-flex;
      width: 100%;

      .button_unchecked,
      .button_checked,
      .button_uncheck {
        flex: 0 auto;
        margin: 2px 9px 0 0;
      }
    }
  }
}

.search-item-text {
  flex: 1;
  line-height: 22px;
}

.search-popup {
  margin: 0 -7px 20px 0;

  .line {
    margin: 20px -20px !important;
  }
}

.button_unchecked,
.button_checked,
.button_uncheck {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border-radius: 2px;
  background: #fafafa;
  border: 1px solid $color-grey3;
  margin-right: 9px;
  font-size: 7px;

  &:hover {
    background: $color-grey-light;
  }
}

.button_unchecked {
  color: #a9ef4b;

  &:hover {
    color: $color-green;
  }
}

.button_checked {
  color: $color-green;
  background: none;
  border-color: transparent;
  cursor: default;

  &:hover {
    background: none;
    border-color: transparent;
  }
}

.button_uncheck {
  color: $color-red;
  font-size: 5px;
}

.search-popup-label {
  margin: 0 0 10px 26px;
  font-weight: 600;
}

.search-popup-bottom {
  display: flex;
  justify-content: space-between;

  .button__close,
  .button__cancel,
  .button__save {
    min-width: 86px !important;
    margin: 0 !important;
  }
}

.search-popup-list-more {
  margin: -15px 26px 25px;
}

.search-popup-placeholder {
  color: $color-text-light;
  font-size: 14px;
  padding: 5px 0 15px;

  &:last-child {
    padding-bottom: 0;
  }
}
