.machine-details--list {
  padding: 0;
  margin: -10px -20px 0;
}

.machine-details--item {
  display: flex;
  padding: 16px 0;
  font-weight: 500;
  font-size: $size-md;
  align-items: center;

  &:nth-child(odd) {
    background: #fafafa;
  }
}

.machine-details--icon {
  width: 87px;
  flex: 0 auto;
  text-align: center;
  font-size: 1.5rem;
  color: #cecece;
  padding: 0 10px 0 0;
}

.machine-details--text {
  flex: 1;
  display: flex;

  label {
    font-size: $size-sm;
    font-weight: 300;
    color: $color-text-light;
  }

  h3,
  .h3 {
    font-size: 17px;
    font-weight: 600;
    padding: 0;
  }

  p {
    padding: 0;
    min-height: 20px;
  }

  .machine-details--position {
    flex: 0 auto;
    width: 28%;

    &:first-child {
      width: auto;
      flex: 1;
    }

    &.w33 {
      width: 33%;
      flex: 0 auto;
    }

    &.w50 {
      width: 56%;
    }
  }
}

.locations--list {
  li {
    display: inline-block;
  }

  .fa {
    font-size: 8px;
    vertical-align: top;
    margin: 5px;
  }
}

.indicators-info {
  h1,
  .h1 {
    padding-bottom: 15px;
  }
}

.indicators-info {
  h1,
  .h1 {
    padding-bottom: 15px;
  }
}
