.uploaded--items {
  padding: 0 0 20px;
}

.uploaded--item {
  position: relative;
  padding: 0 0 15px 22px;
  font-size: $size-md;

  &:after {
    content: '\f0c6';
    font-family: FontAwesome;
    font-size: 20px;
    color: $color-link;

    @extend .fa-flip-horizontal;

    position: absolute;
    left: 0;
    top: -4px;
  }
}

.uploaded--title {
  font-weight: 500;

  .fa,
  .icon {
    margin: 0 0 0 5px;
    font-size: $size-lg;
    color: $color-text-light;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.uploaded--delete {
  margin: 0 0 0 10px;
  float: right;
  font-size: $size-lg;
  color: $color-red;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.upload-block,
.card--position-sm {
  border: 2px dashed #d4d7dc;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 160px;
  cursor: pointer;
  position: relative;
  font-size: $size-md;
  text-align: center;
  margin: 14px 0 20px;
  padding: 8px;
  align-items: center;
  color: $color-text-light;
  background-size: cover;
  background-position: center center;

  .plus-block {
    .fa,
    .icon {
      font-size: 20px;
      line-height: 46px;
    }
  }

  > div {
    width: 100%;
  }

  .modal--content & {
    margin: 0 0 35px;
    height: 250px;
  }
}

.card--position-sm {
  padding: 0;

  .image--wrap {
    height: 100%;

    &:hover {
      .image--actions {
        bottom: 0;
      }
    }
  }

  .card--image {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center center;
  }

  .image--actions {
    color: $color-white;
    font-size: 11px;
    padding: 12px 0;
    height: 34px;
    bottom: -34px;

    button {
      margin: 0;
    }
  }
}

[name='photo'] {
  position: relative;

  .error-line {
    padding: 0;
    position: absolute;
    left: 0;
    bottom: -17px;
    right: 0;
    text-align: center;
  }
}

.upload-block2 {
  height: 125px;
}

.plus-block {
  margin: 0 auto 9px;
  display: block;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 46px;
  border: 1px solid #d4d7dc;
  background: #eef0f2;
  color: $color-link;
}
