.anomally--wrap {
  h3,
  .h3 {
    font-size: 17px;
    font-weight: 600;
    padding: 0 0 10px;
  }

  > .col--in {
    background: #f5f8fc;
    padding-bottom: 0;
  }

  .col--3 {
    margin: 0 -30px;
    min-height: calc(100vh - 60px - 145px);
  }

  .col--3--col {
    flex: 1;
    margin: 0 10px;
    border-radius: 0;

    &:first-child {
      .col--in {
        border-radius: 0 0 5px;
      }
    }

    &:last-child {
      .col--in {
        border-radius: 0 5px 0 0;
      }
    }

    .col--in {
      border-radius: 0;
    }
  }
}
