.aside-popup {
  background: $color-right-popup;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
  position: fixed;
  right: 0;
  top: 117px;
  bottom: 0;
  width: 400px;
  animation: fadein 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);

  .scroll {
    padding: 0 !important;
    height: calc(100vh - 60px - 56px - 1px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .jspVerticalBar {
    margin: 0 20px 0 0;
  }

  .jspScrollable {
    .jspPane {
      padding: 0 !important;
    }
  }

  .line {
    background: $color-right-aside;
    height: 1px;
    margin: 0 -20px 20px;
  }

  .col--in {
    overflow: auto;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.aside-popup--top {
  background: $color-white;
  padding: 15px;
}

.aside-popup--center {
  padding: 22px 15px;

  h2,
  .h2 {
    padding: 0 0 18px;
  }
}

.card--position {
  position: relative;
  color: $color-white;
  margin: 0 0 25px;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    border-width: 30px;
    border-style: solid;
    border-color: transparent;
    border-radius: 0 5px 0 0;
  }

  img {
    width: 100%;
    border-radius: 2px;
    display: block;
  }

  .label {
    left: 20px;
    top: 15px;
    position: absolute;
  }

  &.alert {
    background: #f3f6f8;

    &:after {
      content: '\f071';
      font-family: FontAwesome;
      position: absolute;
      right: 7px;
      top: 5px;
      font-size: 20px;
      font-weight: 700;
      color: $color-white;
      z-index: 2;
    }

    &:before {
      border-top-color: $color-red;
      border-right-color: $color-red;
      z-index: 1;
    }
  }
}

.card--position2 {
  border-radius: 5px;
  overflow: hidden;

  .card-description {
    background: none;
  }
}

.card--image {
  height: 250px;
  border-radius: 5px;
}

.card-description {
  padding: 14px 14px 14px 20px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  align-items: center;
}

.card-description--left {
  flex: 1;

  p {
    padding: 0 0 8px;
  }

  strong {
    font-weight: 600;
    display: block;
    font-size: 15px;
  }
}

.card-description--right {
  flex: 0 auto;
}

.image--wrap {
  position: relative;
  overflow: hidden;

  &:hover {
    .image--actions {
      bottom: 0;
    }
  }
}

.image--actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -44px;
  background: rgba($color-link, 0.95);
  text-align: center;
  font-weight: 500;
  padding: 15px 0;
  height: 44px;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;

  .icon {
    font-size: 10px;
    margin-right: 5px;
  }

  button {
    margin: 0 15px;
    opacity: 0.75;
    text-align: center;
    padding: 0 2px;

    &:hover {
      opacity: 1;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.image-placeholder {
  border: 2px dashed $color-grey2;
  height: 262px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $color-text-light;
  font-size: $size-md;

  a {
    color: $color-text-light;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    padding: 0 0 16px;
  }

  img {
    width: 60px;
    margin: 0 auto;
  }
}

.image-placeholder-in {
  width: 100%;
}
