.tabs {
  font-size: $size-md;
  line-height: 34px;
  color: $color-text;
  background: none;
  border: none;
  font-weight: 600;

  li {
    padding: 0 20px 0 0;

    &:last-child {
      padding: 0;
    }

    a {
      height: 34px;
      padding: 0;
      line-height: 34px;
      position: relative;
      color: $color-text-light;
      text-decoration: none;
      background: none !important;

      &:before {
        content: '';
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        position: absolute;
        background: transparent;
      }

      &:hover {
        color: $color-text-light;

        &:before {
          background: $color-link-light;
        }
      }
    }
  }

  .is-active {
    font-weight: 600;

    a {
      color: $color-text;

      &:before {
        background: $color-link;
      }

      &:hover {
        color: $color-text;
      }
    }
  }

  &.right {
    float: right;
  }
}

.tabs-content {
  border: none;
  background: none;
}

.tabs-panel {
  padding: 0;
}

.tabs-title {
  > a {
    background: none !important;
  }
}

.big-tabs {
  margin: 0 -20px -2px;
  position: relative;
  z-index: 10;

  .col--in > & {
    margin: -20px -20px 8px;
  }
}

.big-tabs-list {
  display: flex;
  border-bottom: 1px solid #e2e5ea;
  padding-top: 2px;
  top: -2px;
  position: relative;
}

.big-tabs-scroll {
  position: relative;
  flex: 1;
  overflow: hidden;
  height: 59px;
  border-radius: 4px 0 0;
  margin-bottom: -2px;
}

.big-tabs-scroll-in {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  .slick-slider {
    flex: 1;
  }
}

.big-tab {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  color: $color-text-light;
  margin-bottom: -1px;
  margin-left: -1px;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    right: -1px;
    top: -1px;
    bottom: -1px;
    width: 1px;
    background: #e2e5ea;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-text;
    cursor: pointer;

    .label-num {
      background: $color-link;
    }
  }

  .label-num {
    margin: 0 0 0 8px;
    background: $color-text-light;
    color: $color-white;
    border-radius: 4px;
    line-height: 16px;
    min-width: 15px;
    text-align: center;
    padding: 0 3px;
    font-size: 10px;
    height: 15px;
    font-weight: 400;
  }

  &.active {
    z-index: 2;
    font-weight: 600;
    color: $color-text;
    background: $color-white;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      position: absolute;
      top: auto;
      left: -3px;
      bottom: 0;
      background: $color-white;
    }

    &:after {
      content: '';
      width: 4px;
      height: 4px;
      position: absolute;
      top: auto;
      right: -3px;
      bottom: 0;
      background: $color-white;
    }

    .big-tab-wrap {
      border-color: #e2e5ea #e2e5ea transparent;
      box-shadow: 0 0 2px rgba($black, 0.1);

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: auto;
        left: -9px;
        bottom: 1px;
        border-radius: 0 0 5px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent #e2e5ea #e2e5ea transparent;
      }

      &:after {
        z-index: 2;
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: auto;
        right: -9px;
        bottom: 1px;
        border-radius: 0 0 0 5px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent transparent #e2e5ea #e2e5ea;
      }
    }

    .big-tab-in {
      &:after {
        display: block;
      }
    }

    .label-num {
      background: $color-link;
    }

    .btn-link {
      display: inline-block;
    }
  }

  .btn-link {
    font-size: 10px;
    margin: 0 0 0 10px;
    display: none;
    line-height: 1;
    color: $color-text-light;

    &:hover {
      color: $color-link;
    }
  }
}

.big-tab-wrap {
  border-radius: 4px 4px 0 0;
  border: 1px solid transparent;
  z-index: 2;
}

.big-tab-in {
  min-height: 57px;
  padding: 0 20px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    background: $color-white;
    height: 2px;
    display: none;
  }
}

.big-tabs-actions {
  align-self: center;
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex: 0 auto;

  .button {
    font-size: 12px;
  }

  .button-plus {
    font-size: 13px;
    padding: 0.85em;
    width: 36px;

    .fa,
    [class^='icon-'],
    [class*=' icon-'] {
      margin: 0;
    }
  }
}

.big-tabs-sm {
  margin: 0 -40px 28px;

  .big-tabs-list {
    padding: 2px 20px 0;
  }

  .big-tab {
    margin-left: 0;

    &:after {
      background: $color-white;
    }

    &.active {
      .big-tab-wrap {
        box-shadow: none;
      }
    }
  }

  .big-tab-in {
    min-height: 26px;
  }

  .big-tabs-scroll {
    overflow: visible;
    height: 29px;
  }
}
