.modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
}

.ReactModal__Content {
  overflow: visible !important;
}

.ReactModal__Overlay {
  overflow: auto;
  z-index: 99999 !important;
}

.modal-content-wide {
  width: 900px !important;
}

.modal-content {
  margin: 0 auto;
  position: relative;
  overflow: visible;
  max-width: 100vw;
  width: 540px;
  background-color: white;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid rgba(220, 222, 223, 0.09);

  .loader-local {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $white;
  }
}

.modal--header {
  padding: 20px 60px 10px;
  position: relative;

  .modal--close {
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.5;
    font-size: 20px;
    cursor: pointer;
    z-index: 6;

    &:hover {
      opacity: 1;
    }
  }
}
.basic-modal--content {
  max-height: 90vh;
  overflow: auto;
}

.modal--content {
  padding: 10px 40px 30px;

  h3,
  .h3 {
    font-size: $size-lg;
    font-weight: 600;
    padding: 0 0 5px;
  }

  h4,
  .h4 {
    font-size: $size-md;
    font-weight: 600;
    padding: 10px 0 19px;

    &.error-text-red {
      color: $color-red;
    }
  }

  .first-title {
    margin-top: -10px;
  }

  .form {
    label,
    .like-label {
      font-size: $size-md;
      font-weight: 300;
      color: $color-text-light;

      .Select,
      .datepicker-block {
        margin-top: 5px;
      }
    }

    .input-group {
      margin-top: 5px;
    }

    textarea {
      height: 102px !important;

      &.height--md {
        height: 67px !important;
      }
    }
  }

  .line {
    background: $color-grey2;
    height: 1px;
    margin: 20px -40px 30px;
    width: auto;
  }

  .button__cancel,
  .button__save,
  .button__remove,
  .button__remove__all {
    @extend .button__sm;

    margin: 0 8px;
    min-width: 120px;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.justify-right {
      justify-content: flex-end;
    }

    &.single {
      justify-content: center !important;
    }

    .button__cancel,
    .button__save,
    .button__remove,
    .button__remove__all {
      margin: 0;
    }

    .button__save,
    .button__remove,
    .button__remove__all {
      font-weight: 500;
    }
  }
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.justify-right {
    justify-content: flex-end;
  }

  .button__cancel,
  .button__save,
  .button__remove,
  .button__remove__all {
    margin: 0;
  }

  .button__save,
  .button__remove,
  .button__remove__all {
    font-weight: 500;
  }

  .button__cancel,
  .button__save,
  .button__remove,
  .button__remove__all {
    @extend .button__sm;

    margin: 0 8px;
    min-width: 120px;
  }
}

.modal--flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal--sm {
  width: 450px;
}

.modal--lg {
  width: 700px;
}

.modal--xlg {
  width: 720px;
}

.modal--big {
  width: 800px;

  .locations--left {
    width: 250px;
  }
}

.modal--single--text {
  padding: 20px 0 35px;
  font-weight: 500;

  &.just--text {
    font-size: 14px;
    padding: 10px 0 5px;
  }
}

.modal--scroll {
  max-height: 350px;
  overflow: auto;
  margin-right: -40px;
  padding-right: 40px;
}

.modal-overflow {
  overflow: hidden;
  position: relative;

  .datepicker-block {
    cursor: pointer;
    flex-flow: row wrap;

    .datepicker-position {
      input {
        &:focus {
          cursor: pointer;
          border-color: $color-link;
          box-shadow: inset 0 0 2px $color-link;

          + .icon-calendar {
            color: $color-link;
          }
        }
      }
    }

    .error-line {
      width: 100%;
      max-width: none;
    }
  }
}

.modal-overflow-in {
  overflow: hidden;
  position: relative;
  padding: 0 0 30px;
  margin: 0 -40px -30px;

  .search-block-category {
    .search-input {
      padding: 5px 40px 5px 15px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid $color-grey3 !important;
      min-height: 42px;
      height: auto;
      line-height: 1.5;
      text-align: left;

      &:hover {
        background: $white;
        color: $color-text;
        border-color: $color-grey3 !important;
      }

      &:focus,
      &:active {
        background: $white;
        border-color: $color-link !important;
        color: $color-text;

        + .input-group-button {
          color: $color-link;
        }
      }
    }
  }
}

.modal-move {
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  width: 100%;
  padding: 0 40px;
}

.modal-datepicker {
  .datepicker-dropdowns--flex {
    margin: 0;
    padding: 15px 0 0;
    border: none;
    text-align: center;
  }

  .datepicker--buttons {
    border: none;
    margin: 0 auto;
    padding: 20px 0 0;
    width: 130px;

    .button {
      height: 36px;
      padding: 10px 12px;
      font-weight: 500;
    }
  }
}

.modal-machines {
  .form {
    .search-block-category {
      padding: 0;

      .search-text {
        margin-bottom: 23px;
      }
    }
  }

  .search-popup {
    position: relative;
    top: 0;
    right: auto;
    left: 0;
    box-shadow: none;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;

    &:before,
    &:after {
      display: none;
    }

    .search-item-text {
      font-weight: 500;
      color: $color-text-light;
    }

    .search-popup-list-more {
      font-weight: 500;
    }

    .button_checked,
    .button_uncheck {
      + .search-item-text {
        color: $color-text;
      }
    }
  }
}

.modal-datepicker,
.modal-machines,
.modal-datapoint,
.modal-datapoint-select,
.modal-roles,
.modal-users,
.modal-locations,
.modal-locations-select,
.modal-filters {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  position: absolute;
  left: 0;
  top: 0;

  .modal-form-in {
    min-height: 395px;
  }
}

.modal-datepicker {
  .modal-form-in {
    min-height: 386px;
  }
}

.modal-roles {
  .modal-form-in {
    min-height: 270px;
  }
}

.modal-locations,
.modal-users,
.modal-locations-select {
  .modal-form-in {
    min-height: 285px;
  }
}

.modal-datapoint {
  .modal-form-in {
    min-height: 304px;
  }

  .locations-block {
    margin-top: 0;
  }
}

.modal-filters {
  .modal-form-in {
    min-height: 386px;

    > .search-alls {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.modal-datapoint-select {
  .locations-block {
    margin-top: 0;
  }

  .locations--left,
  .locations--right {
    height: 384px;
  }
}

.modal-show-datepicker,
.modal-show-machines,
.modal-show-datapoint,
.modal-show-filters {
  min-height: 550px;

  .modal-main {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    position: absolute;
  }
}

.modal-show-datepicker {
  .modal-datepicker {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-machines {
  .modal-machines {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-datapoint {
  .modal-datapoint {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-datapoint-select {
  .modal-main,
  .modal-datapoint {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    position: absolute;
  }

  .modal-datapoint-select {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-roles {
  .modal-main {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    position: absolute;
  }

  .modal-roles {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-locations {
  .modal-main {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    position: absolute;
  }

  .modal-locations {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-users {
  .modal-main {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    position: absolute;
  }

  .modal-users {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-locations-select {
  .modal-main,
  .modal-users,
  .modal-locations {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    position: absolute;
  }

  .modal-locations-select {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal-show-filters {
  .modal-main {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    position: absolute;
  }

  .modal-filters {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: relative;
  }
}

.modal--subtitle {
  font-size: 14px;
}

.list-scroll {
  max-height: 320px;
  overflow: auto;
  padding-bottom: 10px;

  @include scroller;
}

.data-point-modal-display-container {
  overflow: auto;
  max-height: 350px;
}

.bulk-operation-summary-table-row > td {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 0;
}

.modal-action-content {
  height: '100%';
  width: '100%';
}

label:not(.radio):has(input:required)::before {
  content: ' * ';
  color: rgb(77, 77, 77);
  margin-left: 0.25em;
}

label:has([id^='react-select-'][id$='-input'][aria-required='true'])::before {
  content: '* ';
  color: rgb(77, 77, 77);
  margin-right: 0.25em;
}
