.Select-arrow-zone {
  padding: 5px 0 0;
  text-align: center;
  font-size: 1rem;
  width: 32px;
}

.select-disabled {
  .Select-arrow-zone {
    display: none;
  }
}

.Select-arrow {
  border: none;
  color: $color-link;
  width: auto;

  &:after {
    content: "\e958";
    font-family: "datadash" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.Select {
  cursor: pointer;

  &.is-focused {
    .Select-control {
      border-color: $color-grey2;
    }

    &:not(.is-open) > .Select-control {
      border-color: $color-link;
    }
  }

  &.is-open {
    .Select-control {
      border-color: $color-link;
    }

    .Select-arrow {
      &:after {
        content: "\e959";
      }
    }
  }

  &.error {
    .Select-control {
      border-color: $color-red;
    }
  }
}

.Select-control {
  border-color: $color-grey2;
  position: relative;
  border-radius: 4px !important;

  .inline-fields--right & {
    border-radius: 0 4px 4px 0 !important;
  }
}

.Select-option {
  padding: 9px 20px;

  &:first-child {
    border-radius: 4px 4px 0 0;
    padding-top: 11px !important;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
    padding-bottom: 11px !important;
  }

  &.is-focused {
    background: $color-link-light2;
  }

  &.is-selected {
    color: $color-link;
    background: none;
  }
}

.Select-menu-outer {
  margin: 0 0 10px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
}

.select--inline {
  display: inline-block;
  height: auto;
  width: auto;
  margin: -5px 0 -5px 5px;

  &:first-child {
    margin-left: 0;

    .Select-control {
      font-weight: 500;
    }
  }

  .Select-control {
    display: flex;
    height: 28px;
    font-weight: 600;
    border-color: transparent;

    &:hover {
      .Select-value {
        .Select-value-label {
          color: darken($color-link, 10%) !important;
        }
      }

      .Select-arrow {
        color: darken($color-link, 10%) !important;
      }
    }
  }

  .Select-multi-value-wrapper {
    flex: 1;
    display: block;
    height: 28px;
    cursor: pointer;
  }

  .Select-arrow-zone {
    flex: 0 auto;
    display: block;
    font-size: 0;
    padding: 0;
    width: 10px;
  }

  .Select-arrow {
    &:after {
      line-height: 26px;
      font-size: 13px;
      content: "\f0d7";
      font-family: FontAwesome !important;
      margin-left: 2px;
    }
  }

  .Select-placeholder,
  &.Select--single > .Select-control .Select-value {
    position: relative;
    line-height: 26px;
    padding: 0;
  }

  &.has-value {
    &,
    &.is-pseudo-focused {
      &.Select--single {
        > .Select-control {
          .Select-value {
            .Select-value-label {
              color: $color-link;
            }
          }
        }
      }
    }
  }
}

.Select-clear {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  margin-right: -8px;
}
