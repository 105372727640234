* {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: $mainfont;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

strong,
b {
  font-weight: 600;
}

p {
  padding: 0 0 14px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  border: none;
  border-width: 0;
  color: $color-link;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: darken($color-link, 10%);
  }

  *,
  &:active,
  &:focus {
    outline: none;
    -moz-outline: 0;
  }
}

button {
  *,
  &:hover,
  &:active,
  &:focus {
    outline: none;
    -moz-outline: 0;
  }
}

input,
textarea,
select {
  color: $color-text;
}

body {
  margin: 0;
  color: $color-text;
  font-family: $mainfont;
  font-size: $size-sm;
  line-height: 1.2;
  font-weight: 300;
  background: $color-background;
}

hr {
  border-bottom-color: $color-grey;
}

strong {
  font-weight: 600;
}

h1,
.h1 {
  font-weight: 600;
  font-size: $size-lg;
  color: $color-text;
  font-family: $mainfont;
}

h2,
.h2 {
  font-weight: 600;
  font-size: $size-md;
  color: $color-text;
  font-family: $mainfont;
}

h3,
.h3 {
  font-weight: 300;
  font-size: $size-sm;
  color: $color-text;
  font-family: $mainfont;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 300;
  font-size: $size-sm;
  color: $color-text;
  font-family: $mainfont;
}

.link {
  color: $color-link;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: darken($color-link, 10%);
  }
}

.link-text {
  color: $color-text;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $color-text;

    .fa,
    .icon {
      color: darken($color-link, 10%);
    }
  }

  .fa,
  .icon {
    color: $color-link;
  }
}

.disabled {
  color: $color-text-light;
  cursor: default;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: $color-text-light;
  }
}

.light {
  text-transform: uppercase;
  color: $color-text-light;
  font-size: $size-sm;
}

.small {
  color: $color-text-light;
  font-size: $size-sm;
}

.alert {
  color: $color-red;
  font-size: $size-sm;
}

.uppercase {
  text-transform: uppercase;
}

.normal {
  font-weight: normal;
}

.label {
  font-size: $size-sm;
  padding: 0 2px 0 7px;
  margin: 0 15px 0 0;
  line-height: 22px;
  border-radius: 5px 0 0 5px;
  position: relative;
  min-width: 60px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    right: -9px;
    top: 2px;
    width: 18px;
    height: 18px;

    @include rotate(45deg);

    border-radius: 5px;
  }

  &.alert {
    background: $color-red;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-red, 0.5);

    &:before {
      background: $color-red;
      box-shadow: 0 0 6px 0 rgba($color-red, 0.5);
    }

    span {
      background: $color-red;
    }
  }

  &.info {
    background: $color-label;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-label, 0.5);

    &:before {
      background: $color-label;
      box-shadow: 0 0 6px 0 rgba($color-label, 0.5);
    }

    span {
      background: $color-label;
    }
  }

  &.success {
    background: $color-green;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-green, 0.5);

    &:before {
      background: $color-green;
      box-shadow: 0 0 6px 0 rgba($color-green, 0.5);
    }

    span {
      background: $color-green;
    }
  }

  &.error {
    background: $color-orange;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);

    &:before {
      background: $color-orange;
      box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);
    }

    span {
      background: $color-orange;
    }
  }

  &.warning {
    background: $color-yellow;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-yellow, 0.5);

    &:before {
      background: $color-yellow;
      box-shadow: 0 0 6px 0 rgba($color-yellow, 0.5);
    }

    span {
      background: $color-yellow;
    }
  }

  &.secondary {
    background: $color-text-light;
    color: $color-white;
    box-shadow: 0 0 6px 0 rgba($color-text-light, 0.5);

    &:before {
      background: $color-text-light;
      box-shadow: 0 0 6px 0 rgba($color-text-light, 0.5);
    }

    span {
      background: $color-text-light;
    }
  }

  span {
    display: inline-block;
    vertical-align: top;
    position: relative;
    line-height: 20px;
    padding: 2px 0 0;
  }
}

.alert-info {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    background: $color-red;
    box-shadow: 0 0 6px 0 rgba($color-red, 0.5);
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}

.warning-info {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    background: $color-orange;
    box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}

.rel {
  position: relative;
}

.error-cross {
  position: absolute;
  right: 17px;
  top: 8px;
  color: $color-red;

  .input-group & {
    right: 46px;
  }
}

.wsn {
  white-space: nowrap;
}

.color-link {
  color: $color-link;
}

.color-orange {
  color: $color-orange;
}

.color-text {
  color: $color-text;
}

.fw-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}
