.data--top {
  padding-top: 9px;
}

.data--top--left {
  float: left;
  width: 45%;

  button {
    margin-right: 10px;
  }

  .light-grey-bordered {
    margin-right: 20px;
  }
}

.data--top--right {
  float: right;
  width: 45%;
  text-align: right;

  button {
    margin-left: 10px;
  }

  .light-grey-bordered {
    margin-left: 20px;
  }
}

.support-buttons {
  button {
    padding-left: 18px;
    padding-right: 18px;

    .icon-close {
      font-size: 10px;
    }

    .icon-checkmark {
      font-size: 10px;
    }
  }

  .grey {
    font-weight: 400;
  }
}

.filter-like--wrap {
  &.opened {
    .filter-opener-like {
      border-color: $color-grey3;
      position: relative;

      .icon-filter-funnel {
        color: $color-link;

        &:after {
          bottom: 2px;
          border-color: transparent transparent $color-grey2;
        }
      }
    }
  }
}

.filter-opener-like {
  line-height: 13px;
  display: flex;
  align-items: center;

  .icon-filter-funnel {
    font-size: 14px;
    position: relative;
    margin-right: 16px;
    -webkit-transition:
      background-color 0.25s ease-out,
      color 0.25s ease-out;
    transition:
      background-color 0.25s ease-out,
      color 0.25s ease-out;

    &:after {
      content: "";
      position: absolute;
      right: -5px;
      bottom: -3px;
      border-width: 5px 4px;
      border-style: solid;
      border-color: $color-grey2 transparent transparent;
    }
  }

  .fa,
  .icon {
    color: $color-link !important;
  }

  &.passive {
    color: $color-grey2;
    cursor: pointer;

    .icon-filter-funnel {
      color: $color-grey2 !important;
    }
  }
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.td-data-title {
  .maintenances--title {
    font-weight: 500;

    [class^="icon-"],
    [class*=" icon-"] {
      margin-left: 7px;
      font-size: 10px;
      color: $color-link;
      cursor: pointer;
      vertical-align: top;
      display: inline-block;
      margin-top: 2px;

      &:hover {
        color: darken($color-link, 10%);
      }
    }
  }

  .data-number {
    color: $color-grey;

    .passive & {
      color: $color-text-light;
    }
  }
}

.button-group--dropdown {
  .filter-action {
    display: block !important;
    padding: 9px 14px;
    color: $color-text;
    white-space: nowrap;
    font-size: $size-md;

    &:hover {
      background: $color-link-light2;
    }
  }

  .filter--checked {
    .filter-action {
      padding: 7px 14px 7px 21px;
      border-radius: 0 !important;
    }

    .active {
      .filter-action {
        position: relative;
        color: $color-link;

        &:after {
          font-size: 7px;
          content: "\e924";
          color: $color-link;
          position: absolute;
          left: 6px;
          top: 50%;
          margin-top: -4px;
          font-family: "datadash" !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
}

.data-table {
  .button-group__with-dropdown {
    display: none;
  }

  .td-check {
    display: none;
  }

  .td-data-title {
    .maintenances--title {
      [class^="icon-"],
      [class*=" icon-"] {
        display: none;
      }
    }
  }
}

.data--non-editable {
  display: block;
}

button,
.button {
  &.data--non-editable {
    display: inline-block;
  }
}

.data--editable {
  display: none;
}

.data--input {
  height: 26px;
  padding: 3px 5px;
}

.data--edit {
  .data-table {
    .td-check {
      display: table-cell;
    }

    .button-group__with-dropdown {
      display: block;
      margin: -2px 0;
    }

    .td-data-title {
      .maintenances--title {
        [class^="icon-"],
        [class*=" icon-"] {
          display: inline-block;
          visibility: hidden;
        }
      }
    }

    tr {
      &:hover {
        .td-data-title {
          .maintenances--title {
            [class^="icon-"],
            [class*=" icon-"] {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .data--non-editable {
    display: none;
  }

  .data--editable {
    display: block;
  }

  button,
  .button {
    &.data--editable {
      display: inline-block;
    }
  }

  .datatron--top {
    .datatron--title {
      .icon-edit {
        display: inline-block;
      }
    }
  }

  .table-hover {
    tbody {
      tr {
        &:hover {
          background: #f2fafa !important;
        }
      }

      .tr-checked {
        background: $color-link-light2 !important;

        &:hover {
          background: $color-link-light2 !important;
        }
      }
    }
  }

  &.datatron--position {
    .add--line {
      display: block;
    }
  }
}

.add--line {
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  margin: 0 -20px;

  .link {
    color: $color-text;
    font-weight: 500;
    display: block;
    padding: 11px 20px;
    width: 100%;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      color: $color-text;
      background: #fcfcfc;

      .icon-plus,
      .icon-reload-clockwise {
        color: darken($color-link, 10%);
      }
    }

    .icon-plus,
    .icon-reload-clockwise {
      margin-right: 15px;
      color: $color-link;
    }
  }
}
