.close-archive {
  @extend .h1;

  [class^="icon-"],
  [class*=" icon-"] {
    margin: 0 5px 0 0;
    font-size: $size-md;
  }
}

.archives--wrap {
  margin: 20px -20px 0 0;

  .maintenance--col--in {
    min-height: calc(100vh - 158px);
  }
}

.archives--cols {
  margin: 0 -10px;
}

.archives {
  @include grid-row(12, collapse, 100%);
  @include grid-layout(2, ".archives--col");

  @include breakpoint(small down) {
    @include grid-layout(1, ".archives--col");
  }

  @include breakpoint(medium up) {
    @include grid-layout(2, ".archives--col");
  }

  @include breakpoint(large up) {
    @include grid-layout(3, ".archives--col");
  }

  @include breakpoint(xxlarge up) {
    @include grid-layout(3, ".archives--col");
  }
}

.archives--col {
  @include grid-column(12, 20px);
  @include grid-column-end;
}
