.datatron--position {
  position: relative;
  margin: 0 0 15px;

  .td-online {
    width: auto;
    padding-right: 20px;
    text-align: center;
  }

  .td-check {
    + td {
      padding-left: 20px;
    }
  }

  .flex-el {
    justify-content: center;
    text-align: center;
    font-size: 13px;
    color: $color-text-light;
    padding: 40px 40px 30px;

    a {
      text-decoration: underline;
    }

    p {
      padding: 0;
    }
  }

  .data-empty-icon {
    display: flex;
    align-items: center;
    margin: 0 auto 15px;

    > object,
    img {
      margin: 0 auto;
      height: 36px;
    }
  }

  + .add--line {
    margin-top: -15px;
  }

  .data-table {
    tr {
      position: relative;
    }

    td {
      vertical-align: middle;
    }

    .button-group__with-dropdown {
      .button__dropdown {
        min-width: 82px;
        text-align: left;
        padding-right: 20px;

        &:before {
          right: 19px;
        }

        &:after {
          right: 5px;
          top: 10px;
        }
      }
    }
  }

  .tab-menu {
    background: $color-white;
  }

  .big-tabs-scroll {
    height: 30px;
  }

  .big-tab-in {
    min-height: 28px;
  }

  .big-tab {
    &:after {
      display: none;
    }

    &.active {
      &:after {
        display: block;
      }
    }
  }
}

.datatron--top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-right-aside;
  padding: 15px 0 10px;

  .datatron--title {
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    margin: 0;

    .icon-cogs {
      width: 15px;
      text-align: center;
      display: inline-block;
      font-size: 18px;
      color: $color-grey2;
      margin: -2px 8px 0 0;
    }

    .icon-edit {
      margin-left: 7px;
      font-size: 12px;
      color: $color-link;
      cursor: pointer;

      &:hover {
        color: darken($color-link, 10%);
      }
    }

    .input-group {
      margin: 0;
    }

    .error-line {
      float: none;
      position: absolute;
      left: 100%;
      top: 0;
      line-height: 32px;
      margin: -4px 0 0 5px;
    }
  }

  .button__toggle {
    .icon-caret-down,
    .icon-caret-left {
      display: inline-block;
    }
  }

  .light-grey-bordered {
    min-width: 29px;
    font-size: 18px;
    padding-bottom: 10px;
  }

  .filter--block {
    margin: 0;

    .filter--dropdown {
      left: auto;
      right: 0;
      top: 110%;
      min-width: 0;

      ul {
        line-height: 1.2;
      }
    }

    &.opened {
      .filter--dropdown {
        visibility: visible;
      }

      .button {
        color: $color-link;
        box-shadow: inset 0 1px 2px 0 rgba($color-black, 0.2);
      }
    }
  }

  .support-buttons {
    margin: 0;

    button {
      margin-left: 10px;
      height: 28px;
      min-width: 80px;
      line-height: 1;
      font-weight: 400;
      font-size: 12px;
      padding: 0 8px;

      .icon-close {
        font-size: 9px;
      }

      .icon-checkmark {
        font-size: 9px;
      }
    }
  }

  .filter--vertical--points {
    .filter--dropdown {
      a {
        padding-right: 20px;
      }
    }
  }
}

.datatron--title--input {
  height: 32px;
  width: 320px;
  font-size: 13px;
  margin: -4px 0;
  padding: 5px 10px;

  @include breakpoint(large down) {
    width: 220px;
  }

  &:focus {
    box-shadow: 0 0 3px 0 rgba($color-link, 0.7);
  }
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 0 -20px;

  .form-item {
    width: 140px;
    margin: 0 0 0 20px;
  }

  .form-item-large {
    width: auto;
    flex: 1;
  }
}

.data--buttons--bottom {
  text-align: right;

  .button {
    margin: 0 8px;
    min-width: 75px;
  }

  .d-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.device-details {
  padding: 15px 0 20px;

  @include column(3);
  @include column-gap(10px);

  @include breakpoint(medium down) {
    @include column(2);
  }

  @media (max-width: 750px) {
    @include column(1);
  }
}

.device-detail {
  display: flex;
  width: 100%;
  padding: 2px 0;
}

.device-detail-label {
  flex: 0 auto;
  font-weight: 600;
  min-width: 130px;
}

.device-detail-text {
  flex: 1;
}

.data-points-line {
  display: flex;
  font-size: 12px;
  line-height: 15px;
  margin: 0 -20px;
  padding: 6px 20px;

  &.highlight {
    -webkit-animation: row-highlight 1s linear;
    animation: row-highlight 1s linear;
  }

  .form {
    padding: 10px 0;
    width: 100%;
  }
}

.data-points-line-dark {
  background-color: $color-white-dark;
}

.data-points-params {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
}

.data-points-param {
  display: inline-flex;
  width: 25%;
  padding: 6px 0;
  font-weight: 500;

  @include breakpoint(medium down) {
    width: 33.3%;
  }

  @media (max-width: 750px) {
    width: 50%;
  }

  h4.first-title + & {
    width: auto;

    @include breakpoint(medium down) {
      width: auto;
    }

    @media (max-width: 750px) {
      width: auto;
    }
  }
}

.data-points-param-50 {
  width: 50%;

  @include breakpoint(medium down) {
    width: 100%;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
}

.data-points-param-75 {
  width: 50%;

  @include breakpoint(medium down) {
    width: 100%;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
}

.data-points-param-100 {
  width: 100%;

  @include breakpoint(medium down) {
    width: 100%;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
}

.data-points-params2 {
  .data-points-param,
  .data-points-param-50 {
    width: 50%;

    @include breakpoint(medium down) {
      width: 50%;
    }

    @media (max-width: 750px) {
      width: 50%;
    }
  }

  .data-points-param-75,
  .data-points-param-100 {
    width: 100%;

    @include breakpoint(medium down) {
      width: 100%;
    }

    @media (max-width: 750px) {
      width: 100%;
    }
  }
}

.data-points-label {
  min-width: 80px;
  margin-right: 12px;
  color: $color-grey4;
  flex: 0 auto;

  &.no-min-width {
    min-width: 0;
  }
}

.data-points-label-in {
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
}

.data-points-actions {
  flex: 0 auto;
  padding: 6px 0;
  width: 85px;
  margin-left: 20px;

  .button {
    width: 24px;
    height: 24px;
    font-size: 10px;
    margin-right: 5px;
    padding: 5px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin: 0;
    }
  }
}

.data-points-actions.unarchive {
  .button.light-grey-white {
    i.fa.fa-undo {
      font-size: 12px;
      margin: 0;
    }
  }
}

.mr-1 {
  margin-right: 5px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.margin-vertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.split-border {
  border-right: 2px solid #ccc;
  margin-left: 10px;
  height: 20px;
}
.data-points-modal-table {
  margin: 10px -40px;

  table {
    width: 538px;
    border-spacing: 0;

    th,
    td {
      &:first-child {
        padding-left: 40px;
      }

      &:last-child {
        padding-right: 40px;
      }
    }

    tbody,
    thead tr {
      display: block;
    }

    tbody {
      max-height: 228px;
      overflow-y: scroll;
      overflow-x: hidden;

      @include scroller;

      td {
        &:first-child {
          width: 370px;
        }

        &:last-child {
          width: 159px;

          @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
            width: 168px;
          }
        }
      }
    }

    thead {
      td,
      th {
        &:first-child {
          width: 370px;
        }

        &:last-child {
          width: 175px;
        }
      }
    }
  }

  .nodata {
    position: relative;
    left: 0;
    top: 0;
    margin: 20px 0 0;
  }
}

.timer-button {
  color: $color-text-light;
  display: inline-block;
  height: 24px;
  width: 26px;
  border: 1px solid transparent !important;
  border-right: none;
  text-align: center;
  margin: -7px 0 -7px 5px;
  font-size: 14px;
  vertical-align: middle;

  &:hover {
    color: $color-link;

    .icon-counterclockwise {
      color: $color-link;
    }
  }
}

.highlight-action-text {
  width: 65px;
  color: #8f9296;
  font-weight: 600;
}

.highlight-action-text.selected {
  color: #3a3b3d;
  transition: color 180ms transform ease-in-out;
  font-weight: 600;
}

:root {
  --form-control-color: #8f9296;
}

.data-point-checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 0.12em solid #a9abae;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.data-point-checkbox::before {
  content: '';
  width: 0.95em;
  height: 0.95em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 180ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
}

.data-point-checkbox:checked::before {
  transform: scale(1);
}
.data-point-checkbox:disabled {
  cursor: not-allowed;
}

.data-point-select-all-text-action {
  cursor: pointer;
}

.data-point-select-all-text-action:hover .data-point-checkbox {
  border: 0.15em solid #8e8e8f;
}

.data-point-select-all-text-action .flex-name-label {
  font-weight: 600;
  color: #8f9296;
}

.data-point-select-all-text-action:hover .flex-name-label {
  text-decoration: underline;
  color: #656668;
  transition: color 180ms transform ease-in-out;
}

.data-point-checkbox:checked + .flex-name-label {
  color: #3a3b3d;
}

.data-point-select-all-text-action[aria-disabled='true'] {
  cursor: not-allowed;
  pointer-events: none;
}

.data-point-action-label {
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.data-point-radio {
  transform: scale(1.2);
  transform-origin: left;
}

.data-point-radio-label {
  font-size: 1.1em;
}

.data-point-modal-display-container {
  max-height: 30vh;
  overflow: auto;
}
