.inventory-block {
  border-bottom: 1px solid rgba($color-text, 0.2);
  margin: 0 0 30px;
  padding: 0 0 10px;

  &:last-child {
    border-bottom: none;
  }
}

.inventory-wrap {
  margin: 0 -10px;
}

.inventory {
  display: flex;
  flex-flow: row wrap;
}

.inventory--col {
  width: 334px;
  padding: 0 10px;
}

.inventory--item {
  word-wrap: break-word;
  word-break: break-all;
  background: $color-white;
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  height: 75px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(220, 222, 223, 0.09);
  padding: 10px 16px;
  color: $color-text;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    right: -1px;
    top: -1px;
    border-width: 13px;
    border-style: solid;
    border-color: transparent;
    border-radius: 0 2px 0 0;
  }

  &:hover,
  &:focus {
    .inventory--item--title {
      text-decoration: underline;
      color: $color-text;
    }

    .inventory--item--content {
      color: $color-text-light;
    }
  }

  &.alert {
    background: #f3f6f8;

    &:after {
      content: "!";
      position: absolute;
      right: 4px;
      top: 1px;
      font-weight: 700;
      color: $color-white;
    }

    &:before {
      border-top-color: $color-red;
      border-right-color: $color-red;
    }

    .inventory--item--title {
      color: $color-text-light;
    }

    .info-dialog--text {
      color: $color-text-light;
    }
  }

  &.active {
    border-color: $color-blue;
    background: #fafafa;

    .inventory--item--title {
      color: $color-blue;
    }
  }

  > div {
    width: 100%;
  }

  .info-dialog--item {
    padding: 0;
  }

  .info-dialog--text {
    color: $color-text;
  }
}

.inventory--item--add {
  display: none;
  background: none;
  box-shadow: none;
  border: 2px dashed $color-grey2;
  color: $color-text-light;
  text-align: center;
  font-size: $size-md;

  .fa,
  .icon {
    color: $color-green;
    margin: 0 5px 0 0;
  }

  &:hover {
    color: $color-text;
  }
}

.inventory--item--title {
  font-size: $size-md;
  font-weight: 600;
  padding: 3px 0;
}

.inventory--item--content {
  font-size: $size-sm;
  color: $color-text-light;
  padding: 3px 0;
}

.title {
  font-size: $size-md;
  padding: 0 0 5px;

  .links {
    padding: 0 0 2px;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    margin-top: 0;
  }
}
