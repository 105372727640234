.infos {
  line-height: 20px;
  word-wrap: break-word;
  position: relative;
  font-size: $size-md;
  margin: 0 0 20px;

  strong {
    display: block;
  }

  h4,
  .h4 {
    font-size: $size-md;
    padding: 0 0 5px;
    font-weight: 600;
  }

  p {
    padding-bottom: 0;
  }

  .link,
  .links {
    padding: 0 0 5px;
  }
}

.graph-medium {
  padding: 0 0 20px;

  img {
    display: block;
    width: 100%;
  }

  .nodata {
    margin-top: -7px;
  }
}

.infos-line {
  padding: 0 0 14px 23px;
  position: relative;

  .icon,
  .fa {
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    text-align: center;
    margin: 3px 0 0;
    font-size: $size-md;
  }

  .fa {
    top: -1px;
  }

  .links {
    &:last-child {
      padding: 0;
    }
  }

  .color-grey-light {
    color: $color-text-light;
  }
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  margin-top: -3px;

  h5 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
  }

  .m-left-10 {
    margin-left: 10px;
  }

  .button {
    min-width: 0;
    font-size: 12px;
    color: $color-text-light;
    display: flex;
    align-items: center;
    padding-right: 11px;
    padding-left: 11px;

    .aside-popup & {
      display: none;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      font-size: 13px;
      color: $color-text-light;
      margin: -2px 7px 0 0;
    }
  }

  .card-col-c & {
    display: none;
  }
}

.inventory-tab--in {
  .card-cols {
    display: flex;
    align-items: center;
    margin: -5px -20px;
    flex-flow: row wrap;
  }

  .card-top {
    display: flex;
    justify-content: flex-start;

    h5 {
      margin-right: 10px;
    }
  }

  .card-col-lc-wrap {
    margin-right: 40px;
    flex: 1;

    @media (max-width: 1200px) {
      margin-right: 30px;
    }
  }

  .card-col-lc {
    display: flex;
    align-items: center;
  }

  .card-col-l {
    width: 192px;
    flex: 0 auto;
    margin-right: 40px;

    @media (max-width: 1200px) {
      margin-right: 20px;
    }
  }

  .card-col-c {
    min-width: 220px;
    flex: 1;

    .line {
      display: none;
    }

    .infos {
      display: none;
    }

    .line {
      display: none;
    }

    .indicators-info {
      h1,
      .h1 {
        display: none;
      }
    }

    .round-charts-center {
      .round-chart--item {
        width: 25%;

        @media (max-width: 1200px) {
          width: 50%;
        }
      }

      .round-chart--item {
        p {
          @media (max-width: 1200px) {
            min-height: 0;
          }
        }
      }
    }
  }

  .card-col-r {
    min-width: 360px;
    max-width: 580px;
    flex: 1;
    margin-top: -15px;
    align-items: flex-start;

    @media (max-width: 900px) {
      max-width: none;
    }

    @media (max-width: 1200px) {
      align-items: center;
    }

    .graph-medium {
      padding: 0;
    }

    .tabs-panel {
      height: 230px !important;
    }
  }
}
