.report-legend {
  margin: 0 -20px;
  padding: 13px 0 0;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  color: $color-text-light;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 3px 0;
    display: flex;
    align-items: flex-start;

    &.archived {
      color: $color-grey2;

      .btn-link {
        opacity: 0;
      }

      .link-text {
        color: $color-grey2;

        &:hover {
          .icon-remove {
            color: $color-link;
          }
        }

        .icon-remove {
          color: $color-grey2;

          + span {
            text-decoration-line: line-through;
          }
        }
      }
    }
  }

  .report-editor {
    text-align: left;
  }

  .report-watcher {
    height: 15px;
    margin-top: -2px;
  }

  .link-text {
    display: flex;
    align-items: center;
    color: $color-text-light;

    &:hover {
      color: $color-link;

      [class^="icon-"],
      [class*=" icon-"] {
        color: $color-link;
      }
    }

    + .link-text {
      margin: 0 0 0 10px;
    }

    .icon-edit {
      margin: 0 5px 0 0;
      color: $color-text-light;
      font-size: 12px;
      align-self: flex-start;
    }

    .icon-remove {
      color: $color-text-light;
      margin: -1px 7px 0 -1px;
      font-size: 11px;
    }

    .icon-eye {
      margin: 0 0 0 5px;
      color: $color-text-light;
      font-size: 12px;
    }
  }

  .active {
    color: $color-text;

    .link-text {
      color: $color-text;

      &:hover {
        color: $color-link;
      }
    }

    .rectangle {
      opacity: 1;
    }

    .report-watcher {
      [class^="icon-"],
      [class*=" icon-"] {
        color: $color-link;
      }
    }
  }
}

.report-legend-col {
  flex: 1;
  padding: 0 20px;
}

.report-legend-right {
  li {
    justify-content: flex-end;
  }

  .link-text {
    + .link-text {
      margin: 0 10px 0 0;
    }
  }

  .report-watcher {
    order: 2;

    .rectangle {
      order: 2;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      order: 1;
      margin: 0 5px 0 0;
    }
  }

  .report-editor {
    order: 1;

    span {
      order: 1;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      order: 2;
      margin: 0 0 0 5px;
    }
  }
}

.rectangle {
  display: inline-block;
  height: 8px;
  width: 16px;
  border: 2px solid transparent;
  border-radius: 2px;
  opacity: 0.25;

  .Select & {
    opacity: 1;
    margin-right: 5px;
  }
}
