.assistance--item {
  display: flex;
  border-bottom: 1px solid $color-right-aside;
  padding: 15px 0 18px;
  margin: 0 0 10px;
  font-size: $size-md;
  line-height: 1.4;

  &:last-child {
    border-bottom: none;
  }
}

.assistance--icon {
  flex: 0 auto;
  padding: 0 22px 0 12px;
}

.assistance--icon--wrap {
  background: #fafafa;
  border-radius: 50%;
  display: inline-block;
  width: 42px;
  height: 42px;
  border: 2px solid $color-white;
  font-size: 1rem;
  text-align: center;
  line-height: 38px;
}

.assistance--name {
  flex: 1;
  padding: 0 22px 0 12px;

  p {
    padding: 0 0 5px;
    line-height: 1.4;

    &:last-child {
      padding: 0;
    }
  }
}

.assistance--contacts {
  flex: 1;
  min-width: 33%;
  padding: 0 22px 0 12px;
}

.assistance--contact {
  display: flex;
  width: 100%;
  padding: 0 0 5px;

  &:last-child {
    padding: 0;
  }

  .icon,
  .fa {
    width: 14px;
    text-align: center;
    font-size: 11px;
    margin: 0 10px 0 0;
    color: $color-grey2;
    padding-top: 2px;
  }

  .icon-comment {
    padding-top: 3px;
  }

  .fa {
    font-size: $size-sm;
  }
}

.assistance--remove {
  flex: 0 auto;
  width: 24px;
  height: 24px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
