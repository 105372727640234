.subheader {
  margin: 0 -20px 0;
  height: 56px;
  position: relative;
  background: $color-white;

  .central {
    display: flex;
  }
}

.subheader-in {
  height: 56px;
  background: $color-white;
  position: fixed;
  top: 90px;
  padding: 0 20px;
  z-index: 998;
  display: flex;
  align-items: center;
}

.subheader--left {
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.subheader--right {
  flex: 0 auto;
}

.subheader--with--center {
  .subheader--left {
    flex: 0 auto;
    z-index: 2;
  }

  .subheader--center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    z-index: 1;

    .tab-menu {
      display: inline-block;
      vertical-align: top;
    }
  }
}

.tab-menu {
  font-size: $size-md;
  line-height: 56px;
  color: $color-text;
  font-weight: 600;
  flex: 0 auto;
  white-space: nowrap;

  .fa,
  [class^='icon-'],
  [class*=' icon-'] {
    margin: 0 5px 0 0;
    color: $color-link;
  }

  .fa-arrow-left {
    margin-top: -4px;
  }

  h1,
  .h1 {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0 40px 0 0;
    display: flex;
    align-items: center;

    &:last-child {
      padding: 0;
    }

    a {
      font-size: $size-md;
      height: 56px;
      padding: 0;
      line-height: 56px;
      position: relative;
      color: $color-text-light;
      text-decoration: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: transparent;
      }

      &:hover {
        color: $color-text;

        &:before {
          background: $color-link-light;
        }
      }
    }
  }

  .active,
  .is-active {
    a {
      color: $color-text;

      &:before {
        background: $color-link;
      }
    }
  }

  .passive {
    color: $color-grey2;

    a {
      color: $color-grey2;
      cursor: default;

      &:hover {
        color: $color-grey2;

        &:before {
          background: transparent;
        }
      }
    }

    .fa,
    [class^='icon-'],
    [class*=' icon-'] {
      color: $color-grey2;
    }
  }

  .back-link {
    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $color-text;

      &:before {
        display: none !important;
      }

      &:hover {
        color: $color-link;
      }

      .icon-arrow-left {
        font-size: 12px;
        margin-right: 11px;
      }
    }
  }

  &.right {
    float: right;
  }
}

.menu-tags {
  vertical-align: top;
  white-space: nowrap;

  li,
  .filter--block {
    padding: 0 10px 0 0;
    display: inline-block;
    vertical-align: top;
    margin: 0;

    .filter--dropdown {
      top: 100%;

      > ul {
        margin: 0;
        padding: 0;
      }

      .search-inline {
        width: 100%;
      }
    }
  }

  .filter--btn {
    @extend .button;
    @extend .white;

    display: inline-flex;
    padding: 4px 11px 8px;
    font-size: 12px;
    border-radius: 3px;
    line-height: 19px;
    color: $color-link;
    background: $color-grey-light2;

    @-moz-document url-prefix() {
      padding: 7px 11px 5px;
    }

    &:hover {
      color: $color-link;
      background: darken($color-white, 5%);

      .fa,
      .icon {
        color: $color-link;
      }
    }

    &:focus,
    &:active {
      color: $color-link;
      background: darken($color-white, 5%);

      .fa,
      .icon {
        color: $color-link;
      }
    }

    span {
      vertical-align: top;
    }

    .fa {
      color: $color-link;
      font-size: $size-sm;
      line-height: 15px;
    }

    .icon {
      color: $color-link;
      font-size: $size-sm;
      line-height: 15px;
      -webkit-transition: color 0.5s ease-out;
      -moz-transition: color 0.5s ease-out;
      transition: color 0.5s ease-out;
    }

    .opened & {
      color: $color-link;
      background: $color-grey-light3;

      &:hover {
        background: $color-grey-light;
      }

      .fa,
      .icon {
        color: $color-link;
      }

      .label-num {
        background: $color-link;
      }
    }
  }

  &.white--btn {
    .filter--btn {
      background: $color-white !important;
      color: $color-text-light;
      font-weight: 400;
      font-size: 12px;
      margin-right: -10px;

      &:hover,
      &:active,
      &:focus {
        color: $color-link !important;
        background: $color-white !important;
      }
    }
  }

  .passive {
    .filter--btn {
      background: $color-white;
      color: $color-text-light;

      .fa,
      .icon {
        color: $color-text-light;
      }

      &:hover {
        color: $color-text-light;
        background: darken($color-white, 5%) !important;

        .fa,
        .icon {
          color: $color-text-light !important;
        }
      }
    }

    &.opened {
      .filter--btn {
        color: $color-text !important;
        background: $color-white-dark !important;
        box-shadow: inset 0 0 4px $color-link;
        border-color: $color-link;

        &:active,
        &:focus {
          color: $color-text !important;
          background: $color-white-dark !important;
          box-shadow: inset 0 0 4px $color-link;
          border-color: $color-link;

          .fa,
          .icon {
            color: $color-link !important;
          }

          .label-num {
            background: $color-link;
          }
        }

        .fa,
        .icon {
          color: $color-link !important;
        }

        .label-num {
          background: $color-link;
        }
      }
    }
  }
}

.menu-tag {
  @extend .button;
  @extend .white;

  font-size: $size-md;

  .fa,
  .icon {
    margin: 0 0 0 5px;
    font-size: $size-lg;
    line-height: 12px;
  }
}

.menu-tag-filter {
  @extend .button;
  @extend .light-grey;

  font-size: $size-md;

  .fa,
  .icon {
    color: $color-blue;
    font-size: $size-lg;
    line-height: 12px;
  }
}

.passive {
  color: $color-text-light;
  cursor: default;

  &:hover {
    color: $color-text-light;

    &:before {
      background: transparent;
    }
  }

  .fa,
  .icon {
    color: $color-text-light;
  }
}

.breadcrumps-menu {
  font-size: $size-md;
  color: $color-text;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 40px;
    top: 0;
    bottom: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#0ffff', endColorstr='#fff', GradientType=0);
  }

  li {
    position: relative;
    display: block;
    flex: 0 auto;
    white-space: nowrap;

    a {
      font-size: $size-md;
      padding: 0;
      position: relative;
      color: $color-text;
      text-decoration: none;

      &:before {
        content: '';
      }

      &:hover {
        color: $color-text;

        &:before {
          background: $color-link-light;
        }
      }
    }
  }

  .active {
    .breadcrumps-dropdown {
      display: block;
    }

    .breadcrumps-title {
      color: $color-text;

      .fa {
        color: $color-link;
      }

      .fa-chevron-right {
        display: none;
      }

      .fa-chevron-down {
        display: inline-block;
      }
    }
  }

  .tab-menu + & {
    margin-left: 60px;
  }
}

.breadcrumps-title {
  padding: 8px 17px 5px 8px;
  color: $color-text-light;
  position: relative;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: $color-text;
  }

  .fa {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -5px;
    color: $color-text-light;
  }

  .fa-chevron-down {
    display: none;
  }

  .fa-chevron-right {
    display: inline-block;
  }
}

span.breadcrumps-title,
div.breadcrumps-title {
  color: $color-text-light;
  cursor: default;

  &:hover {
    color: $color-text-light;
    cursor: pointer;
  }
}
div.read-only.breadcrumps-title {
  &:hover {
    cursor: default;
  }
}

.breadcrumps-home {
  padding: 8px 17px 5px 8px !important;
  color: $color-link !important;

  .icon-home {
    font-size: 16px;
  }
}

.breadcrumps-dropdown {
  text-align: left;
  display: none;
  min-width: 180px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  background: $color-white;
  padding: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: $size-md;

  ul {
    padding: 5px 0;
    margin: 0;
  }

  li {
    display: block;
    color: $color-text;
    padding: 6px 14px;

    &:hover {
      background: #fafafa;
    }
  }

  .none {
    color: $color-red;
  }
}

.menu-icons {
  margin-right: -10px;

  a {
    color: $color-text-light;
    padding: 10px;

    &:hover {
      color: $color-link;
    }
  }

  .active {
    a {
      color: $color-link;
      cursor: default;
    }
  }
}

.location-line-field {
  padding: 2px 5px 5px;
  background-color: #f0f2f5;
  display: block;
  height: auto;
  min-height: 42px;
  width: 100%;
  border-radius: 4px !important;

  &:focus {
    border-color: #d4d7dc;
    box-shadow: none;
    background: $color-right-aside;

    ~ .input-group-button {
      button {
        border-color: $color-link;
      }
    }
  }

  &.white {
    background: $color-white;

    &:hover {
      background: $color-grey-light2;
    }
  }

  .breadcrumps-title {
    padding-right: 10px;
    color: $color-text !important;
    font-weight: 400;
    cursor: pointer;

    .fa {
      font-size: 8px;
      margin-top: -4px;
      right: 0;
      color: $color-grey2;
    }

    .icon-location {
      color: $color-link !important;
    }
  }

  .breadcrumps-home {
    padding: 5px 1px 5px 8px !important;
  }

  .breadcrumps-menu {
    white-space: normal;
    flex-flow: row wrap;

    &:after {
      display: none;
    }

    li {
      &:last-child {
        .breadcrumps-title {
          color: $color-text;
        }
      }
    }
  }

  .error > & {
    border-color: $color-red;
  }
}
