.notifications {
  position: fixed;
  padding: 0 10px 10px;
  z-index: 997;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.notification {
  cursor: pointer;
  max-width: 600px;
  position: relative;
  border-radius: 4px;
  margin: 10px auto 0;
  padding: 10px 9px 8px;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.3s ease-in-out;
  right: 0;
  min-height: 50px;
  background: $color-white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  &:hover {
    border-color: $color-link;
  }
}

.notification__description {
  word-wrap: break-word;
  flex: 1;
  text-align: center;
  padding: 0 0 0 30px;
  position: relative;

  &:before {
    width: 30px;
    text-align: center;
    font-family: "datadash" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    top: 1px;

    .notification--red & {
      color: $color-red;
      content: "\e918";
    }

    .notification--green & {
      color: $color-green;
      content: "\e924";
    }
  }
}

.notification__title {
  font-size: 14px;
  margin: 0 0 5px;
  padding: 0;
  font-weight: bold;
}

.notification__close {
  flex: 0 auto;
  font-size: 0.6rem;
  opacity: 0.8;
  width: 30px;
  cursor: pointer;

  &:hover {
    opacity: 1;
    color: $color-red;
  }
}

.notifications-big {
  position: fixed;
  padding: 0 10px 10px;
  z-index: 997;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.notification-big {
  max-width: 600px;
  position: relative;
  border-radius: 4px;
  margin: 10px auto 0;
  padding: 11px 10px 9px;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.3s ease-in-out;
  right: 0;
  min-height: 50px;
  background: $color-white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
}

.notification-big--text {
  flex: 1;
  text-align: center;
  padding: 0 0 0 30px;
  position: relative;

  &:before {
    color: $color-green;
    content: "\e924";
    width: 30px;
    text-align: center;
    font-family: "datadash" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    top: 1px;
  }
}

.notification-big--close {
  flex: 0 auto;
  font-size: 0.6rem;
  opacity: 0.8;
  width: 30px;
  cursor: pointer;

  &:hover {
    opacity: 1;
    color: $color-red;
  }
}
