table {
  font-weight: 400;

  thead,
  tbody,
  tfoot {
    td {
      padding: 12px 15px;
      font-weight: 400;
      word-wrap: break-word;
    }
  }

  thead {
    border-color: transparent;

    td {
      color: $color-text-light;
    }

    .fa-globe {
      margin-left: 5px;
    }
  }

  thead,
  tfoot {
    background: none;
  }

  tbody {
    tr {
      background-color: $color-white-dark;

      &:nth-child(2n) {
        background: $color-white;
      }

      &.selected {
        background: $color-link-light2 !important;
      }

      &:hover {
        .td-edit-in {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .highlight {
    td {
      -webkit-animation: row-highlight 1s linear;
      animation: row-highlight 1s linear;
    }
  }
}

@-webkit-keyframes row-highlight {
  0% {
    background: #96b1e1;
  }

  50% {
    background: #c5d3ee;
  }

  70% {
    background: #e8eff9;
  }

  100% {
    background: transparent;
  }
}

@keyframes row-highlight {
  0% {
    background: #96b1e1;
  }

  50% {
    background: #c5d3ee;
  }

  70% {
    background: #e8eff9;
  }

  100% {
    background: transparent;
  }
}

.valign-middle {
  td {
    vertical-align: middle;
  }
}

.table-sm {
  thead,
  tbody,
  tfoot {
    border: none;

    tr {
      background: none;
    }

    td {
      padding: 7px 10px 7px 0;
      color: $color-text;
      font-weight: 400;
    }
  }

  thead {
    border-bottom: 1px solid $color-right-aside;
  }
}

.data-sources {
  h4,
  .h4 {
    font-size: $size-md;
    font-weight: 600;
    padding: 0 0 10px;

    .fa,
    .icon {
      color: $color-blue;
      margin: 0 5px 0 0;
    }
  }
}

.td-category {
  width: 100px;

  .menu-tags {
    padding: 0;
    margin: -11px -10px -11px -15px;
    border: none;
    box-shadow: none;
    background: none;
    border-radius: 0;

    .filter--btn {
      font-size: 12px;
      border: none;

      .label-num {
        //margin-top: -2px;
        background: $color-link;
      }
    }
  }

  .button {
    cursor: default !important;

    &.alert {
      background: $color-red !important;
      color: $color-white;
    }

    &.info {
      background: $color-link !important;
      color: $color-white;
    }

    &.success {
      background: $color-green !important;
      color: $color-white;
    }

    &.error {
      background: $color-orange !important;
      color: $color-white;
    }

    &.warning {
      background: $color-yellow !important;
      color: $color-white;
    }
  }
}

.td-amount {
  width: 90px;
}

.td-date {
  width: 90px;
  text-align: center;

  &.text-left {
    text-align: left;
  }
}

.td-time {
  width: 90px;
  text-align: center;
}

.td-comments {
  width: 70px;
  text-align: center;
}

.td-comment {
  word-break: break-all;
}

.td-action {
  width: 40px;
  text-align: center;
  padding-right: 15px;
  padding-left: 5px;
}

.tr-line-more {
  display: none;
}

.td-status {
  width: 100px;
}

.td-data-type {
  width: 100px;
}

.td-rate {
  width: 80px;
}

.td-activity {
  width: 150px;
}

.td-online {
  width: 8px;
  padding-left: 20px;
  padding-right: 0;

  + td {
    padding-left: 11px;
  }
}

.td-check {
  width: 14px;
  padding-right: 0;
}

.tr-line {
  &.opened {
    + .tr-line-more {
      display: table-row;
    }

    .button__toggle {
      .fa-chevron-down {
        display: inline-block;
      }

      .fa-chevron-left {
        display: none;
      }
    }
  }

  .button__toggle {
    .fa-chevron-down {
      display: none;
    }

    .fa-chevron-left {
      display: inline-block;
    }
  }
}

.table--cols {
  @include grid-row();
}

.table--col-text {
  @include grid-col(4);

  line-height: 1.4;
  color: $color-text-light;

  h1,
  .h1 {
    font-weight: 500;
    padding: 0 0 10px;
  }

  h3,
  .h3 {
    font-weight: 500;
    padding: 0 0 15px;
  }

  p {
    padding: 0 0 20px;
  }
}

.table--col-comments {
  @include grid-col(8);

  .feedbacks {
    padding: 0;
  }
}

.table-lined {
  tbody {
    tr {
      background-color: $color-white !important;

      .td-edit-in {
        background: $color-white;
        background: -moz-linear-gradient(
          left,
          rgba($color-white, 1) 0%,
          rgba($color-white, 1) 50%,
          rgba($color-white, 0) 100%
        );
        background: -webkit-linear-gradient(
          left,
          rgba($color-white, 1) 0%,
          rgba($color-white, 1) 50%,
          rgba($color-white, 0) 100%
        );
        background: linear-gradient(
          to right,
          rgba($color-white, 1) 0%,
          rgba($color-white, 1) 50%,
          rgba($color-white, 0) 100%
        );
      }

      .feedback-comment {
        background-color: #f1f2f4;
      }

      .feedback-comment--form {
        .input-group-field {
          background-color: #f1f2f4;
        }
      }
    }

    &:nth-child(2n) {
      tr {
        background-color: #fafafa !important;

        .td-edit-in {
          background: $color-white;
          background: -moz-linear-gradient(
            left,
            rgba(250, 250, 250, 1) 0%,
            rgba(250, 250, 250, 1) 50%,
            rgba(250, 250, 250, 0) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(250, 250, 250, 1) 0%,
            rgba(250, 250, 250, 1) 50%,
            rgba(250, 250, 250, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(250, 250, 250, 1) 0%,
            rgba(250, 250, 250, 1) 50%,
            rgba(250, 250, 250, 0) 100%
          );
        }

        .feedback-comment {
          background-color: $color-white;
        }

        .feedback-comment--form {
          .input-group-field {
            background-color: $color-white;
          }
        }
      }
    }
  }

  .link-text {
    &:hover {
      .icon {
        color: darken($color-link, 10%);
      }

      .fa-caret-down,
      .fa-caret-left,
      .icon-caret-down,
      .icon-caret-left {
        color: darken($color-link, 10%);
      }
    }

    .icon {
      margin-right: 5px;
      color: $color-text-light;
    }

    .fa-caret-down,
    .fa-caret-left,
    .icon-caret-down,
    .icon-caret-left {
      margin-left: 5px;
      margin-right: 0;
      color: $color-text-light;
    }

    &.active {
      .icon {
        color: $color-link;
      }

      .fa-caret-down,
      .fa-caret-left,
      .icon-caret-down,
      .icon-caret-left {
        color: $color-link;
      }
    }

    &.passive {
      .icon {
        color: $color-text-light;
      }

      .fa-caret-down,
      .fa-caret-left,
      .icon-caret-down,
      .icon-caret-left {
        color: $color-text-light;
      }
    }
  }

  .inline-list {
    .link-text {
      color: $color-text;

      &:hover {
        .icon {
          color: darken($color-link, 10%);
        }

        .fa-caret-down,
        .fa-caret-left,
        .icon-caret-down,
        .icon-caret-left {
          color: darken($color-link, 10%);
        }
      }

      .fa,
      [class^="icon-"],
      [class*=" icon-"] {
        margin: 3px 6px 0 0;
        display: inline-block;
        vertical-align: top;
        color: $color-grey2;
      }

      .icon-comment,
      .icon-locomotive {
        color: $color-link;
      }

      .fa-caret-down,
      .fa-caret-up,
      .fa-caret-left,
      .icon-caret-down,
      .icon-caret-up,
      .icon-caret-left {
        color: $color-link;
        margin: 3px 0 0 3px;
      }

      &.active {
        .icon {
          color: $color-link;
        }

        .fa-caret-down,
        .fa-caret-left,
        .icon-caret-down,
        .icon-caret-left {
          color: $color-link;
        }
      }

      &.passive {
        color: $color-grey2;

        .fa,
        [class^="icon-"],
        [class*=" icon-"] {
          color: $color-grey2;
        }
      }
    }
  }
}

.table-like {
  display: block;

  thead,
  tbody,
  tfoot {
    display: block;

    tr {
      display: flex;
    }

    td {
      display: block;
      flex: 1;

      &.td-icon {
        flex: 0 auto;
        //width: 23px;
      }

      &.td-action {
        flex: 0 auto;
        width: 60px;
      }

      &.td-title {
        flex: 0 auto;
        width: 30%;
        min-width: 135px;
      }

      &.td-content {
        width: 50%;
      }
    }

    .not-opened {
      .link-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.table-likes {
  thead,
  tbody,
  tfoot {
    .td-icon {
      //width: 23px;
    }

    .td-action {
      width: 60px;
    }

    .td-title {
      width: 30%;
      min-width: 135px;
    }

    .td-content {
      //width: 50%;
    }

    .not-opened {
      .link-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.table-link {
  tbody {
    tr {
      &:hover {
        cursor: pointer;
        background: $color-right-popup;
        color: $color-link;

        a {
          color: $color-link;
        }
      }

      &:nth-child(2n) {
        &:hover {
          background: $color-right-popup;
        }
      }
    }
  }
}

.td-edit {
  position: relative;
  padding: 0 !important;
  width: 1px;

  .button__xs {
    min-width: 0;
  }
}

.td-edit-in {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  height: 100%;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;

  .filter--block {
    margin: 0;
  }
}

.td-edit-fixed {
  width: 45px;

  &:last-child {
    .td-edit-in {
      padding-right: 15px;
    }
  }

  .td-edit-in {
    position: relative;
    padding-right: 5px;
    width: auto;
  }
}

.td-buttons {
  display: flex;
  padding-right: 15px;
  justify-content: flex-end;

  .button__xs {
    width: 24px;
    height: 24px;
    padding: 0 5px;
    font-size: 10px;
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }

    .icon-merge {
      font-size: 12px;
    }
  }
}

.tr-active {
  background: $color-link-light2 !important;
  color: $color-link;
}

.asc_desc {
  position: relative;
  display: inline-block;
  padding-right: 13px;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
  }

  &:before {
    margin: -9px 0 0;
    border-bottom-color: $color-grey2;
  }

  &:after {
    margin: 1px 0 0;
    border-top-color: $color-grey2;
  }

  &:hover {
    &:before {
      border-bottom-color: $color-text-light !important;
    }

    &:after {
      border-top-color: $color-grey2 !important;
    }
  }

  &.asc {
    color: $color-text;

    &:before {
      border-bottom-color: $color-link;
    }

    &:hover {
      &:before {
        border-bottom-color: $color-link !important;
      }

      &:after {
        border-top-color: $color-text-light !important;
      }
    }
  }

  &.desc {
    color: $color-text;

    &:after {
      border-top-color: $color-link;
    }

    &:hover {
      &:before {
        border-bottom-color: $color-text-light !important;
      }

      &:after {
        border-top-color: $color-link !important;
      }
    }
  }
}

.font-weight-light {
  font-weight: 300;
}

.lined--table {
  font-size: 11px;

  thead,
  tbody,
  tfoot {
    td {
      font-weight: 500;
    }
  }

  tbody,
  tfoot {
    td {
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }

  .td-location {
    font-weight: 300;

    a {
      font-weight: 500;
    }
  }

  .td-number {
    width: 8%;

    @include breakpoint(medium down) {
      width: auto;
    }
  }

  .td-category {
    .menu-tags {
      .filter--btn {
        font-size: 11px;
        align-items: center;
        cursor: pointer;

        .icon {
          font-size: 10px;
          margin-bottom: 1px;
        }
      }
    }
  }

  .label {
    min-width: 92px;
  }

  .light-grey-white {
    background: $color-white;
    color: $color-grey4;
    border: 1px solid $color-grey3;

    &:hover,
    &:active,
    &:focus {
      color: $color-text;
      background: $color-white;

      .fa,
      .icon,
      [class^="icon-"],
      [class*=" icon-"] {
        color: $color-link;
      }
    }
  }
}

.modal-tabs-table {
  margin: -25px -40px 0 -40px;

  .table {
    display: flex;
    flex-direction: column;

    thead {
      padding: 0;
      margin: 0;
      display: flex;

      tr {
        display: flex;
        width: 100%;

        > td,
        > th {
          flex: 1;
        }
      }
    }

    tbody {
      max-height: 380px;
      margin: 0;
      padding: 0;
      overflow: auto;

      @include scroller;

      tr {
        display: flex;
        width: 100%;

        > td,
        > th {
          flex: 1;
        }
      }
    }
  }

  .nodata {
    position: relative;
    left: 0;
    top: 0;
    margin: 20px 0 0;
  }
}
