.filter--block {
  font-size: $size-md;
  position: relative;
  margin: 0 -14px;

  .fa,
  .icon {
    margin: 0 5px 0 0;
    color: $color-link;
    font-size: $size-sm;
    line-height: 12px;
  }

  .search-input {
    + ul {
      li {
        &:first-child {
          margin-top: 5px;
        }
      }
    }
  }

  &.opened {
    color: $color-link;

    .filter--dropdown {
      visibility: visible;
      transition-delay: 0s;
    }

    .filter--btn {
      color: $color-link;
    }
  }
}

.filter--btn {
  color: $color-text-light;
  font-weight: 600;
  white-space: nowrap;
  padding: 3px 14px;
  align-items: center;

  &:hover {
    color: $color-text-light;
    background: darken($color-white, 5%);
  }

  &:active,
  &:focus {
    color: $color-link;
    background: darken($color-white, 5%);
  }
}

.restore--btn {
  @extend .color-link;
}

.filter--dropdown {
  transition: 0.2s 0.5s;
  text-align: left;
  visibility: hidden;
  min-width: 115px;
  position: absolute;
  left: 0;
  top: 90%;
  background: $color-white;
  padding: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 11;
  font-size: $size-md;

  &:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 0;
    right: -12px;
    bottom: -12px;
  }

  ul {
    padding: 5px 0;
    margin: 0;
    list-style: none;
    position: relative;
  }

  li {
    display: block;
    padding: 0;
    margin: 0;

    &:first-child {
      a,
      .checkbox,
      .radio {
        border-radius: 5px 5px 0 0;
      }
    }

    &:last-child {
      a,
      .checkbox,
      .radio {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .filter-action {
    display: block !important;
    padding: 9px 14px;
    color: $color-text;
    white-space: nowrap;

    &:hover {
      background: #fafafa;
    }
  }

  label {
    line-height: 1.2;
  }

  .checkbox,
  .radio {
    padding: 9px 14px;
    display: block;

    &:hover {
      background: #fafafa;
    }
  }

  &.filter--light {
    .filter-action {
      padding: 4px 14px;
      border-radius: 0;

      &:hover {
        background: $color-link-light2;
      }
    }
  }

  &.filter--checked {
    .filter-action {
      padding: 4px 14px 4px 32px;
    }

    .active {
      .filter-action {
        position: relative;
        color: $color-link;

        &:after {
          font-size: 7px;
          content: '\e924';
          color: $color-link;
          position: absolute;
          left: 11px;
          top: 50%;
          margin-top: -4px;
          font-family: 'datadash' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  &.filter--fixed {
    position: fixed;
    z-index: 888;
    display: block;
    visibility: visible;
  }

  &.filter--fixed1 {
    left: 510px;
    top: 225px;
  }

  &.filter--fixed2 {
    left: 910px;
    top: 225px;
  }

  .search-alls-list {
    font-size: 12px;
    white-space: normal;
    font-weight: 400;
    max-height: 320px;
    overflow: auto;

    @include scroller;

    li {
      padding-bottom: 9px;
      line-height: 18px;
      display: flex;
      align-items: flex-start;

      &:hover {
        color: $color-text;
        cursor: pointer;
      }

      &:last-child {
        padding: 0;
      }
    }
  }

  .search-alls-sm {
    .search-alls-list {
      max-height: 104px;
    }
  }

  .search--buttons {
    margin: 10px -20px 0;
    padding: 20px 10px 0;
  }
}

.search-alls-list-wrap {
  max-height: 200px;
  overflow: auto;

  @include scroller;

  .search-alls-sm & {
    max-height: 104px;
  }

  .modal-filters & {
    .search-alls-list {
      @include column(2);
      @include column-gap(20px);

      li {
        display: inline-flex;
        width: 100%;
      }
    }
  }
}

.search-alls-list {
  font-size: 12px;
  padding-right: 10px;
  white-space: normal;
  font-weight: 400;

  li {
    line-height: 18px;
    display: flex;
    align-items: flex-start;
    border: 1px solid #e2e1e1;
    background-color: #f8f8f8;
    margin-top: 5px;

    &:hover {
      color: $color-text;
      background-color: #fff;
      cursor: pointer;
    }

    &.disabled {
      cursor: default;
      text-decoration: none;
      color: $color-text-light;
    }

    &.archived {
      text-decoration-line: line-through;
      color: $color-grey2;
    }

    .wrap--text-highlight {
      line-height: 18px;
      padding: 5px 5px;
    }
  }
}

.search-alls-sm {
  .search-alls-list {
    max-height: 104px;
  }
}

.filter--block--sm {
  .filter--dropdown {
    min-width: 85px;
  }
}

.clear--btn {
  @extend .button;
  @extend .transparent;
  @extend .links;

  font-weight: 600;
  float: right;
  padding-right: 0;
  padding-left: 0;

  &:hover {
    .icon-filter-funnel {
      color: $color-grey2;
    }
  }

  .icon-filter-funnel {
    margin: -2px 10px 0 0;
    font-size: 14px;
    position: relative;
    color: $color-grey2;

    &:after {
      content: '\e943';
      font-size: 6px;
      color: $color-red;
      position: absolute;
      right: -3px;
      bottom: 0;
      font-family: 'datadash' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.download--btn {
  @extend .button;
  @extend .light-grey-white;
}

.download--wrap {
  position: relative;
  float: right;

  .datepicker-block {
    width: auto;

    .datepicker-dropdowns,
    .download-dropdowns {
      &:before,
      &:after {
        right: 62px;
      }
    }
  }

  .inventory-tab--in &,
  .reports--wrap--all & {
    float: none;
    margin-right: -5px;

    .download--btn {
      @extend .button__xs;

      min-width: 0;
      position: absolute;
      right: 0;
      top: 6px;
      z-index: 5;
    }

    .download-dropdowns {
      top: 40px;

      &:before,
      &:after {
        right: 32px;
      }
    }
  }

  .reports--wrap--all & {
    margin-right: 15px;

    .download--btn {
      top: 0;
    }
  }
}

.button-group {
  font-size: inherit;
  margin: 0;

  a {
    border-radius: 0;
    border-right: none !important;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 5px;
      bottom: 5px;
      width: 1px;
      background: $color-white;
      opacity: 0.5;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;

      &:after {
        display: none;
      }
    }
  }
}

.button-group--dropdown {
  @extend .filter--dropdown;

  right: 0;
  left: 0;
  top: 110%;
  font-size: $size-sm;

  a {
    padding: 6px 14px;
    display: block;
    color: $color-text;
    border-radius: 0 !important;

    &:hover {
      background: $color-link-light2;
    }
  }
}

.button-group__with-dropdown {
  position: relative;

  .button-group--dropdown {
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
  }

  .button__dropdown {
    padding-right: 42px;
    position: relative;
    white-space: nowrap;

    &:before {
      content: '';
      position: absolute;
      right: 30px;
      background: $color-white;
      top: 3px;
      bottom: 3px;
      width: 1px;
      opacity: 0.3;
    }

    &:after {
      content: '';
      position: absolute;
      right: 11px;
      top: 50%;
      margin-top: -3px;
      border-width: 4px;
      border-style: solid;
      border-color: $color-white transparent transparent transparent;
    }

    &.button__xs {
      padding-right: 30px;

      &:before {
        right: 22px;
      }

      &:after {
        right: 8px;
      }
    }
  }

  &.opened {
    .button__dropdown {
      box-shadow: inset 0 1px 2px 0 rgba($color-black, 0.2);

      &:after {
        margin-top: -7px;
        border-color: transparent transparent $color-white transparent;
      }

      + .button-group--dropdown {
        top: 105%;
      }
    }

    .button-group--dropdown {
      display: block;
      visibility: visible;
    }
  }
}

.button__plus {
  border-radius: 50%;
  display: inline-block;
  background: $color-green;
  color: $color-white;
  text-align: center;
  width: 18px;
  height: 18px;
  line-height: 18px;
  vertical-align: top;
  margin: 0 0 0 5px;

  .icon-plus {
    font-size: 8px;
  }
}

.filters--wrap--block {
  background: $color-white;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid $color-grey3;
  margin-top: 11px;
}

.filters--wrap {
  .filter--line {
    &:before,
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .menu-tags {
    display: none;
  }

  .search-inline {
    width: 100%;
  }

  .filter--dropdown {
    margin-top: 6px;
    width: 312px;
    padding: 20px;
    background: $color-white;
    border-radius: 2px;
    border: 1px solid $color-grey-light;
    box-shadow: 0 2px 10px 0 rgba(46, 47, 66, 0.07);

    &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: -11px;
      right: auto;
      bottom: auto;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-grey2 transparent;
    }

    &:after {
      content: '';
      position: absolute;
      left: 10px;
      top: -10px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-white transparent;
    }

    .search-text {
      padding: 0;
      width: 100%;

      &:after {
        left: auto;
        right: 14px;
        top: 13px;
        font-size: 16px;
        color: $color-link;
      }
    }

    .search-dropdown {
      position: relative;
      width: auto !important;
      top: auto;
      box-shadow: none;
      margin: 3px 0 -12px !important;

      &:before,
      &:after {
        display: none;
      }
    }

    .search-title {
      display: none;
    }

    .search-input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $color-grey3 !important;
      height: 42px;
      padding: 5px 40px 5px 15px;

      &:focus {
        border-color: $color-link !important;
      }
    }
  }

  .filter-type--status {
    .filter--dropdown {
      width: auto;
      padding: 10px 0 0;
      min-width: 115px;
      transition: none;

      .search--buttons {
        margin: 10px 0 0;
        padding: 14px 6px;
      }
    }
  }

  .search--buttons {
    justify-content: space-between;
    display: flex;

    .button {
      min-width: 86px;
    }
  }

  &.opened {
    .menu-tags {
      display: block;
    }

    .filter--line {
      padding-bottom: 10px;
    }

    .filter-opener {
      border-color: $color-link;
      position: relative;
      color: $color-text !important;
      background: $color-white-dark;
      box-shadow: inset 0 0 4px $color-link;

      &:focus,
      &:active {
        background: $color-white !important;
      }

      &:hover {
        background: $color-grey-light3 !important;

        .icon-filter-funnel {
          color: $color-link;
        }
      }

      .icon-filter-funnel {
        color: $color-link;

        &:after {
          bottom: 2px;
          border-color: transparent transparent $color-grey2;
        }
      }

      .label-num {
        background: $color-link;
      }
    }
  }
}

.button {
  &.filter-opener {
    line-height: 13px;
    display: flex;
    align-items: center;
    float: left;
    color: $color-text-light !important;

    &:focus,
    &:active {
      background: $color-white !important;
      color: $color-text !important;
    }

    &:hover {
      color: $color-text;
      background: darken($color-white, 5%) !important;

      .icon-filter-funnel {
        color: $color-text-light;
      }
    }

    .icon-filter-funnel {
      color: $color-text-light;
      font-size: 14px;
      position: relative;
      margin-right: 12px;
      -webkit-transition:
        background-color 0.25s ease-out,
        color 0.25s ease-out;
      transition:
        background-color 0.25s ease-out,
        color 0.25s ease-out;

      &:after {
        content: '';
        position: absolute;
        right: -5px;
        bottom: -3px;
        border-width: 5px 4px;
        border-style: solid;
        border-color: $color-grey2 transparent transparent;
      }
    }

    &.passive {
      color: $color-grey2;
      cursor: pointer;

      .icon-filter-funnel {
        color: $color-text-light !important;
      }
    }
  }
}

.filter--wrap1 {
  margin: -10px 0 10px;

  .filter--line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .back--wrap {
    flex: 1;
  }

  .filter-buttons--wrap {
    flex: 0 auto;
  }

  .back--wrap {
    a {
      height: 36px;
      line-height: 36px;
    }
  }
}

.export--line {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .radios {
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0 0 15px;

    .radio {
      line-height: 16px;
      margin-right: 20px;
      padding: 0;

      &:last-child {
        margin-right: 0;
      }

      input {
        &:checked {
          + span {
            &:after {
              content: '';
              width: 6px;
              height: 6px;
              top: 4px;
              left: 4px;
              border-radius: 50%;
              background: $color-link;
            }
          }
        }
      }

      span {
        color: $color-text;

        &:before {
          border-radius: 50%;
          background: $color-white;
          border: 1px solid $color-grey-light2;
        }
      }
    }
  }

  .central-form {
    width: auto;
    padding: 0;

    .button {
      min-width: 112px;
      font-weight: 400;
      margin: 0 8px;
    }
  }

  .line {
    &,
    .modal--content & {
      background: $color-grey-light2;
      margin: 0 0 15px;
      height: 1px;
      width: 100%;
    }
  }

  .modal--content & {
    .radios {
      white-space: normal;
      margin: -12px -10px 0;

      .radio {
        margin-right: 15px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.export--line--modal {
      margin-top: 25px;
      min-height: 37px;

      .radios {
        margin-top: 0;

        .radio {
          margin-right: 45px;

          &:last-child {
            margin: 0;
          }
        }
      }

      .loader-local {
        min-height: 100px;
      }
    }
  }
}

.radio-info {
  color: $color-text-light;
  font-size: 10px;
  font-style: normal;
}

.export-placeholder {
  padding: 0 0 15px;
  color: $color-red;
}

.search-alls {
  margin: 26px 0 20px;
  color: $color-text-light;

  .btn-link {
    flex: 0 auto;
    order: 2;
    font-size: 8px;
    color: $color-link;
    padding: 5px 8px 0 10px;
    margin: 0;

    &:hover,
    &:active,
    &:focus {
      color: darken($color-link, 10%);
    }
  }

  .search-alls-text {
    flex: 1;
    order: 1;
  }

  .search-title {
    color: $color-text;
    display: flex !important;
    justify-content: center;
    padding: 0 0 16px;
    font-size: 14px;
    line-height: 16px;
    border: none;
    font-weight: 600;
    align-items: center;
    cursor: default;

    .btn-link {
      order: 1;
      padding: 5px 10px 2px 10px;
    }

    .search-alls-text {
      flex: 0 auto;
    }
  }

  .search-text {
    padding: 0;
    width: 100%;
  }

  .input-group-button {
    left: auto;
    right: 0;
    top: 0;
    position: absolute;

    .button__search {
      font-size: 16px;
      color: $color-link;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .search-input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid $color-grey-light !important;
    height: 42px;
    padding: 5px 40px 5px 15px;

    &:focus {
      border-color: $color-link !important;
    }
  }
}
