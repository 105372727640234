.reports-wrap {
  margin: 0 -10px;
  min-height: calc(100vh - 60px - 58px - 20px - 40px - 57px);
  position: relative;
}

.reports {
  margin-top: 15px;
  min-height: 250px;

  @include grid-row(12, collapse, 100%);
  @include grid-layout(2, '.reports--col');

  @include breakpoint(medium down) {
    @include grid-layout(1, '.reports--col');
  }

  @include breakpoint(large up) {
    @include grid-layout(2, '.reports--col');
  }

  @media (max-width: 1025px) {
    @include grid-layout(1, '.reports--col');
  }
}

.reports--col {
  @include grid-column(12, 20px);
  @include grid-column-end;
}

.reports--item {
  background: $color-white;
  margin: 0 0 20px;
  box-shadow: 0 2px 10px 0 rgba($color-grey, 0.08);
  border: 1px solid $color-grey-light;
  border-radius: 4px;
}

.reports--top {
  width: 100%;
  align-items: center;
  position: relative;
  background: transparent;
  border-bottom: 1px solid $color-grey-light;
  padding: 10px 20px;
  display: block;

  > div {
    display: flex;
  }

  .Select-control {
    background: #f5f8fc;
  }

  .search-inline {
    width: 73px;
    height: 38px;
    margin-left: 10px;

    .input-group {
      margin: 0;
    }

    .input-group-field,
    .search-input {
      padding: 0 5px;
      height: 26px;

      &:focus {
        ~ .input-group-button {
          .button__search {
            color: $color-link;
          }
        }
      }
    }

    .search-text {
      padding-right: 0;
      flex: 1;
      overflow: hidden;

      .search-input {
        width: 0;
        padding: 0;
      }
    }

    .button__search {
      @extend .button;

      border: none;
      padding: 0;
      height: 26px;
      width: 26px;
      margin: 0 0 0 -1px;
      position: relative;
      background: none !important;
      color: $color-header-icon;
      display: none;

      &:hover,
      &:active,
      &:focus {
        color: $color-link;
      }

      .icon {
        font-size: $size-md;
        margin: 0;
      }
    }

    .button__opener {
      display: inline-block;
    }

    &.opened {
      .search-block {
        width: calc(100% - 338px);
        border-left-color: transparent;
      }

      .button__search {
        display: inline-block;
      }

      .button__opener {
        display: none;
      }
    }
  }

  .search-block {
    border-left: 1px solid $color-grey-light;
    padding: 0 0 0 10px;
    background: #f5f8fc;
    display: flex;
    align-items: center;
    position: absolute;
    right: 333px;
    top: 14px;
    width: 73px;
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
  }

  .reports-actions {
    margin-right: 10px;
    margin-left: 10px;
  }

  .reports-machines {
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
    padding: 0 0 0 24px;
    position: relative;
    line-height: 18px;
    color: $color-grey;
    min-height: 18px;

    &:before {
      content: '\e93a';
      font-family: 'datadash' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      left: 0;
      top: 2px;
      font-size: 13px;
      color: $color-text-light;
    }
  }
}

.reports-path {
  flex: 1;
  display: block !important;
  margin: -8px 0;
  padding: 5px 0 !important;
  flex-wrap: wrap;
  word-break: break-all;
  line-height: 16px;

  .report-title {
    display: inline-block;
    vertical-align: top;
    font-size: $size-lg;
    color: $color-text;
    font-weight: 600;
  }

  .report-name {
    color: $color-text;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    min-height: 28px;
    display: block;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  a:hover {
    color: darken($color-link, 10%);
  }

  [class^='icon-'],
  [class*=' icon-'] {
    margin-left: 7px;
    font-size: $size-sm;
    color: $color-link;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    margin-top: 2px;

    &:hover {
      color: darken($color-link, 10%);
    }
  }

  input {
    outline: none !important;
    padding: 0 10px;
    height: 36px;
  }
}

.reports-actions,
.updates-datepicker {
  flex: 0 auto;
  font-size: $size-md;
  margin: 0 20px;
  position: relative;

  strong {
    color: $color-text;
  }

  .icon-calendar {
    color: $color-text-light;
  }

  .inline-list {
    margin: -14px 0;
    display: flex;

    li {
      padding: 0 20px;
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  .input-group {
    margin: 0;
    display: flex;

    input {
      flex: 1;
      text-align: center;
      height: 34px;
      padding: 5px;
      background: $color-white !important;
      border: none;
      min-width: 1px;
    }

    .input-group-fields {
      flex: 1;
      width: auto;
      display: flex;
      align-items: center;
      border: 1px solid #d4d7dc;
      border-left: none;
      height: 36px;
      background: $color-white;

      &:last-child {
        border-radius: 0 4px 4px 0;

        input {
          border-radius: 0 4px 4px 0 !important;
        }
      }
    }

    .input-group-fields-sep {
      flex: 0 auto;
    }

    .button__calendar {
      @extend .button;

      background: $color-white;
      border: 1px solid #d4d7dc;
      margin-left: -1px;
      color: $color-link;
      display: inline-block !important;

      &:last-child {
        border-radius: 4px 0 0 4px;
      }
    }

    .input-group-button {
      position: relative;
      flex: 0 auto;
      width: auto;
      z-index: 1;

      &:first-child {
        .button__calendar {
          border-radius: 4px 0 0 4px;
          margin: 0 -1px 0 0;
        }
      }

      &:last-child {
        .button__calendar {
          border-radius: 0 4px 4px 0;
          margin: 0 0 0 -1px;
        }
      }
    }
  }

  .datepicker-dropdowns {
    right: 0;

    &:before,
    &:after {
      right: 204px;
    }
  }
}

.input-cover {
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.updates-datepicker {
  position: absolute;
  right: 0;
  top: 75px;
  z-index: 5;
  display: flex;
  justify-content: flex-end;
  width: auto;

  .download--btn {
    flex: 0 auto;
    margin: 0 0 0 20px;
  }

  .datepicker-block {
    flex: 0 auto;
  }
}

.new_assistance,
.new_data {
  position: fixed;
  right: 20px;
  top: 100px;
  z-index: 999;
}

.datepicker-dropdowns,
.download-dropdowns {
  font-size: $size-sm;
  position: absolute;
  right: 0;
  top: 120%;
  width: 440px;
  background: $color-white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
  padding: 20px;
  z-index: 99;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    right: 15px;
    top: -11px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-grey2 transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: 15px;
    top: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-white transparent;
  }
}

.download-dropdowns {
  width: 500px;
}

.datepicker-close {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 0.6rem;
  color: $color-red;
  opacity: 0.7;
  padding: 5px;

  &:hover {
    opacity: 1;
  }
}

.datepicker-dropdowns--flex {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px 20px;
  border-bottom: 1px solid $color-grey-light2;
  padding: 0 20px 20px;

  &:last-child {
    margin: 0 0 -20px;
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
}

.datepicker-dropdowns--left {
  width: 190px;
  flex: 0 auto;
}

.datepicker-dropdowns--right {
  width: 190px;
  flex: 0 auto;
}

.datepicker-dropdowns--top {
  padding: 0 0 8px;
  font-size: 12px;
  color: $color-text;
  font-weight: 600;
}

.datepicker-dropdowns--one {
  flex: 1;

  .datepicker-dropdowns--top {
    display: flex;
    justify-content: space-between;

    > span {
      flex: 0 auto;
      width: 100%;
      text-align: center;
    }
  }

  .react-datepicker {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__month-container {
    float: none;
    display: inline-block;
    width: 190px;
    flex: 0 auto;
  }
}

.reports-filter {
  flex: 0 auto;
  padding: 10px;
  position: relative;

  &.active {
    .button__filter {
      color: $color-link !important;

      &:hover,
      &:active,
      &:focus {
        color: $color-link !important;
      }
    }
  }

  .search-dropdown {
    padding: 10px 0;
    min-width: 180px;

    &:before,
    &:after {
      left: 15px;
    }
  }

  .search-text-in {
    font-size: $size-md;
    display: flex;
    align-items: center;
    padding: 0 20px;
    line-height: 1.2;

    strong {
      display: block;
      font-weight: 400;
    }

    .search-text-description {
      font-size: 10px;
      color: $color-text-light;
      display: block;
      margin-top: -4px;
    }
  }

  .search-text-in--title {
    flex: 1;
    padding-right: 10px;
  }

  .search-close {
    flex: 0 auto;
    font-weight: 700;
    padding: 0 5px;
    margin: -4px 0;
    color: $color-red;
    font-size: 16px !important;
    height: 16px;
    line-height: 16px;
    vertical-align: top;

    &:hover {
      color: $color-link;
    }
  }
}

.reports-clone {
  flex: 0 auto;
  padding: 0 10px;
}

.reports-close {
  flex: 0 auto;
  padding: 0 10px;
}

.reports--in {
  padding: 20px;
  min-height: 387px;
  position: relative;

  .graph-medium {
    padding: 0;
  }
}

.reports--empty {
  font-size: 28px;
  text-align: center;

  i {
    color: $color-blue;
    margin: 0 10px;
  }
}

.date-text {
  padding: 0 5px;
}

.reports--date {
  cursor: pointer;
}

.graph--wrap {
  position: relative;
}

.graph--small--text {
  font-size: 10px;
  font-weight: 400;

  span {
    display: inline-block !important;
  }

  .graph-comma {
    margin-right: 4px;
  }
}

.graph-medium {
  h4 {
    color: $color-text-light;
    text-transform: uppercase;
    padding: 0 0 5px;
    font-weight: 600;
  }
}

.bordered {
  border: 1px solid $color-grey-light;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 15px;

  &:last-child {
    margin-bottom: 10px;
  }
}

.nodata {
  text-align: center;
  font-size: $size-md;
  position: absolute;
  left: 0;
  font-weight: 600;
  margin: -14px 0 0 0;
  right: 0;
  top: 50%;
  color: $color-grey2;

  span {
    font-size: $size-sm;
    display: block;
    font-weight: 400;
  }

  &.nodata-sm {
    font-size: $size-sm;
    margin: -22px 0 0 0;

    span {
      font-size: 11px;
    }
  }
}

.reports-empty {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  line-height: 1.4;
  color: $color-text-light;

  > div {
    width: 260px;
    margin: 0 auto;
  }

  .button-group {
    display: inline-block;
  }

  .button-group--dropdown {
    top: 105%;
  }

  .button-group__with-dropdown {
    max-width: 260px;
    margin: 0 auto;
  }

  .tools--table + & {
    top: 40px;
  }
}

.experiments-table {
  min-height: 401px;

  .modal--content & {
    margin: 0 -40px;
  }

  table {
    thead,
    tbody,
    tfoot {
      td {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
}

.reports-empty-icon {
  margin-bottom: 15px;

  &.fz40 {
    font-size: 50px;
    margin-bottom: 5px;
    color: $color-text-light;
  }
}

.dropdown--checkbox {
  text-align: left;
  padding: 0 0 10px;

  .radio,
  .checkbox {
    line-height: 1.3;
    font-size: 12px;
  }
}

.datepicker--buttons {
  flex: 0 auto;
  width: 158px;
  border-right: 1px solid $color-grey-light2;
  padding: 63px 20px 20px;
  margin: -20px 20px -20px -20px;

  .button {
    height: 30px;
    line-height: 1;
    font-weight: 400;
    font-size: $size-sm;
    margin-bottom: 10px;
    padding: 4px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-bottom: 0;
    }

    &.text-center {
      text-align: center;
    }
  }

  .line {
    border-top: 1px solid $color-grey-light2;
    margin: 0 0 10px;
  }
}

.reports--wrap--all {
  background: $color-white;
  border-radius: 4px;
  padding: 0 20px;
  margin-bottom: 20px;

  .tabs {
    margin: 0 -20px 20px -21px;
    position: relative;
    border-bottom: 1px solid $color-grey-light;

    &:before {
      content: '';
      top: -10px;
      bottom: -10px;
      left: -5px;
      width: 6px;
      display: block;
      position: absolute;
      background: $color-white;
      border-right: 1px solid $color-grey-light;
      z-index: 5;
    }

    li {
      margin: 0 0 -1px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent transparent $color-grey-light;
      border-radius: 4px 4px 0 0;
      padding: 0;
      position: relative;

      &:first-child {
        border-left: none;

        &:before {
          display: none;
        }

        a {
          &:before {
            display: none;
          }
        }
      }

      a {
        padding: 0 20px;
        position: relative;

        &:hover {
          color: $color-text;
        }

        &:before {
          display: none;
        }
      }
    }

    .is-active {
      border-color: $color-grey-light $color-grey-light transparent;
      background: $color-white;

      &:before {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        top: auto;
        left: -3px;
        bottom: -1px;
        background: $color-white;
      }

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        top: auto;
        right: -3px;
        bottom: -1px;
        background: $color-white;
      }

      a {
        position: relative;

        &:before {
          display: block;
          z-index: 2;
          content: '';
          width: 10px;
          height: 10px;
          position: absolute;
          top: auto;
          left: -10px;
          bottom: -1px;
          border-radius: 0 0 5px;
          border-width: 1px;
          border-style: solid;
          background: none;
          border-color: transparent #e2e5ea #e2e5ea transparent;
        }

        &:after {
          z-index: 2;
          content: '';
          width: 10px;
          height: 10px;
          position: absolute;
          top: auto;
          right: -10px;
          bottom: -1px;
          border-radius: 0 0 0 5px;
          border-width: 1px;
          border-style: solid;
          border-color: transparent transparent #e2e5ea #e2e5ea;
        }
      }
    }
  }

  .round-chart {
    margin-bottom: 0;
  }

  .round-chart--item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    font-weight: 400;

    &.active {
      font-weight: 600;
    }

    p {
      order: 1;
      min-height: 0;
      padding-bottom: 5px;
    }
  }

  .position-relative {
    order: 2;
  }

  .reports--in {
    padding: 15px 20px;
  }

  .reports-empty {
    font-size: 12px;
    padding-bottom: 20px;

    > div {
      max-width: 180px;
    }

    h4,
    .h4 {
      text-align: center;
      font-size: 20px;
      color: $color-text;
      padding-bottom: 10px;
      font-weight: 600;
    }
  }

  .tools-wrap .reports-empty {
    top: 40px;
  }
}

.reports--top--up {
  display: flex;
  align-items: flex-start;
  max-width: 100%;

  .reports-path {
    margin: 0;
    padding: 0 10px 0 0 !important;
    flex: 1;
    max-width: calc(100% - 130px - 25px);
  }

  .filter--block {
    margin: 0 -10px -5px 10px;
    flex: 0 auto;
  }
}

.reports-label {
  border: 1px solid $color-grey-light;
  border-radius: 0 0 4px 4px;
  min-width: 130px;
  flex: 0 auto;
  text-align: center;
  line-height: 24px;
  white-space: nowrap;
  padding: 2px 10px 0 0;
  font-size: 13px;
  color: $color-grey4;
  margin-top: -11px;
  font-weight: 500;
}

.big-tabs-button {
  margin: 0 -10px 0 0;

  .button {
    margin: 0 0 0 10px;
    min-width: 36px;
    padding: 10px;
    text-align: center;
  }
}

.report-filters {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;

  .datepicker-block {
    flex: 0 auto;
    margin-bottom: 10px;

    .datepicker-position {
      &:hover {
        background: $color-white-dark;
      }
    }

    &.opened {
      background: $color-white-dark;

      .datepicker-position {
        input {
          border-color: $color-link;
          box-shadow: inset 0 0 2px $color-link;
        }

        .icon-calendar {
          color: $color-link;
        }
      }
    }

    .sm,
    .button-date {
      height: 26px;
      padding: 0 10px 0 31px;
      color: $color-text-light;
      cursor: pointer;
    }

    .button-date {
      text-align: left;
      font-weight: 400;
    }
  }

  .button-sm {
    margin: 0 0 10px 10px;
    height: 26px;
    padding: 5px 8px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-reload-clockwise {
      margin-left: 2px;
      font-size: 12px;
    }

    .icon-time {
      font-size: 12px;
    }
  }

  .datepicker-dropdowns {
    right: auto;
    left: 0;
    width: 500px;

    &:before,
    &:after {
      left: 15px;
      right: auto !important;
    }

    h5 {
      color: $color-text;
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 10px;
    }

    .datepicker-dropdowns--top {
      font-size: 12px;
      color: $color-text;
      font-weight: 600;
    }

    .button__cancel,
    .button__save,
    .button__remove,
    .button__remove__all {
      @extend .button__sm;

      margin: 0 8px;
      min-width: 120px;
    }
  }

  .datepicker-dropdowns--flex {
    flex-direction: column;

    .datepicker--buttons {
      width: auto;
      margin: 0;
      padding: 20px 0 0;
      border: none;
      order: 2;
      justify-content: space-between;
      display: flex;

      .button-block {
        display: inline-block;
        width: auto;
        min-width: 120px;
        font-weight: 400;
        height: 36px;
        margin: 0;
      }

      .line {
        display: none;
      }
    }
  }

  .modal-buttons {
    justify-content: space-between;
    display: flex;

    .button {
      margin: 0;
    }
  }

  .datepicker-dropdowns--one {
    order: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .datepicker-dropdowns--top {
      width: 100%;
    }
  }
}

.report-interval {
  width: 110px;
  flex: 0 auto;
  margin-bottom: 10px;

  &.opened {
    color: $color-link;

    .filter--dropdown {
      visibility: visible;
      transition-delay: 0s;
    }

    .filter--btn {
      color: $color-link;
    }

    .button-sm {
      border-color: $color-link;
      box-shadow: inset 0 0 2px $color-link;
    }

    .icon-interval,
    .icon-reload-clockwise,
    .icon-time {
      color: $color-link;
    }
  }

  .filter--dropdown {
    top: 120%;
    font-size: 11px;
    min-width: 100%;
    transition-duration: 0s;

    &:after {
      content: '';
      position: absolute;
      left: 15px;
      top: -10px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-white transparent;
    }

    li {
      &:first-child,
      &:last-child {
        a {
          border-radius: 0;
        }
      }

      &.active {
        .filter-action {
          background: $color-white-dark;
          color: $color-text;
        }
      }
    }

    .filter-action {
      padding: 6px 15px;
      font-weight: 500;
      color: $color-text-light;

      &:hover {
        color: $color-text;
      }
    }
  }
}

.report-interval-reload {
  margin-left: 10px;
  width: auto;

  .button-sm {
    margin: 0;
    padding: 5px 10px 5px 8px;

    .icon-reload-clockwise {
      margin-right: 5px;
    }

    .icon-time {
      margin-right: 5px;
    }

    .texted {
      font-size: 11px;
      padding-left: 3px;
    }
  }

  .report-interval-field {
    input {
      cursor: pointer;
      padding: 0 5px 0 50px;
    }
  }
}

.report-interval-field {
  &:hover {
    background-color: $color-white-dark;
  }

  input {
    height: 26px;
    padding: 0 10px 0 31px;
    color: $color-text-light;
    cursor: pointer;
  }
}

.report-filters-periods {
  display: flex;
  flex: 0 auto;
  margin: 0 0 10px;
}

.radio-button {
  margin: 0 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  input {
    position: absolute;
    left: -20px;
    top: 0;

    &:checked {
      + span {
        color: $color-text;
        border-color: $color-link;
        box-shadow: inset 0 0 2px $color-link;
      }
    }
  }

  > span {
    border: 1px solid $color-grey3;
    text-align: center;
    width: 30px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 12px;
    color: $color-text-light;
    font-weight: 500;

    &:hover {
      background: $color-white-dark;
    }
  }
}

.modal-search-info {
  text-align: center;
  font-weight: 600;
}
