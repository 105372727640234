.content {
  position: relative;
  padding: 90px 0 0;
  min-height: calc(100vh);
}

.col--1 {
  padding: 0 20px;

  .subheader {
    margin-bottom: 20px;

    .subheader-in {
      width: 100%;
    }
  }

  &.pb20 {
    padding-bottom: 20px;
  }
}

.pb15 {
  padding-bottom: 15px;
}

.col--3 {
  display: flex;
  min-height: calc(100vh - 60px);
}

.col--3--left {
  padding: 0 20px;
  width: 185px;
  flex: 0 1 auto;
  background: $color-left-aside;
  color: $color-white;
}

.col--3--center {
  flex: 1;
  padding: 0 20px;

  .subheader-in {
    //width: calc(100% - 185px - 380px);
    width: calc(100% - 185px);

    @media screen and (max-width: 64em) {
      width: calc(100% - 185px);
    }
  }
}

.col--3--right {
  padding: 0 20px;
  flex: 0 1 auto;
  width: 380px;
  background: $color-right-aside;
  border-left: 1px solid rgba(81, 81, 81, 0.14);

  @media screen and (max-width: 64em) {
    z-index: 10000;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
    position: fixed;
    right: 0;
    top: 60px;
    bottom: 0;
    animation: fadein 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);

    .scroll {
      height: calc(100vh - 60px - 56px - 1px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-right: -20px;
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 40em) {
    right: 0;
  }

  .subheader-in {
    width: 380px;
  }
}

.col--3--right__hide {
  display: none !important;

  @media screen and (max-width: 64em) {
    display: none;
  }
}

.calendar-overlay {
  position: fixed;
  background: rgba($color-grey, 0.3);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  @media screen and (min-width: 64.01em) {
    display: none !important;
  }
}

.calendar-overlay__hide {
  @media screen and (max-width: 64em) {
    display: none;
  }
}

.central {
  max-width: 830px;
  margin: 0 auto;
  width: 100%;
}

.col--2--wrap {
  margin: 20px -10px 0 -10px;
}

.col--2 {
  min-height: calc(100vh - 80px - 56px);

  @include flex-grid-row(collapse, 100%);

  &.scrolled {
    height: calc(100vh - 80px - 56px - 50px - 40px);

    .scroll-block {
      position: relative;
      height: calc(100vh - 80px - 56px - 50px - 40px);
    }

    .table--title {
      + .scroll-block {
        position: relative;
        height: calc(100vh - 80px - 56px - 50px - 40px - 58px);
      }
    }

    .height40 {
      .scroll-block {
        height: calc((100vh - 80px - 56px - 50px) * 0.4 - 40px - 24px);
      }
    }

    .height50 {
      .scroll-block {
        height: calc((100vh - 80px - 56px - 50px) * 0.5 - 40px - 24px);
      }
    }

    .height60 {
      .scroll-block {
        height: calc((100vh - 80px - 56px - 50px) * 0.6 - 40px - 24px);
      }
    }
  }

  &.without-subheader {
    height: calc(100vh - 80px - 50px - 40px);
    min-height: calc(100vh - 80px);

    .scroll-block {
      position: relative;
      height: calc(100vh - 80px - 50px - 40px);
    }

    .table--title {
      + .scroll-block {
        position: relative;
        height: calc(100vh - 80px - 50px - 40px - 58px);
      }
    }
  }
}

.col--2--left {
  @include flex-grid-column(4, 20px);

  padding-bottom: 20px;
}

.col--2--right {
  @include flex-grid-column(8, 20px);
  @include grid-column-end;

  padding-bottom: 20px;
  min-height: 360px;
}

.col--2--central {
  .col--2--left {
    @include flex-grid-column(6, 20px);

    padding-bottom: 20px;
  }

  .col--2--right {
    @include flex-grid-column(6, 20px);
    @include grid-column-end;

    padding-bottom: 20px;
  }
}

.col--2--data {
  .col--2--left {
    @include flex-grid-column(3, 20px);
  }

  .col--2--right {
    @include flex-grid-column(12, 20px);
    @include grid-column-end;
  }

  .card--image {
    height: 200px;
  }

  .round-charts-center {
    .round-chart--item {
      width: 45%;
    }
  }
}

.col--in {
  background-color: $color-white;
  border-radius: 5px;
  height: 100%;
  padding: 20px;
  position: relative;

  .line {
    background: $color-right-aside;
    height: 1px;
    margin: 0 -20px 20px;
  }

  .tab-menu {
    margin: -6px -20px 10px -20px;
    padding: 0 20px;
    border-bottom: 1px solid $color-right-aside;
    line-height: 36px;

    li {
      padding: 0 33px 0 0;

      @media (max-width: 1100px) {
        padding: 0 22px 0 0;
      }

      &:last-child {
        padding: 0;
      }

      a {
        margin: 0 0 -1px 0;
        height: 36px;
        line-height: 36px;
      }
    }
  }

  .menu-right {
    float: right;
    margin: -3px 0 10px;
  }

  &.grey {
    background-color: #dbdee1;
    border: 1px solid #d2d2d2;

    .col--head {
      background-color: #dbdee1;
      border-bottom: 1px solid #d2d2d2;
    }
  }
}

.col--height {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: #fff;
  border-radius: 5px;

  .col--in {
    height: auto;
    flex: 1;

    &:last-child {
      margin: 0;
    }

    + .col--in {
      margin-top: 20px;
    }
  }
}

.col--title {
  font-size: $size-lg;
  background: #f5f8fc;
  margin: -20px -20px 5px -20px;
  padding: 0 20px;
  font-weight: 600;
  line-height: 44px;
  border-radius: 4px 4px 0 0;
}

.col--head {
  margin: -20px -20px 15px -20px;
  padding: 0 15px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  background-color: $color-white;
  border-bottom: 1px solid $color-grey-light;
  height: 43px;
}

.runtime--title {
  font-size: 11px;
  line-height: 30px;
  font-weight: 400;
  color: $color-text;
  padding: 6px 0;
  position: absolute;
  top: -61px;
  left: 0;
}

.search--title {
  font-size: 11px;
  line-height: 30px;
  font-weight: 400;
  color: $color-text;
  padding: 6px 0;
  position: absolute;
  top: -61px;
  left: 0;
}

.td-runtime {
  max-width: 220px;

  .rel {
    min-width: 190px;
  }
}

.td-search {
  max-width: 140px;

  .rel {
    min-width: 110px;
  }
}

.scroll-block {
  overflow-y: auto;
  margin: 0 -20px;
  padding: 0 20px;
  height: calc(100% - 20px);

  &.without-scroll {
    overflow: visible;
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.row {
  &.row-margin {
    margin: 0 -0.9375rem;
  }
}
