.react-datepicker {
  border: none;
  font-family: $mainfont;
  border-radius: 0;
  line-height: 1.2;
}

.react-datepicker__header {
  border-radius: 0;
  background: none;
  border: none;
  padding: 0 0 8px 0;
}

.react-datepicker__day-name {
  line-height: 22px;
  padding: 0;
  margin: 0;
  font-weight: 500;
  width: 27px;
  height: 22px;
  color: $color-text-light;
  font-size: 8px;
  background: $color-grey-light2;
}

.react-datepicker__day {
  color: $color-text;
  line-height: 27px;
  padding: 0;
  margin: 0;
  font-weight: 500;
  border-left: 1px solid $color-grey-light2;
  border-bottom: 1px solid $color-grey-light2;
  width: 27px;
  height: 27px;
  font-size: 11px;

  &:hover {
    border-radius: 0;
  }

  &:last-child {
    border-right: 1px solid $color-grey-light2;
  }
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0;
  background: inherit;
  color: inherit;
}

.react-datepicker__day--disabled {
  color: $color-grey2;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  background: transparent !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 0;
  background: $color-link-light;
  color: $color-text;

  &:hover {
    border-radius: 0;
    background: $color-link;
    color: $color-white;
  }
}

.react-datepicker__day--in-selecting-range {
  &:not(.react-datepicker__day--in-range) {
    background-color: $color-blue;
    color: $color-white;
  }
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__current-month {
  font-size: 12px;
  font-weight: 600;
  padding: 0 0 8px;
}

.react-datepicker__navigation {
  border-width: 5px;
  top: -6px;
  font-family: FontAwesome;
  color: $color-link;
  border: none;
  width: 10px;
  font-size: 10px;
}

.react-datepicker__navigation--previous {
  &:before {
    content: "\f053";
  }
}

.react-datepicker__navigation--next {
  &:before {
    content: "\f054";
  }
}
