.dimensionDisplay {
  width: 100%;
  background-color: lightgray;
  outline: 1px solid darkgray;
  font-size: 15px;
  padding: 12px;
  border-radius: 5px;
}

.dimensionButtons {
  padding: 2px;
  border-radius: 20px;
  color: white;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 15px;

  .dimensionMinus {
    background-color: red;
  }
}

.dimensionInnerRemove {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  overflow: visible;
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  display: flex;
  color: white;
}

.dimensionsTextInput {
  display: flex;
  flex-grow: 1;
  font-size: 15px;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  height: 20px;
  min-width: 55px;
  max-width: 120px;
  text-align: center;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.dimensionsInternalPlus {
  height: 15px;
  width: 15px;
  font-size: 15px;
}

.dimensionsType {
  background-color: lightgray;
  border-radius: 20px;
  align-items: center;
  padding: 10px 5px 10px 5px;
  display: flex;
  margin: 3px;
  height: 20px;
}

.dimensionPlus:before {
  content: '+';
}
.dimensionMinus:before {
  content: '-';
}

.dimensionInner {
  height: 20px;
  position: relative;
  padding: 12px 3px 11px 3px;
  border-radius: 3px;

  .dimensionInnerRemove {
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    overflow: visible;
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 20px;
    justify-content: center;
    font-size: 10px;
    display: flex;
  }

  .dimensionInnerRemove:before {
    content: 'x';
  }

  &:hover {
    outline: 1px solid #0c3d5d;

    .dimensionInnerRemove {
      visibility: visible;
    }
  }
}

.dimensionInnerError {
  input {
    color: red;
  }
}

.dimensionsVerticalCenterFlex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.dimensionsArrayOf {
  color: black;
  display: flex;
  padding: 5px 10px 5px 10px;
  margin: 3px;
  flex-direction: row;
  align-items: center;
  background-color: lightgray;
  flex-basis: max-content;
  border-radius: 20px;
}
