.maintenances-wrap {
  .col--3--right {
    border-left-color: transparent;
    background: none;

    .subheader {
      border-left: 1px solid rgba(81, 81, 81, 0.14);
    }
  }

  .col--3--center {
    .subheader-in {
      //width: calc(100% - 380px);
      width: 100%;
    }
  }
}

.maintenance-wrap {
  margin: 20px -10px 0 -10px;
  //margin: 20px -30px 0 -10px;
}

.maintenance {
  min-height: calc(100vh - 158px);
  padding: 0 0 20px;

  @include flex-grid-row(collapse, 100%);
  @include grid-layout(3, '.maintenance--col');

  @include breakpoint(small down) {
    @include grid-layout(1, '.maintenance--col');
  }

  @include breakpoint(large up) {
    @include grid-layout(2, '.maintenance--col');
  }

  @include breakpoint(xxlarge up) {
    @include grid-layout(3, '.maintenance--col');
  }
}

.maintenance--col {
  @include flex-grid-column(4, 20px);
  @include grid-column-end;

  &.transparent {
    .maintenance--col--in {
      background: none;
      border-color: transparent;
    }

    .maintenance--top {
      margin-bottom: 14px;
      border-bottom-color: transparent;
    }
  }
}

.maintenance--col--in {
  background-color: #dbdee1;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  height: 100%;
  padding: 0 15px 20px;

  &.maintenance--col--in_is-over {
    background-color: darken(#dbdee1, 10%);
  }
}

.maintenance--item {
  margin: 0 0 20px;

  .feedbacks {
    padding: 10px 0 0;
  }

  .feedback-comment {
    background: $color-right-popup;
  }

  .feedback-comment--form {
    .input-group-field {
      background: $color-white;

      &.no-cross::-ms-clear {
        display: none;
      }
    }
  }

  .feedback--item {
    &:last-child {
      padding: 0;
    }
  }
}

.maintenance--top {
  border-bottom: 1px solid #cdcdcd;
  margin: 0 -15px 20px -15px;
  padding: 0 15px;
  position: relative;

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .tab-menu {
    font-size: $size-sm;
    margin: 0 0 -1px 0;
    line-height: 44px;
    font-weight: 400;

    li {
      padding: 0 12px 0 0;

      &:last-child {
        padding: 0;
      }

      a {
        height: 44px;
        line-height: 44px;
        color: $color-text;
      }

      &.active,
      &.is-active {
        font-weight: 400;

        a {
          background: none;
          color: $color-text;
        }
      }
    }
  }

  .filter--btn {
    line-height: 43px;
    color: $color-text;
    background: none;

    &:hover {
      color: $color-link;
      background: none;
    }

    &.no-filter {
      color: $color-text-light;

      &:hover {
        color: $color-link;
      }

      .fa,
      .icon {
        color: $color-text-light;
      }
    }
  }

  .search-inline {
    width: 200px;
    height: 43px;
    float: right;
    display: flex;
    align-items: center;

    .input-group {
      margin: 0;
    }
  }
}

.maintenance-title {
  font-weight: 600;
  font-size: $size-md;
  line-height: 43px;
  float: left;
}

.maintenance--item {
  cursor: pointer;
  word-wrap: break-word;
  word-break: break-all;
  background: $color-white;
  align-items: center;
  margin: 0 0 20px;
  min-height: 146px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(220, 222, 223, 0.09);
  padding: 16px 16px 38px 18px;
  color: $color-text;
  opacity: 1;

  &:after {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 3px;
    border-radius: 2px;
    background: $color-text-light;
  }

  &.success {
    &:after {
      background: $color-green;
      box-shadow: 0 0 6px 0 rgba($color-green, 0.5);
    }
  }

  &.alert {
    &:after {
      background: $color-red;
      box-shadow: 0 0 6px 0 rgba($color-red, 0.5);
    }
  }

  &.error {
    &:after {
      background: $color-orange;
      box-shadow: 0 0 6px 0 rgba($color-orange, 0.5);
    }
  }

  &.warning {
    &:after {
      background: $color-yellow;
      box-shadow: 0 0 6px 0 rgba($color-yellow, 0.5);
    }
  }

  &.info {
    &:after {
      background: $color-green;
      box-shadow: 0 0 6px 0 rgba($color-green, 0.5);
    }
  }

  &.anomaly {
    &:after {
      background: $color-label;
      box-shadow: 0 0 6px 0 rgba($color-label, 0.5);
    }
  }

  &.opened {
    .maintenance--text {
      display: block;
    }

    .maintenance--text--sm {
      display: none;
    }

    .more-less {
      color: $color-link;

      &:hover,
      &:active,
      &:focus {
        color: $color-link;
      }

      .fa-caret-right,
      .icon-caret-right {
        display: none;
      }

      .fa-caret-down,
      .icon-caret-down {
        display: inline-block;
      }
    }
  }

  .button__toggle {
    position: absolute;
    right: 39px;
    top: 14px;
  }

  .info-dialog--list {
    .info-dialog--item {
      width: auto;
      vertical-align: top;
      padding: 0 10px 8px 0;
      display: inline-block;
    }

    .info-dialog--label {
      color: $color-text-light;
      display: inline-block;
      vertical-align: top;
      padding: 0 2px 0 0;
    }

    .info-dialog--text {
      display: inline-block;
      vertical-align: top;
    }
  }

  &.maintenance--item_is-dragging {
    opacity: 0.8;
  }

  &.maintenance--item_is-archiving::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }

  &.maintenance--item_is-placeholder {
    background-color: $color-grey2;

    &:after {
      display: none;
    }
  }

  .filter--block {
    margin: -4px -7px;
    float: right;
  }
}

.maintenance--item--top {
  min-height: 90px;

  .wrench {
    float: right;
    margin: 1px -1px 0 0;
  }
}

.maintenance--item--title {
  font-size: $size-md;
  font-weight: 600;
  padding: 0;
  margin-bottom: 10px;
  display: block;
  color: $color-text;
  margin-right: 20px;
}

.maintenance-date,
.maintenance-location,
.maintenance--item--time,
.maintenance-machine {
  padding: 0 0 10px;

  .fa,
  .icon {
    margin: 0 5px 0 0;
    font-size: 11px;
    color: $color-grey2;
  }

  .icon-cog {
    margin-left: -1px;
  }
}

.maintenance-machine {
  font-weight: 500;
}

.maintenance--text {
  color: $color-text-light;
  line-height: 20px;
  padding: 0 0 5px;
  display: none;

  img {
    width: 100%;
    display: block;
  }

  p {
    padding: 0 0 9px;

    &:last-child {
      padding: 0;
    }
  }
}

.maintenance--text--sm {
  padding: 0 0 5px;
  line-height: 1.6;

  .assignee-name {
    padding-top: 4px;
  }
}

.assignee-name {
  a {
    color: $color-text;

    [class^='icon-'],
    [class*=' icon-'] {
      color: $color-link;
    }
  }
}

.maintenance--item--bottom {
  line-height: 18px;
  margin: 0 0 -5px 0;
  padding: 5px 16px 10px 18px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  > ul {
    display: flex;
    text-align: center;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    > li {
      flex: 0 auto;
      padding: 0 20px 0 0;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
        padding: 0;
      }
    }

    a {
      color: $color-text-light;
      display: inline-block;

      span {
        color: $color-text;
      }
    }

    .fa,
    .icon {
      color: $color-link;

      &.disabled {
        color: $color-grey2;
      }
    }

    .icon-comment {
      float: left;
      margin: 3px 5px 0 0;
    }
  }

  .width-lg {
    flex: 1;
  }
}

.more-less {
  @extend .button;
  @extend .transparent;

  position: absolute;
  left: 12px;
  top: 13px;
  color: $color-grey2;
  margin: 0;
  font-size: $size-sm;
  padding: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 0;

  &:hover {
    background: $color-grey-light;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-grey2;
  }

  .fa,
  .icon {
    margin: 0;
  }

  .fa-caret-down,
  .icon-caret-down {
    display: none;
  }

  .fa-caret-right,
  .icon-caret-right {
    display: inline-block;
  }
}

.maintenance--item--top--row {
  @include flex-grid-row(12);
}

.maintenance--item--top--left {
  @include flex-grid-column(8, 0);
}

.maintenance--item--top--right {
  @include flex-grid-column(4, 0);
}

.stream-user-assign-search {
  position: absolute;
  width: 250px;
  background: $color-white;
  padding: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 5;

  .Select-control {
    border-color: transparent !important;
    position: relative;
    padding: 0 0 0 25px;
    z-index: 5;

    &:after {
      content: '\f002';
      position: absolute;
      font-family: FontAwesome;
      left: 11px;
      top: 10px;
      color: $color-text-light;
      font-size: 15px;
      -webkit-transform: scale(-1, 1);
      -moz-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }

  .Select-placeholder {
    padding-left: 40px;
  }

  .Select-arrow-zone {
    display: none;
  }

  .Select-menu-outer {
    border: none;
    z-index: 4;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    strong {
      /*
      line-height: 10px;
      display: block;
      padding: 10px 0 0;
      */
      font-weight: 300;
      color: $color-text-light;
      float: left;
      width: 40px;
      margin: 0 0 0 -5px;
      text-align: center;
    }
  }
}

.maintenance--items--wrap {
  position: relative;
  min-height: calc(100vh - 60px - 58px - 20px - 84px - 20px);
}

.maintenance-empty {
  position: absolute;
  padding: 20px;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  background: #dbdee1;
  display: flex;
  text-align: center;
  font-size: $size-md;
  color: $color-text-light;
  line-height: 1.4;
  z-index: 3;

  .flex-el {
    width: 100%;
    display: flex;
    align-items: center;
    max-height: calc(100vh - 60px - 20px - 84px);
    min-height: 200px;

    > div {
      width: 100%;
    }
  }
}

.maintenance-empty-icon {
  min-height: 76px;
  display: flex;
  align-items: center;
  margin: 0 auto 15px;

  > object,
  img {
    margin: 0 auto;
  }
}

.maintenance--item-transition {
  margin: 0 -5px;
  padding: 0 5px;

  &.transition-enter {
    opacity: 0;
  }

  &.transition-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  &.transition-leave {
    opacity: 1;
  }

  &.transition-leave-active {
    opacity: 0;
    max-height: 1px !important;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:last-child .maintenance--item {
    margin: 0;
  }
}

.maintenance--assignee-option {
  line-height: 30px;
  vertical-align: middle;
  font-size: 12px;
}

.columnStyles {
  display: 'flex';
  width: 100%;
  border-radius: 5px;
  background: #dbdee1;
  transition: all 100ms ease-out;
  position: 'relative';
  padding: 20px;
}

$gridSize: 174; // Example value, replace with actual value
$columnGap: 20; // Example value, replace with actual value

.board {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: $columnGap;
  flex-direction: row;

  --grid: #{$gridSize}px;

  height: 100%;
  margin: 20px 0;
}

.dragging .maintenance--item {
  opacity: 0.5;
  transition: all 100ms ease-in;
}

.columnHeaderStyles {
  display: 'flex';
  padding: 20px;
  justify-content: 'space-between';
  flex-direction: 'row';
  color: #626f86;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 0.7s infinite;
}

.cardListStyles {
  cursor: pointer;
  word-wrap: break-word;
  word-break: break-all;
  background: #fff;
  align-items: center;
  min-height: 146px;
  position: relative;
  color: #3a3b3d;
  opacity: 1;
}
.cardListStyles {
  &:hover {
    background: #fcfcfc;
  }
}
.notAllowed {
  cursor: not-allowed;
}

.board .maintenance--col--in .maintenance-empty {
  position: relative;
  height: calc(100% - 100px);
  margin: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.maintenances-wrap-body {
  margin: 20px -10px 0 -10px;
  padding: 0;
  height: calc(100% - 100px);
}

.multiline-ellipsis-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0;
  -o-ellipsis-lastline: text-overflow;
  margin-bottom: 9px;
  height: 35px;
}
